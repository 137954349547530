import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import useSnackbar from './useSnackbar';
import { formatErrorMessage, formatErrorMessageProps } from '../shared/util/error';

const baseUrl = process.env.REACT_APP_API_URL;
if (!baseUrl) {
  throw new Error('You must specify an API url in your .env file with the key REACT_APP_API_URL');
}

// used to prevent infinite rerenders - see comment within the useRequest hook for more info.
let token = '';

export const useCheckAuth = () => {
  // TODO: move token to user store?
  const [accessToken, setAccessToken] = useState(token);
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  if (isAuthenticated && !isAuthLoading && token === '') {
    getAccessTokenSilently().then(t => {
      // note, we have to use this local variable and the above conditional to ensure we don't
      // infinitely set the access token state each time this hook is called. without this
      // local token var and check, it will cause infinite rerenders anytime a component
      // using this hook rerenders for reasons outside of this hook. If the tokens are refreshed
      // consistently, this naive check will no longer be sufficient.
      token = t;
      setAccessToken(token);
    });
  }

  return { accessToken, isAuthenticated };
};

const handleFetch = async <RequestBody, ResponseBody>(
  accessToken: string,
  path: string,
  method: string,
  body?: RequestBody,
): Promise<[ResponseBody, boolean, number] | null> => {
  const response = await fetch(baseUrl + path, {
    method: method,
    mode: 'cors',
    headers: [
      ['Authorization', `Bearer ${accessToken}`],
      ['Content-Type', 'application/json'],
    ],
    body: body ? JSON.stringify(body) : undefined,
  });
  // TODO: return this after the responses on the backend are standardized

  const headers = response.headers;
  if (
    headers.get('Content-Type')?.includes('application/json') &&
    headers.get('Content-Length') &&
    Number(headers.get('Content-Length')) > 0
  ) {
    const body = await response.json();
    return [body, response.ok, response.status];
  } else if (
    headers.get('Content-Type')?.includes('text/html') &&
    headers.get('Content-Length') &&
    Number(headers.get('Content-Length')) > 0
  ) {
    const text = await response.text();
    const textJSON = JSON.stringify(text);
    return [JSON.parse(textJSON), response.ok, response.status];
  }

  return null;
};

export type UseRequestOptions = { skipInitial?: boolean; handleError?: (err: any) => void };

// Use this hook for all authenticated get requests
export const useRequest = <ResponseBody>(
  path: string,
  method: string,
  options?: UseRequestOptions,
) => {
  const [response, setResponse] = useState<ResponseBody | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const { accessToken } = useCheckAuth();
  const { createSnackbar } = useSnackbar();

  const request = async (altPath?: string) => {
    path = altPath || path;
    if (accessToken) {
      setIsLoading(true);
      const res = await handleFetch<undefined, ResponseBody>(accessToken, path, method);
      if (res !== null) {
        const [data, ok] = res;
        if (!ok) {
          setError(data);
        }

        if (!ok && !options?.handleError) {
          createSnackbar({
            message: formatErrorMessage(data as formatErrorMessageProps),
            variant: 'error',
          });
        }

        if (!ok && options?.handleError) {
          options.handleError(data);
        }

        setResponse(data);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (options?.skipInitial) return;
    request().catch((err: Error) => {
      setError(err);
      createSnackbar({ message: formatErrorMessage(err), variant: 'error' });
    });
  }, [accessToken]);

  return {
    response,
    refetch: async (...args: any) => await request(args),
    isLoading,
    error,
  };
};

// Use this hook for all authenticated post, put, delete requests
export const useMutate = <ResponseBody, RequestBody>(path: string, method: string) => {
  const [response, setResponse] = useState<ResponseBody | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const { createSnackbar } = useSnackbar();

  const { accessToken } = useCheckAuth();

  const request = async (body: RequestBody) => {
    if (!accessToken) {
      setError(new Error('No access token'));
    }

    setIsLoading(true);
    try {
      const res = await handleFetch<RequestBody, ResponseBody>(accessToken, path, method, body);
      if (res === null) {
        setIsLoading(false);
        return;
      }

      const [data, ok, status] = res;
      if (!ok) {
        setError(data);
        if (status === 500) {
          const message =
            'There was an unexpected error. Please try again or contact your account representative if the issue persists.';
          createSnackbar({ message, variant: 'error' });
        }
        return;
      }

      setError(null);
      setResponse(data);
    } catch (e: any) {
      setError(e);
    }
    setIsLoading(false);
  };

  return { response, request, isLoading, error };
};
