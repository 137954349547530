import { createMachine, assign } from 'xstate';

export type BondFormContext = {
  uploaded: boolean;
  type: string;
  title: string;
  obligee: string | null;
  tags: string[] | null;
  id: string;
};

export const bondFormMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOgCMB7fCAMQoCdUA5CgFwGUwAbMTVyAMTsAogBlhAYQAqwgCKJQABwqxcrXFQUgAHogDMABj0k9ATgMA2ACwWATLdMWAjI4A0IAJ6IXxg34MA7I5OToF6egEAvpHuaFh4hKSU1HSMLBzcvPwQAgCqAAqiAPIAgrJyWsqq6ppIOt6mpiaOBgAcoU72tq167l4IpgEkAKz+oc5WrQaDw9GxGDgExORUtAyonDx8ggBKwgCyRQBqFXVVahr4WroIhk1OVpZ6wxNTpq0Bfd5WwyT+BsMeu0fnpWnMQHFFokVil1rlFFwKOgILsDsdTkoVBdaqAbsMnL9-MMrLYLK1bASDE4vggnNYRk4Aq1WsNTMTJhFojEQPgKCj4HVIQllsk1mk2JsspBKlialc6jcSTTGcZRn4LBY9CSXiE9OChUskqtUhtMtsIDLqpdrogrBESA4yY57ME3J5vEESBqLAEAqEfnY2fqFsKjbDGPDEcjpWdZdaFbb7Y7Ws7HS4LDTTE4-v49E5WlZCw5wsH4obLdj5bjvK1la0c35ScMIgZbIWrFzIkA */
  createMachine(
    {
      tsTypes: {} as import('./bondFormMachine.typegen').Typegen0,
      id: 'BondFormMachine',
      initial: 'bondFormNotSelected',
      context: {
        uploaded: false,
        type: '',
        title: '',
        obligee: null,
        tags: null,
        id: '',
      },
      schema: {
        context: {} as BondFormContext,
        events: {} as
          | { type: 'SELECTED'; data: BondFormContext }
          | { type: 'UPLOADED'; data: BondFormContext }
          | { type: 'REMOVE'; data: BondFormContext },
      },
      states: {
        bondFormNotSelected: {
          on: {
            SELECTED: {
              target: 'bondFormSelected',
              actions: 'assignBondFormDetailsToContext',
            },
            UPLOADED: {
              target: 'bondFormUploaded',
              actions: 'assignBondFormDetailsToContext',
            },
            REMOVE: {
              target: 'bondFormNotSelected',
              actions: 'resetBondFormDetailsContext',
            },
          },
        },
        bondFormSelected: {
          on: {
            SELECTED: {
              target: 'bondFormSelected',
              actions: 'assignBondFormDetailsToContext',
            },
            UPLOADED: {
              target: 'bondFormUploaded',
              actions: 'assignBondFormDetailsToContext',
            },
            REMOVE: {
              target: 'bondFormNotSelected',
              actions: 'resetBondFormDetailsContext',
            },
          },
        },
        bondFormUploaded: {
          on: {
            SELECTED: {
              target: 'bondFormSelected',
              actions: 'assignBondFormDetailsToContext',
            },
            UPLOADED: {
              target: 'bondFormUploaded',
              actions: 'assignBondFormDetailsToContext',
            },
            REMOVE: {
              target: 'bondFormNotSelected',
              actions: 'resetBondFormDetailsContext',
            },
          },
        },
      },
    },
    {
      actions: {
        assignBondFormDetailsToContext: assign((_context, event) => {
          return {
            uploaded: (event.data as BondFormContext).uploaded,
            type: (event.data as BondFormContext).type,
            title: (event.data as BondFormContext).title,
            obligee: (event.data as BondFormContext).obligee,
            tags: (event.data as BondFormContext).tags,
            id: (event.data as BondFormContext).id,
          };
        }),
        resetBondFormDetailsContext: assign(() => {
          return {
            uploaded: false,
            type: '',
            title: '',
            obligee: null,
            tags: null,
            id: '',
          } as BondFormContext;
        }),
      },
    },
  );
