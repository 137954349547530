import { TextField } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { PrivateFund } from '../../../../services/useOwners';
import { PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA } from '../../../../validations/bondFormPrincipalOwnerValidation';
import { ModalButtons } from './ModalButtons';
import { getValueOrInitial } from './helpers';
import Yup from '../../../../validations/custom';

type PrivateEquityFormProps =
  | FormikProps<Yup.InferType<typeof PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA>> & {
      onSaveClick: (values: PrivateFund) => void;
      onCancelClick: () => void;
      readOnly: boolean;
    };

export const PrivateEquityForm = (props: PrivateEquityFormProps) => {
  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  const onSave = () => {
    props.validateForm().then(errors => {
      if (Object.keys(errors).length == 0) {
        const values = { ...props.values, type: 'fund' };
        props.onSaveClick(values as PrivateFund);
      }
    });
  };

  return (
    <Form>
      <TextField
        name='name'
        id='name'
        label='Name'
        aria-describedby='private-fund-name-text'
        fullWidth
        value={getValueOrInitial(props, 'name')}
        onChange={props.handleChange}
        error={Boolean(props.errors.name)}
        helperText={props.errors.name}
        inputProps={readOnlyInputProps}
      />
      <ModalButtons
        readOnly={props.readOnly}
        onSaveClick={onSave}
        onCancelClick={props.onCancelClick}
      />
    </Form>
  );
};
