import { useEffect, useState, useMemo } from 'react';

const state = {
  loadingQueue: 0,
};

export type LoaderStoreContextType = {
  dequeueLoadingScreen: () => void;
  enqueueLoadingScreen: () => void;
  useLoadingScreen: (isPageLoading: boolean) => void;
  isLoading: boolean;
};

export const useLoaderStore = () => {
  const [loadingQueue, setLoadingQueue] = useState<number>(state.loadingQueue);

  const isLoading = useMemo(() => loadingQueue > 0, [loadingQueue]);

  const enqueueLoadingScreen = () => {
    setLoadingQueue(count => count + 1);
  };

  const dequeueLoadingScreen = () => {
    if (loadingQueue > 0) {
      setLoadingQueue(count => count - 1);
    }
  };

  const useLoadingScreen = (isPageLoading: boolean) => {
    return useEffect(() => {
      if (isPageLoading) {
        enqueueLoadingScreen();
      } else {
        dequeueLoadingScreen();
      }
    }, [isPageLoading]);
  };

  return {
    dequeueLoadingScreen,
    enqueueLoadingScreen,
    useLoadingScreen,
    isLoading,
  };
};
