import { createContext, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import FormNavBar from '../../components/FormNavBar';
import { FinalRequestPayload, useGetFinalRequest } from '../../services/finalRequest';
import { useIsFirstRender } from 'usehooks-ts';

export const FinalRequestContext = createContext<FinalRequestPayload | null>(null);

export type FinalRequestSubmittedViewOutletContext = {
  request: FinalRequestPayload;
  navigateToCurrentStep: () => void;
  refetchRequest: (...args: any) => Promise<void>;
};

export const FinalRequestSubmittedView = () => {
  const navigate = useNavigate();
  const isFirstRender = useIsFirstRender();
  const { id } = useParams();
  const {
    response: request,
    refetch: refetchRequest,
    isLoading,
  } = useGetFinalRequest(id!, { skipInitial: false });

  const navigateToCurrentStep = () => {
    if (request !== null) {
      if (['approved', 'review', 'declined', 'rejected'].includes(request.status)) {
        navigate(`/requests/contract/final/${id}/terms`);
      } else {
        if (!isFirstRender) {
          navigate(`/requests/contract/final/${id}/issue`);
        }
      }
    }
  };

  useEffect(() => {
    navigateToCurrentStep();
  }, [request?.status]);

  return !isLoading && request !== null ? (
    <FinalRequestContext.Provider value={request}>
      <FormNavBar />
      <Outlet context={{ request, navigateToCurrentStep, refetchRequest }} />
    </FinalRequestContext.Provider>
  ) : (
    <Stack direction='row' justifyContent='center' sx={{ paddingY: '1rem' }}>
      <CircularProgress />
    </Stack>
  );
};
