/* eslint-disable react/prop-types */
import * as React from 'react';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { RequestForm } from './Form';
import { FINAL_DRAFT_BOND_SCHEMA } from '../../../validations/finalBondFormDraftValidationSchema';
import Yup from '../../../validations/custom';
import { FINAL_BOND_SCHEMA } from '../../../validations/finalBondFormValidationSchema';
import {
  stripEmptyObjects,
  stripEmptyStrings,
} from '../../../services/requestFormSubmissionHelper';
import { usePostFinalBondRequest } from '../../../services/newFinalBondRequest';
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { debounce } from 'debounce';

type InitialFormValuesType = {
  bid_bond_id: string;
  bond_form_template_id: string;
  bond_form_file_upload_id: string;
  bond_amount: string;
  t_listing_required: string;
  contract_amount: string;
  second_bid: string;
  actual_bid_date: string;
  contract_date: string;
  point_of_contact: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
};

const INITIAL_FORM_VALUES: InitialFormValuesType = {
  bid_bond_id: '',
  bond_form_template_id: '',
  bond_form_file_upload_id: '',
  t_listing_required: 'true',
  bond_amount: '',
  contract_amount: '',
  second_bid: '',
  actual_bid_date: '',
  point_of_contact: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  contract_date: '',
};

type FormContentProps = {
  draftId: string;
  readOnly?: boolean;
};
export const FormContent = ({ readOnly, draftId }: FormContentProps) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null);

  const handleSnackbarClose = () => {
    setSnackbarMessage(null);
  };

  const {
    request: submitFinalForm,
    response: postFinalResponse,
    error: submitFinalError,
    isLoading: isFinalFormSubmissionLoading,
  } = usePostFinalBondRequest();

  React.useEffect(() => {
    if (submitFinalError) {
      console.error('Error submitting final form: ', submitFinalError);
      return;
    }

    if (postFinalResponse) {
      const { id } = postFinalResponse;
      navigate(`/requests/contract/final/${id}/terms`);
    }
  }, [postFinalResponse, submitFinalError]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={INITIAL_FORM_VALUES}
      validate={async values => {
        let errors: any = {};
        const validationSchema =
          !isSubmitting && !hasSubmitted ? FINAL_DRAFT_BOND_SCHEMA : FINAL_BOND_SCHEMA;

        const newValues =
          !isSubmitting && !hasSubmitted ? values : { ...values, draft_id: draftId };
        try {
          await validateYupSchema(
            validationSchema.noUnknown().cast(stripEmptyStrings(newValues), { assert: false }),
            validationSchema,
          );
        } catch (validationErrors) {
          if (isSubmitting) {
            setHasSubmitted(true);
            setIsSubmitting(false);
          }
          errors = yupToFormErrors(validationErrors);
        }
        return errors;
      }}
      onSubmit={(values: any) => {
        submitFinalForm(
          FINAL_BOND_SCHEMA.noUnknown().cast(
            stripEmptyObjects(stripEmptyStrings({ ...values, draft_id: draftId })),
            { assert: false },
          ) as Yup.InferType<typeof FINAL_BOND_SCHEMA>,
        );
        setIsSubmitting(false);
      }}
    >
      {props => (
        <>
          <RequestForm
            {...props}
            draftId={draftId}
            readOnly={readOnly}
            submitError={submitFinalError}
            setSnackbarMessage={setSnackbarMessage}
            isSubmitting={isSubmitting}
            setIsSubmitting={debounce(setIsSubmitting, 500)}
            isFinalFormSubmissionLoading={isFinalFormSubmissionLoading}
          ></RequestForm>
          <Snackbar
            open={snackbarMessage !== null}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
          >
            <Alert severity='error'>{snackbarMessage}</Alert>
          </Snackbar>
        </>
      )}
    </Formik>
  );
};
