import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import { Grid, Stack, Typography } from '@mui/material';

export const DeclinedView = () => (
  <Grid container spacing={4} direction='column' justifyContent='center' alignItems='center' p={4}>
    <Stack
      justifyContent='center'
      alignItems='center'
      style={{ padding: '2rem' }}
      direction='row'
      spacing={2}
    >
      <CloseIcon sx={{ color: red[500] }} />
      <Typography variant='h5'>Declined</Typography>
    </Stack>
    <Typography variant='h5'>Oh no! This request has been declined.</Typography>
    <Typography variant='caption'>
      If you have any questions, feel free to reach out to us at:{' '}
      <a href='bonds@getinrev.com'>bonds@getinrev.com</a>
    </Typography>
  </Grid>
);
