export const naicsCodeDescriptions: { [key: string]: string } = {
  '236115': 'New Single-Family Housing Construction (except For-Sale Builders)',
  '236116': 'New Multifamily Housing Construction (except For-Sale Builders)',
  '236117': 'New Housing For-Sale Builders',
  '236118': 'Residential Remodelers',
  '23611': 'Residential Building Construction',
  '23621': 'Industrial Building Construction',
  '23622': 'Commercial and Institutional Building Construction',
  '23711': 'Water and Sewer Line and Related Structures Construction',
  '23712': 'Oil and Gas Pipeline and Related Structures Construction',
  '23713': 'Power and Communication Line and Related Structures Construction',
  '23721': 'Land Subdivision',
  '23731': 'Highway, Street, and Bridge Construction',
  '23799': 'Other Heavy and Civil Engineering Construction',
  '23811': 'Poured Concrete Foundation and Structure Contractors',
  '23812': 'Structural Steel and Precast Concrete Contractors',
  '23813': 'Framing Contractors',
  '23814': 'Masonry Contractors',
  '23815': 'Glass and Glazing Contractors',
  '23816': 'Roofing Contractors',
  '23817': 'Siding Contractors',
  '23819': 'Other Foundation, Structure, and Building Exterior Contractors',
  '23821': 'Electrical Contractors and Other Wiring Installation Contractors',
  '23822': 'Plumbing, Heating, and Air-Conditioning Contractors',
  '23829': 'Other Building Equipment Contractors',
  '23831': 'Drywall and Insulation Contractors',
  '23832': 'Painting and Wall Covering Contractors',
  '23833': 'Flooring Contractors',
  '23834': 'Tile and Terrazzo Contractors',
  '23835': 'Finish Carpentry Contractors',
  '23839': 'Other Building Finishing Contractors',
  '23891': 'Site Preparation Contractors',
  '23899': 'All Other Specialty Trade Contractors',
};

export const naicsCodes = Object.keys(naicsCodeDescriptions);

export type NAICSCode =
  | '236115'
  | '236116'
  | '236117'
  | '236118'
  | '23611'
  | '23621'
  | '23622'
  | '23711'
  | '23712'
  | '23713'
  | '23721'
  | '23731'
  | '23799'
  | '23811'
  | '23812'
  | '23813'
  | '23814'
  | '23815'
  | '23816'
  | '23817'
  | '23819'
  | '23821'
  | '23822'
  | '23829'
  | '23831'
  | '23832'
  | '23833'
  | '23834'
  | '23835'
  | '23839'
  | '23891'
  | '23899';
