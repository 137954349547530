import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useGetBondPreviewsRequests } from '../../services/bondRequests';
import { RequestsTable } from './RequestsTable';
import { BondRequestPreview } from '../../shared/types/main';
import { usePostNewBondRequestDraft } from '../../services/newBondRequest';
import { usePostNewBidBondRequestDraft } from '../../services/newBidBondRequest';
import { useStore } from '../../store';

type MenuNavigationConfig = {
  url: string;
};

const menuNavigationConfig: Record<string, MenuNavigationConfig> = {
  bid: {
    url: '/requests/contract/bid',
  },
  contract: {
    url: '/requests/contract',
  },
};

export const RequestsView = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const {
    loader: { useLoadingScreen },
  } = useStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClick = (id: string, bondType: string) => {
    const request = requests.find(r => r.id === id) as BondRequestPreview;
    if (request.status === 'draft' && bondType.toLowerCase().includes('final')) {
      navigate(`/requests/contract/final/drafts/${request.id}`);
    } else if (request.status !== 'draft' && bondType.toLowerCase().includes('final')) {
      navigate(`/requests/contract/final/${request.id}`);
    } else if (request.status === 'draft' && bondType.toLowerCase().includes('bid')) {
      navigate(`/requests/contract/bid/drafts/${request.id}`);
    } else if (request.status !== 'draft' && bondType.toLowerCase().includes('bid')) {
      navigate(`/requests/contract/bid/${request.id}`);
    } else if (request.status === 'draft' && bondType.toLowerCase().includes('contract')) {
      navigate(`/requests/contract/drafts/${request.id}`);
    } else if (request.status !== 'draft' && bondType.toLowerCase().includes('contract')) {
      navigate(`/requests/contract/${request.id}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: string) => {
    const onClick = () => {
      const config = menuNavigationConfig[item];
      if (item === 'contract') {
        postNewBondRequestDraft({});
        return;
      } else if (item === 'bid') {
        postNewBidBondRequestDraft({});
        return;
      }
      navigate(config.url);
    };

    return onClick;
  };

  const { response, isLoading } = useGetBondPreviewsRequests();
  useLoadingScreen(isLoading);
  let requests = [] as BondRequestPreview[];
  if (response && response.data) {
    requests = response.data;
  }

  const { response: newDraftResponse, request: postNewBondRequestDraft } =
    usePostNewBondRequestDraft();

  useEffect(() => {
    if (newDraftResponse === null) {
      return;
    }

    navigate(`/requests/contract/drafts/${newDraftResponse.id}`);
  }, [newDraftResponse]);

  const {
    response: newBidBondDraftResponse,
    request: postNewBidBondRequestDraft,
    error: newBidBondDraftError,
  } = usePostNewBidBondRequestDraft();

  useEffect(() => {
    if (newBidBondDraftResponse === null) {
      return;
    }

    navigate(`/requests/contract/bid/drafts/${newBidBondDraftResponse.id}`);
  }, [newBidBondDraftResponse]);

  useEffect(() => {
    if (newBidBondDraftError) console.error(newBidBondDraftError);
  }, [newBidBondDraftError]);

  return (
    <div style={{ flexGrow: 1, background: '#F8F8FB' }}>
      <Container maxWidth='xl' sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
        <Grid container item sx={{ alignItems: 'center', paddingBottom: '5px' }}>
          <Grid container item xs={10} md={10} direction='row'>
            <div style={{ display: 'flex ', alignItems: 'center' }}>
              <h2 style={{ fontWeight: 'normal', marginRight: '25px' }}>Requests</h2>
            </div>
          </Grid>
          <Grid item xs={2} md={2}>
            <Button
              id='new-bond-request-button'
              aria-controls={open ? 'new-bond-request-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              variant='contained'
              disableElevation
              color='blue'
              onClick={handleClick}
              sx={{ float: 'right' }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              New Request
            </Button>
            <Menu
              id='new-bond-request-menu'
              MenuListProps={{
                'aria-labelledby': 'new-bond-request-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleMenuItemClick('bid')} disableRipple>
                Bid Bond
              </MenuItem>
              <MenuItem onClick={handleMenuItemClick('contract')} disableRipple>
                Contract Bond
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {!isLoading && (
          <div style={{ height: '80vh', width: '100%' }}>
            <RequestsTable requests={requests} onRequestClick={handleRequestClick} />
          </div>
        )}
      </Container>
    </div>
  );
};
