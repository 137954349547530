export enum BondFormTemplateType {
  pnp = 'pnp',
  performance = 'performance',
  payment = 'payment',
  supply = 'supply',
}

export enum BondFormTemplateTypeName {
  pnp = 'Performance and Payment',
  performance = 'Performance',
  payment = 'Payment',
  supply = 'Supply',
}
