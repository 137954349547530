import { ArrowDropDown } from '@mui/icons-material';
import { Grid, Stack, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import logo from '../assets/inrev-logo.svg';
import { useStore } from '../store';

const NavBarLink = (props: { label: string; path: string; active: boolean }) => {
  const navigate = useNavigate();

  return (
    <Box
      style={{
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={() => navigate(props.path)}
    >
      <Box style={{ position: 'relative' }}>
        {props.label}
        {props.active && (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: -6,
              height: 3,
              backgroundColor: '#F2C232',
            }}
          ></Box>
        )}
      </Box>
    </Box>
  );
};

const LoggedInNav = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { logout } = useAuth0();
  const {
    user: { getUserRole },
  } = useStore();

  const navigate = useNavigate();

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuOpen = Boolean(anchorElNav);

  const [activePath, setActivePath] = useState<string | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setActivePath(pathname.split('/')[1]);
  }, [pathname]);

  return (
    <Stack
      direction='row'
      spacing={3}
      style={{
        height: '100%',
        color: '#F8F8FB',
        fontSize: 13,
        fontWeight: 600,
        alignItems: 'center',
      }}
    >
      <NavBarLink label='Requests' path='/requests' active={activePath === 'requests'} />
      <NavBarLink label='Bonds' path='/bonds' active={activePath === 'bonds'} />
      <NavBarLink label='Principals' path='/principals' active={activePath === 'principals'} />
      <Box style={{ width: 1, height: 20, backgroundColor: '#FFF' }}></Box>
      <Box
        style={{
          height: '100%',
          padding: '0 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorElNav(event.currentTarget);
        }}
      >
        My Account
        <ArrowDropDown style={{ paddingLeft: 5 }} fontSize='small' />
      </Box>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={menuOpen}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block' },
          zIndex: 2500000,
        }}
      >
        {getUserRole() === 'agent-admin' && (
          <MenuItem
            onClick={() => {
              handleCloseNavMenu();
              navigate('/agents');
            }}
            disableRipple
            focusVisibleClassName={'none'}
            // this is necessary to get around the focusVisible chrome bug
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0) !important' }}
          >
            <Typography textAlign='center'>Manage Agents</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleCloseNavMenu();
            logout({ returnTo: window.location.origin });
          }}
          disableRipple
        >
          <Typography textAlign='center'>Log Out</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Grid
      container
      direction='row'
      style={{
        width: '100%',
        height: 48,
        backgroundColor: '#111835',
        padding: '0 20px',
        fontFamily: '"Outfit", Arial, Helvetica, sans-serif',
      }}
    >
      <Grid item display='flex' alignItems='center'>
        <img src={logo} height={22} />
      </Grid>
      <Grid item xs={true} display='flex' alignItems='center' justifyContent='flex-end'>
        {!isAuthenticated && (
          <Stack
            direction='row'
            spacing={3}
            style={{
              zIndex: 1000,
              height: '100%',
              color: '#F8F8FB',
              fontSize: 13,
              fontWeight: 600,
              alignItems: 'center',
            }}
          >
            <NavBarLink label='Log In' path='/requests' active={false} />
          </Stack>
        )}
        {isAuthenticated && <LoggedInNav />}
      </Grid>
    </Grid>
  );
};
