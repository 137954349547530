import { useNavigate, useParams } from 'react-router-dom';
import { RequestFormView } from './RequestForm';

export const RequestDraftView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (id === undefined) {
    navigate('/requests');
    return <></>;
  }

  // const { isLoading } = useGetFinalRequestDraft(id!, {
  //   handleError: () => {
  //     // noop - just want to prevent default error handling as this will consistently fail
  //     // during regular usage of the application
  //   },
  // });
  // useLoadingScreen(isLoading);

  // React.useEffect(() => {
  //   console.log('isLoading: ', isLoading)
  // }, [isLoading])

  return <RequestFormView draftId={id} />;
};
