import { DateTime } from 'luxon';
import _ from 'lodash';

export const formatToDollar = (value: number | string | undefined) =>
  '$' + new Intl.NumberFormat('en').format(Number(value));

// formats to Full name of month: December 12, 2015
export const formatDateToMMDDYYYY = (date: string) =>
  DateTime.fromFormat(date, 'MM/dd/yyyy').toLocaleString(DateTime.DATE_MED);

export const formatToPercent = (value: number) => `${(value * 100).toFixed(2)}%`;

export const capitalize = _.capitalize;

export function getFullAddress(
  address: string,
  city: string,
  state: string,
  zip: string,
  address2?: string,
) {
  return `${address}${address2 ? ' ' + address2 : ''} ${city}, ${state} ${zip}`;
}
