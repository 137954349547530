import { useRequest } from './useRequest';

export type GetRequiredIndemnitorsPayload = {
  existing_indemnity_agreements: {
    id: string;
    created_at: string;
  }[];
  principal?: {
    id: string;
    name: string;
  };
  companies: {
    contact_id: string;
    name: string;
  }[];
  individuals: {
    contact_id: string;
    first_name: string;
    middle_initial?: string;
    last_name: string;
    suffix?: string;
    email: string;
  }[];
};

export const useGetRequiredIndemnitors = (quoteId: string, bondType = '') => {
  let path = `/v1/surety/contract/quotes/${quoteId}/indemnitors`;
  switch (bondType) {
    case 'bid':
      path = `/v1/surety/contract/bid/quotes/${quoteId}/indemnitors`;
      break;
    case 'final':
      path = `/v1/surety/contract/final/quotes/${quoteId}/indemnitors`;
      break;
    default:
      path = `/v1/surety/contract/quotes/${quoteId}/indemnitors`;
  }

  return useRequest<GetRequiredIndemnitorsPayload>(path, 'GET');
};
