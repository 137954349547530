import { NucleusPurpleRequestPayloadBase, RequestPayload } from './request';
import { useRequest } from './useRequest';
import { PartialDeep } from 'type-fest';

export type RequestDraftPayload = {
  id: string;
  created_at: string;
  quote_id: string | undefined;
} & PartialDeep<
  Pick<
    RequestPayload,
    | 'bond_form_template_id'
    | 'bond_form_file_upload_id'
    | 'contract_amount'
    | 'bond_amount'
    | 't_listing_required'
    | 'point_of_contact'
    | 'bid_amount'
    | 'final_bond_type'
    | 'bid_date'
  >
> &
  PartialDeep<Omit<NucleusPurpleRequestPayloadBase, 'model'>>;

export const useGetRequestDraft = (id: string, isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/drafts/${id}`
    : `/v1/surety/contract/quotes/drafts/${id}`;
  return useRequest<RequestDraftPayload>(path, 'GET');
};
