import { Stack, Button } from '@mui/material';

type ModalButtonsProps = {
  onSaveClick: () => void;
  onCancelClick: () => void;
  readOnly: boolean;
};

export const ModalButtons = ({ onSaveClick, onCancelClick, readOnly }: ModalButtonsProps) => {
  if (readOnly) {
    return (
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} p={1}>
        <Button variant='contained' color='secondary' onClick={onCancelClick}>
          Close
        </Button>
      </Stack>
    );
  } else {
    return (
      <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} p={1}>
        <Button variant='contained' color='secondary' onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          onClick={() => {
            onSaveClick();
          }}
        >
          Save
        </Button>
      </Stack>
    );
  }
};
