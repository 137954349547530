import { FinalRequestPayload } from './finalRequest';
import { FinalDraftRequestPayload } from './finalRequestDraft';
import { useMutate } from './useRequest';
import { FINAL_BOND_SCHEMA } from '../validations/finalBondFormValidationSchema';
import { FINAL_DRAFT_BOND_SCHEMA } from '../validations/finalBondFormDraftValidationSchema';
import Yup from '../validations/custom';
import { CREATE_FINAL_DRAFT_BOND_SCHEMA } from '../validations/createFinalBondFormDraftValidationSchema';

export type CreateFinalBondRequestDraftRequest = Yup.InferType<
  typeof CREATE_FINAL_DRAFT_BOND_SCHEMA
>;

export type NewFinalBondRequestDraftRequest = Yup.InferType<typeof FINAL_DRAFT_BOND_SCHEMA>;

export type NewFinalBondRequestRequest = Yup.InferType<typeof FINAL_BOND_SCHEMA>;

export const usePostFinalBondRequestDraft = () => {
  return useMutate<FinalDraftRequestPayload, CreateFinalBondRequestDraftRequest>(
    '/v1/surety/contract/final/quotes/drafts',
    'POST',
  );
};

export const usePutFinalBondRequestDraft = (draftId: string) => {
  return useMutate<FinalDraftRequestPayload, NewFinalBondRequestDraftRequest>(
    `/v1/surety/contract/final/quotes/drafts/${draftId}`,
    'PUT',
  );
};

export const usePostFinalBondRequest = () => {
  return useMutate<FinalRequestPayload, NewFinalBondRequestRequest>(
    '/v1/surety/contract/final/quotes',
    'POST',
  );
};
