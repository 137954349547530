import { useDeleteAgentInvite } from '../../services/agents';
import { LoadingButton } from '../../components/LoadingButton';

type ResendInviteButtonProps = {
  agentId: string;
  getAgents: (path: string) => Promise<void>;
};

export const DeleteInviteButton = ({ agentId, getAgents }: ResendInviteButtonProps) => {
  const { request, isLoading } = useDeleteAgentInvite(agentId);

  const handleOnClick = async () => {
    await request(null);
    getAgents('/v1/agents/previews');
  };

  return (
    <LoadingButton
      variant='outlined'
      onClick={() => handleOnClick()}
      color='error'
      disabled={isLoading}
      isLoading={isLoading}
      sx={{ marginRight: '1rem' }}
      text='Delete'
      spinnerColor='info'
    />
  );
};
