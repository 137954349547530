import * as R from 'ramda';
import {
  BASE_SURETY_BOND_REQUEST_VALIDATION_SCHEMA,
  BID_SURETY_REQUEST_VALIDATION_SCHEMA,
  CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
  SMALL_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
  MEDIUM_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
  LARGE_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
} from '../validations/bondFormValidationSchema';
import {
  BASE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
  BID_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
  CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
  SMALL_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
  MEDIUM_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
  LARGE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
} from '../validations/bondFormDraftValidationSchema';
import { ObjectSchema } from 'yup';

export const baseValuePaths = [
  'draft_id',
  'bond_form_template_id',
  'bond_form_file_upload_id',
  't_listing_required',
  'point_of_contact.first_name',
  'point_of_contact.last_name',
  'point_of_contact.email',
  'point_of_contact.phone',
  'project.address',
  'project.address',
  'project.city',
  'project.state',
  'project.zip',
  'project.scope_of_work',
  'project.months_to_complete',
  'project.contract_warranty_months',
  'project.contract_damages',
  'project.contract_hazards',
  'project.completion_bond',
  'principal.name',
  'principal.address',
  'principal.city',
  'principal.state',
  'principal.zip',
  'principal.naics_code',
  'principal.fein',
  'principal.owners',
];

export const contractBaseValuePahts = ['bond_amount', 'contract_amount'];

export const bidBaseValuePaths = ['bond_amount', 'bid_amount', 'final_bond_type', 'bid_date'];

export const smallValuePahts = [
  'principal.largest_construction_project_completed',
  'principal.failed_to_complete_a_construction_job',
  'principal.lost_a_payment_suit',
  'principal.caused_surety_loss',
  'principal.profitable_ytd',
  'principal.profitable_last_fiscal_year',
];

export const mediumValuePaths = [
  'principal.construction_projects_gross_loss_gt_10_percent_last_36_months',
  'principal.current_construction_project_expected_gross_loss',
  'principal.construction_project_backlog_cost',
  'principal.last_fiscal_year_end_statement.date',
  'principal.last_fiscal_year_end_statement.corporate_cash',
  'principal.last_fiscal_year_end_statement.current_assets',
  'principal.last_fiscal_year_end_statement.current_liabilities',
  'principal.last_fiscal_year_end_statement.total_liabilities',
  'principal.last_fiscal_year_end_statement.total_assets',
  'principal.last_fiscal_year_end_statement.revenue',
  'principal.last_fiscal_year_end_statement.preparation_method',
];

export const largeValuePaths = [
  'principal.bloc_size',
  'principal.construction_project_backlog_gross_profit',
  'principal.profitable_year_prior_to_last_fiscal_year',
  'principal.last_fiscal_year_end_statement.accounts_payable',
  'principal.last_fiscal_year_end_statement.accounts_receivable',
  'principal.last_fiscal_year_end_statement.underbillings',
  'principal.last_fiscal_year_end_statement.credit_cards_payable',
  'principal.last_fiscal_year_end_statement.overbillings',
  'principal.last_fiscal_year_end_statement.current_portion_of_ltd',
  'principal.last_fiscal_year_end_statement.term_loan_debt',
  'principal.last_fiscal_year_end_statement.bank_line_payable',
  'principal.last_fiscal_year_end_statement.accrued_expense',
  'principal.last_fiscal_year_end_statement.ga_expense',
];

export const getValuesByModel = (model: string, values: any, isBidBond: boolean): any => {
  let relevantFields = [...baseValuePaths];

  switch (model) {
    case 'small':
      relevantFields = [...relevantFields, ...smallValuePahts];
      break;
    case 'medium':
      relevantFields = [...relevantFields, ...smallValuePahts, ...mediumValuePaths];
      break;
    case 'large':
      relevantFields = [
        ...relevantFields,
        ...smallValuePahts,
        ...mediumValuePaths,
        ...largeValuePaths,
      ];
      break;
  }

  if (isBidBond) {
    relevantFields = [...relevantFields, ...bidBaseValuePaths];
  } else {
    relevantFields = [...relevantFields, ...contractBaseValuePahts];
  }

  let newValues = {};
  relevantFields.forEach(field => {
    const fieldPath = field.split('.');
    const value = R.path(fieldPath, values);
    newValues = R.assocPath(fieldPath, value, newValues);
  });
  return newValues;
};

export const getValidationSchemaByModel = (
  model: string,
  drafting: boolean,
  isBidBond: boolean,
): ObjectSchema<any> | undefined => {
  let resultant: any;

  switch (model) {
    case 'small':
      if (drafting) {
        resultant = BASE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
        );
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        }
      } else {
        resultant = BASE_SURETY_BOND_REQUEST_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
        );
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA);
        }
      }
      return resultant;
    case 'medium':
      if (drafting) {
        resultant = BASE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
        ).concat(MEDIUM_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        }
      } else {
        resultant = BASE_SURETY_BOND_REQUEST_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
        ).concat(MEDIUM_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA);
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA);
        }
      }
      return resultant;
    case 'large':
      if (drafting) {
        resultant = BASE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA,
        )
          .concat(MEDIUM_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA)
          .concat(LARGE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA);
        }
      } else {
        resultant = BASE_SURETY_BOND_REQUEST_VALIDATION_SCHEMA.concat(
          SMALL_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA,
        )
          .concat(MEDIUM_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA)
          .concat(LARGE_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA);
        if (isBidBond) {
          resultant = resultant.concat(BID_SURETY_REQUEST_VALIDATION_SCHEMA);
        } else {
          resultant = resultant.concat(CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA);
        }
      }
      return resultant;
  }
};

export const parseStringsToBools = (
  values: Record<string, any>,
  stringsToParseIntoBooleans: string[],
): any => {
  let newValues = { ...values };

  stringsToParseIntoBooleans.forEach(field => {
    const path = field.split('.');
    const oldVal = R.path(path, values);
    if (oldVal !== undefined) {
      const newVal = oldVal === 'true';
      newValues = R.assocPath(path, newVal, newValues);
    }
  });

  return newValues;
};

export const parseStringsToNumbers = (
  values: Record<string, any>,
  stringsToParseIntoNumbers: string[],
): any => {
  let newValues = { ...values };

  stringsToParseIntoNumbers.forEach(field => {
    const path = field.split('.');
    const oldVal = R.path(path, values);
    if (oldVal !== undefined && isNumeric(oldVal)) {
      const newVal = Number(oldVal);
      newValues = R.assocPath(path, newVal, newValues);
    }
  });

  return newValues;
};

export function isNumeric(str: any) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const stripEmptyStrings = (value: { [key: string]: any } | Array<any>): any => {
  if (Array.isArray(value)) {
    return value.map(subVal => stripEmptyStrings(subVal));
  }
  if (typeof value === 'object' && !(value instanceof Date) && value !== null) {
    const newObj = { ...value };
    Object.keys(newObj).forEach(key => {
      if (newObj[key] === '') {
        delete newObj[key];
      } else {
        newObj[key] = stripEmptyStrings(newObj[key]);
      }
    });
    return newObj;
  }
  return value;
};

export const stripEmptyObjects = (value: { [key: string]: any } | Array<any>): any => {
  if (Array.isArray(value)) {
    return value.map(subVal => stripEmptyObjects(subVal));
  }
  if (typeof value === 'object' && !(value instanceof Date) && value !== null) {
    const newObj = { ...value };
    Object.keys(newObj).forEach(key => {
      if (
        typeof newObj[key] === 'object' &&
        !(newObj[key] instanceof Date) &&
        newObj[key] !== null
      ) {
        if (!Array.isArray(newObj[key]) && Object.keys(newObj[key]).length === 0) {
          delete newObj[key];
        } else {
          newObj[key] = stripEmptyObjects(newObj[key]);
        }
      }
    });
    return newObj;
  }
  return value;
};

export const deepStringify = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map(subVal => deepStringify(subVal));
  }
  if (typeof value === 'object' && !(value instanceof Date) && value !== null) {
    const newObj = { ...value };
    Object.keys(newObj).forEach(key => {
      if (
        Array.isArray(newObj[key]) ||
        (typeof newObj[key] === 'object' && !(newObj[key] instanceof Date) && newObj[key] !== null)
      ) {
        newObj[key] = deepStringify(newObj[key]);
      } else {
        newObj[key] = newObj[key] === undefined || newObj[key] === null ? '' : String(newObj[key]);
      }
    });
    return newObj;
  }
  return value === undefined || value === null ? '' : String(value);
};
