import {
  TextField,
  Stack,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Tooltip,
  Typography,
  Checkbox,
  InputLabel,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import { Form, FormikProps, useField } from 'formik';
import { nameSuffixes } from '../../../../constants/nameSuffixes';
import { Individual } from '../../../../services/useOwners';
import { PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA } from '../../../../validations/bondFormPrincipalOwnerValidation';
import { ModalButtons } from './ModalButtons';
import { getValueOrInitial } from './helpers';
import { MuiTelInput } from 'mui-tel-input';
import Yup from '../../../../validations/custom';
import { getFullAddress } from '../../../../shared/util/main';
import { AddressAutocomplete } from '../../../../components/AddressAutocomplete';

type IndividualFormProps = any &
  FormikProps<Yup.InferType<typeof PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA>> & {
    onSaveClick: (values: Individual) => void;
    onCancelClick: () => void;
    readOnly: boolean;
  };

export const IndividualForm = (props: IndividualFormProps) => {
  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  const nameSuffixMenuItems = nameSuffixes.map(suffix => (
    <MenuItem value={suffix} key={suffix}>
      {suffix}
    </MenuItem>
  ));

  const onSave = () => {
    props.validateForm().then((errors: any) => {
      if (Object.keys(errors).length == 0) {
        const values = { ...props.values, type: 'individual' };
        //Cleaning Up Phone as it is optional;
        if (values.phone === '+1') {
          values.phone = '';
        }
        props.onSaveClick(values as Individual);
      } else {
        console.error('errors on save', errors);
      }
    });
  };

  const [, , helpers] = useField('phone');

  return (
    <Form>
      <Stack direction='column'>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={2}>
            <TextField
              name='first_name'
              id='first-name'
              label='First Name'
              aria-describedby='first-name-text'
              fullWidth
              onChange={props.handleChange}
              value={getValueOrInitial(props, 'first_name')}
              error={props.touched.first_name && Boolean(props.errors.first_name)}
              helperText={props.touched.first_name && props.errors.first_name}
              inputProps={readOnlyInputProps}
            />
            <Tooltip title='Middle Initial'>
              <TextField
                name='middle_initial'
                id='middle-name-initial'
                label='M.I'
                aria-describedby='middle-name-initial-text'
                onChange={event =>
                  props.setFieldValue(
                    'middle_initial',
                    (event.target as HTMLInputElement).value.slice(-1),
                  )
                }
                value={getValueOrInitial(props, 'middle_initial')}
                error={props.touched.middle_initial && Boolean(props.errors.middle_initial)}
                helperText={props.touched.middle_initial && props.errors.middle_initial}
                inputProps={readOnlyInputProps}
              />
            </Tooltip>
            <TextField
              name='last_name'
              id='last-name'
              label='Last Name'
              aria-describedby='last-name-text'
              fullWidth
              onChange={props.handleChange}
              value={getValueOrInitial(props, 'last_name')}
              error={props.touched.last_name && Boolean(props.errors.last_name)}
              helperText={props.touched.last_name && props.errors.last_name}
              inputProps={readOnlyInputProps}
            />
            <FormControl fullWidth error={props.touched.suffix && Boolean(props.errors.suffix)}>
              <InputLabel id='suffix-select-label'>Suffix</InputLabel>
              <Select
                name='suffix'
                id='suffix'
                label='Suffix'
                aria-describedby='suffix-select'
                onChange={props.handleChange}
                value={getValueOrInitial(props, 'suffix')}
                error={props.touched.suffix && Boolean(props.errors.suffix)}
                inputProps={readOnlyInputProps}
              >
                {nameSuffixMenuItems}
              </Select>
              {<FormHelperText>{props.errors.suffix}</FormHelperText>}
            </FormControl>
          </Stack>
          <Stack direction='row' spacing={2}>
            <AddressAutocomplete
              valueOverride={
                getValueOrInitial(props, 'address') !== null &&
                getValueOrInitial(props, 'address') !== undefined &&
                getValueOrInitial(props, 'address') !== '' &&
                getValueOrInitial(props, 'city') !== null &&
                getValueOrInitial(props, 'city') !== undefined &&
                getValueOrInitial(props, 'city') !== '' &&
                getValueOrInitial(props, 'state') !== null &&
                getValueOrInitial(props, 'state') !== undefined &&
                getValueOrInitial(props, 'state') !== '' &&
                getValueOrInitial(props, 'zip') !== null &&
                getValueOrInitial(props, 'zip') !== undefined &&
                getValueOrInitial(props, 'zip') !== ''
                  ? getFullAddress(
                      getValueOrInitial(props, 'address'),
                      getValueOrInitial(props, 'city'),
                      getValueOrInitial(props, 'state'),
                      getValueOrInitial(props, 'zip'),
                    )
                  : null
              }
              onValidation={result => {
                props.setFieldValue('address', !result?.valid ? '' : result?.address ?? '');
                props.setFieldTouched('address', true);
                props.setFieldValue('city', result?.city ?? '');
                props.setFieldValue('state', result?.state ?? '');
                props.setFieldValue('zip', result?.zip ?? '');
              }}
              error={props.values.address === '' && props.touched.address}
              readOnly={props.readOnly}
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <TextField
              name='ssn'
              id='ssn'
              label='SSN'
              type='password'
              aria-describedby='last-name-suffix'
              fullWidth
              onChange={event =>
                props.setFieldValue('ssn', (event.target as HTMLInputElement).value.slice(-9))
              }
              value={getValueOrInitial(props, 'ssn')}
              error={props.touched.ssn && Boolean(props.errors.ssn)}
              helperText={props.touched.ssn && props.errors.ssn}
              inputProps={readOnlyInputProps}
            />
            <TextField
              name='email'
              id='email'
              type='email'
              label='Email'
              aria-describedby='email-text'
              fullWidth
              onChange={props.handleChange}
              value={getValueOrInitial(props, 'email')}
              error={props.touched.email && Boolean(props.errors.email)}
              helperText={props.touched.email && props.errors.email}
              inputProps={readOnlyInputProps}
            />
            <MuiTelInput
              disableDropdown
              defaultCountry='US'
              onlyCountries={['US']}
              name='phone'
              id='phone'
              label='Phone'
              fullWidth
              inputProps={readOnlyInputProps}
              value={getValueOrInitial(props, 'phone')}
              onChange={val => {
                if (!val.startsWith('+1')) {
                  helpers.setValue('+1' + val);
                } else {
                  helpers.setValue(val);
                }
              }}
              error={props.touched.phone && Boolean(props.errors.phone)}
              helperText={props.touched.phone && props.errors.phone}
            />
          </Stack>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={4}
          >
            <Grid item xs={8}>
              <Typography variant='body1'>Is this individual married?</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl error={props.values.married === undefined && Boolean(!props.readOnly)}>
                <RadioGroup
                  name='married'
                  row
                  aria-labelledby='married-radio-buttons-group-label'
                  onChange={event => {
                    props.setFieldValue(
                      'married',
                      (event.target as HTMLInputElement).value === 'true',
                    );
                    props.setFieldValue(
                      'spouse',
                      (event.target as HTMLInputElement).value === 'true'
                        ? {
                            first_name: '',
                            middle_initial: '',
                            last_name: '',
                            suffix: '',
                            email: '',
                          }
                        : undefined,
                    );
                  }}
                  value={props.values.married}
                >
                  <FormControlLabel
                    value='true'
                    disabled={props.readOnly}
                    control={
                      <Radio
                        sx={{
                          color: '#111835',
                          '&.Mui-checked': {
                            color: '#111835',
                          },
                          '&.Mui-disabled': {
                            color: '#111835',
                          },
                        }}
                      />
                    }
                    label='Yes'
                  />
                  <FormControlLabel
                    value='false'
                    disabled={props.readOnly}
                    control={
                      <Radio
                        sx={{
                          color: '#111835',
                          '&.Mui-checked': {
                            color: '#111835',
                          },
                          '&.Mui-disabled': {
                            color: '#111835',
                          },
                        }}
                      />
                    }
                    label='No'
                  />
                </RadioGroup>
                {props.values.married === undefined && (
                  <FormHelperText>{'Please Select'}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {props.values.married && (
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  name='spouse.first_name'
                  id='spouse-first-name'
                  label='Spouse First Name'
                  aria-describedby='spouse-first-name-text'
                  fullWidth
                  onChange={props.handleChange}
                  value={getValueOrInitial(props, 'spouse.first_name')}
                  error={
                    props.touched.spouse?.first_name && Boolean(props.errors.spouse?.first_name)
                  }
                  inputProps={readOnlyInputProps}
                  helperText={props.touched.spouse?.first_name && props.errors.spouse?.first_name}
                />
                <Tooltip title='Spouse Middle Initial'>
                  <TextField
                    name='spouse.middle_initial'
                    id='middle-name-initial'
                    label='Spouse M.I'
                    aria-describedby='spouse-middle-name-initial-text'
                    onChange={event =>
                      props.setFieldValue(
                        'spouse.middle_initial',
                        (event.target as HTMLInputElement).value.slice(-1),
                      )
                    }
                    value={getValueOrInitial(props, 'spouse.middle_initial')}
                    error={
                      props.touched.spouse?.middle_initial &&
                      Boolean(props.errors.spouse?.middle_initial)
                    }
                    inputProps={readOnlyInputProps}
                    helperText={
                      props.touched.spouse?.middle_initial && props.errors.spouse?.middle_initial
                    }
                  />
                </Tooltip>
                <TextField
                  name='spouse.last_name'
                  id='last-name'
                  label='Last Name'
                  aria-describedby='spouse-last-name-text'
                  fullWidth
                  onChange={props.handleChange}
                  value={getValueOrInitial(props, 'spouse.last_name')}
                  inputProps={readOnlyInputProps}
                  error={props.touched.spouse?.last_name && Boolean(props.errors.spouse?.last_name)}
                  helperText={props.touched.spouse?.last_name && props.errors.spouse?.last_name}
                />
                <FormControl
                  fullWidth
                  error={props.touched.spouse?.suffix && Boolean(props.errors.spouse?.suffix)}
                >
                  <InputLabel id='suffix-select-label'>Suffix</InputLabel>
                  <Select
                    name='spouse.suffix'
                    id='spouse-suffix'
                    label='Spouse Suffix'
                    aria-describedby='spouse-suffix-select'
                    onChange={props.handleChange}
                    inputProps={readOnlyInputProps}
                    value={getValueOrInitial(props, 'spouse.suffix')}
                    error={props.touched.spouse?.suffix && Boolean(props.errors.spouse?.suffix)}
                  >
                    {nameSuffixMenuItems}
                  </Select>
                  {<FormHelperText>{props.errors.spouse?.suffix}</FormHelperText>}
                </FormControl>
              </Stack>
              <Stack direction='row' spacing={2}>
                <TextField
                  name='spouse.email'
                  id='spouse_email'
                  type='email'
                  label='Spouse Email'
                  aria-describedby='spouse-email-text'
                  fullWidth
                  onChange={props.handleChange}
                  inputProps={readOnlyInputProps}
                  value={getValueOrInitial(props, 'spouse.email')}
                  error={props.touched.spouse?.email && Boolean(props.errors.spouse?.email)}
                  helperText={props.touched.spouse?.email && props.errors.spouse?.email}
                />
              </Stack>
            </Stack>
          )}
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={4}
          >
            <Grid item xs={8}>
              <Typography variant='body1'>Owns a home? </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                error={
                  props.touched.owns_a_home &&
                  Boolean(props.errors.owns_a_home) &&
                  Boolean(!props.readOnly)
                }
              >
                <RadioGroup
                  name='owns_a_home'
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  onChange={event =>
                    props.setFieldValue(
                      'owns_a_home',
                      (event.target as HTMLInputElement).value === 'true',
                    )
                  }
                  value={getValueOrInitial(props, 'owns_a_home')}
                >
                  <FormControlLabel
                    value='true'
                    disabled={props.readOnly}
                    control={
                      <Radio
                        sx={{
                          color: '#111835',
                          '&.Mui-checked': {
                            color: '#111835',
                          },
                          '&.Mui-disabled': {
                            color: '#111835',
                          },
                        }}
                      />
                    }
                    label='Yes'
                  />
                  <FormControlLabel
                    value='false'
                    disabled={props.readOnly}
                    control={
                      <Radio
                        sx={{
                          color: '#111835',
                          '&.Mui-checked': {
                            color: '#111835',
                          },
                          '&.Mui-disabled': {
                            color: '#111835',
                          },
                        }}
                      />
                    }
                    label='No'
                  />
                </RadioGroup>
                <FormHelperText>{props.errors.owns_a_home}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='body1'>
                Credit Report Authorization? <br></br>
              </Typography>
              <Typography variant='caption'>
                You understand that by checking the box next to this notice, you are providing
                `written instructions` to InRev under the Fair Credit Reporting Act authorizing
                InRev to obtain personal credit report information or other information from
                Experian. You authorize InRev to obtain such information solely for underwriting
                purposes.
                <br />
                <u>
                  <a href='#faca'> Download consent </a>
                </u>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                error={
                  props.touched.credit_report_permission &&
                  Boolean(props.errors.credit_report_permission)
                }
              >
                <Checkbox
                  inputProps={readOnlyInputProps}
                  name='credit_report_permission'
                  checked={props.values.credit_report_permission}
                  onChange={event => {
                    props.setFieldValue('credit_report_permission', event.target.checked);
                  }}
                />
                <FormHelperText>
                  {props.touched.credit_report_permission && props.errors.credit_report_permission}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <ModalButtons
        readOnly={props.readOnly}
        onSaveClick={onSave}
        onCancelClick={props.onCancelClick}
      />
    </Form>
  );
};
