import { Autocomplete, Box, Grid, Popper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { LocationOn, WhereToVote, ChangeCircle } from '@mui/icons-material';
import parse from 'autosuggest-highlight/parse';
import { getFullAddress } from '../shared/util/main';

const deepEqual = (a: any, b: any, caseSensitive = true): boolean => {
  const aIsArray = Array.isArray(a);
  const bIsArray = Array.isArray(b);
  const aIsDate = a instanceof Date;
  const bIsDate = b instanceof Date;
  const aIsObj = typeof a === 'object' && !aIsArray && a !== null && !aIsDate;
  const bIsObj = typeof b === 'object' && !bIsArray && b !== null && !bIsDate;

  if (typeof a !== typeof b) return false;
  if (aIsArray !== bIsArray) return false;
  if (aIsDate !== bIsDate) return false;
  if (aIsObj !== bIsObj) return false;

  if (aIsArray) {
    return a.every((item, index) => deepEqual(item, b[index]));
  }

  if (aIsDate) {
    return a.valueOf() === b.valueOf();
  }

  if (aIsObj) {
    return Object.keys(a).every(key => {
      return deepEqual(a[key], b[key]);
    });
  }

  if (caseSensitive && typeof a === 'string') {
    return a.toLowerCase() === b.toLowerCase();
  }

  return a === b;
};

export interface ValidationResponse {
  result: Result;
  responseId: string;
}
export interface Result {
  verdict: Verdict;
  address: Address;
  geocode: Geocode;
  metadata: Metadata;
  uspsData?: UspsData;
}
export interface Verdict {
  inputGranularity: string;
  validationGranularity: string;
  geocodeGranularity: string;
  addressComplete: boolean;
  hasInferredComponents: boolean;
}
export interface Address {
  formattedAddress: string;
  postalAddress: PostalAddress;
  addressComponents?: AddressComponentsEntity[] | null;
}
export interface PostalAddress {
  regionCode: string;
  languageCode: string;
  postalCode: string;
  administrativeArea: string;
  locality: string;
  addressLines?: string[] | null;
}
export interface AddressComponentsEntity {
  componentName: ComponentName;
  componentType: string;
  confirmationLevel: string;
  inferred?: boolean | null;
}
export interface ComponentName {
  text: string;
  languageCode?: string | null;
}
export interface Geocode {
  location: LowOrHighOrLocation;
  plusCode: PlusCode;
  bounds: Bounds;
  featureSizeMeters: number;
  placeId: string;
  placeTypes?: string[] | null;
}
export interface LowOrHighOrLocation {
  latitude: number;
  longitude: number;
}
export interface PlusCode {
  globalCode: string;
}
export interface Bounds {
  low: LowOrHighOrLocation;
  high: LowOrHighOrLocation;
}
export interface Metadata {
  business: boolean;
  poBox: boolean;
}
export interface UspsData {
  standardizedAddress: StandardizedAddress;
  deliveryPointCode: string;
  deliveryPointCheckDigit: string;
  dpvConfirmation: string;
  dpvFootnote: string;
  dpvCmra: string;
  dpvVacant: string;
  dpvNoStat: string;
  carrierRoute: string;
  carrierRouteIndicator: string;
  postOfficeCity: string;
  postOfficeState: string;
  fipsCountyCode: string;
  county: string;
  elotNumber: string;
  elotFlag: string;
  addressRecordType: string;
}
export interface StandardizedAddress {
  firstAddressLine: string;
  cityStateZipAddressLine: string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeExtension: string;
}

export type AddressAutocompleteProps = {
  valueOverride: string | null;
  onValidation: (
    result: { valid: boolean; address: string; city: string; state: string; zip: string } | null,
  ) => void;
  error?: boolean;
  readOnly: boolean;
};

export const AddressAutocomplete = (props: AddressAutocompleteProps) => {
  const [internalIsValid, setInternalIsValid] = useState<{
    valid: boolean;
    address: string;
    city: string;
    state: string;
    zip: string;
  } | null>(null);
  const {
    suggestions: { loading, status, data },
    setValue,
  } = usePlacesAutocomplete({ defaultValue: '', requestOptions: { types: ['street_address'] } });
  const [internalValue, setInternalValue] = useState<string>(props.valueOverride ?? '');
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  useEffect(() => {
    if (!loading && status.includes('OK')) {
      setSuggestedAddresses(data);
    }
  }, [data, status, loading]);

  const validateAddress = (selectedAddress: string) => {
    if (selectedAddress.length) {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const raw = JSON.stringify({
        address: {
          regionCode: 'US',
          addressLines: [selectedAddress],
        },
        enableUspsCass: true,
      });

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow',
      };
      fetch(
        'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDo-u74TizCs5NYSicK9vlTpIvaQRBmGdU',
        requestOptions,
      )
        .then(response => response.json() as unknown as ValidationResponse)
        .then(({ result }) => {
          const address = {
            address:
              result.address.postalAddress.addressLines?.[0] !== undefined
                ? `${result.address.postalAddress.addressLines?.[0]}${
                    result.address.postalAddress.addressLines?.[1] !== undefined
                      ? ' ' + result.address.postalAddress.addressLines?.[1]
                      : ''
                  }`
                : undefined,
            city: result.address.postalAddress.locality ?? undefined,
            state: result.address.postalAddress.administrativeArea ?? undefined,
            zip:
              result.address.postalAddress.postalCode !== undefined
                ? result.address.postalAddress.postalCode.substring(0, 5)
                : undefined,
          };

          if (
            address.address === undefined ||
            address.city === undefined ||
            address.state === undefined ||
            address.zip === undefined
          ) {
            console.error('Address was validated but missing a component.');
            props.onValidation(null);
            if (internalIsValid !== null) {
              setInternalIsValid(null);
            }
          } else {
            if (
              result.address.addressComponents &&
              result.uspsData &&
              result.address.addressComponents.every(component => {
                return component.componentType === 'subpremise'
                  ? component.confirmationLevel === 'CONFIRMED' ||
                      component.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE'
                  : component.confirmationLevel === 'CONFIRMED';
              })
            ) {
              const newInternalIsValid = {
                valid: true,
                ...(address as Omit<NonNullable<typeof internalIsValid>, 'valid'>),
              };
              if (!deepEqual(newInternalIsValid, internalIsValid)) {
                setInternalIsValid(newInternalIsValid);
              }
            } else {
              setInternalIsValid({
                valid: false,
                ...(address as Omit<NonNullable<typeof internalIsValid>, 'valid'>),
              });
            }
          }
        })
        .catch(error => console.log('error', error));
    } else {
      setInternalIsValid(null);
    }
  };

  useEffect(() => {
    if (props.valueOverride !== null) {
      setInternalValue(props.valueOverride);
      validateAddress(props.valueOverride);
    }
  }, [props.valueOverride]);

  useEffect(() => {
    if (internalIsValid !== null) {
      props.onValidation(internalIsValid);
      setInternalValue(
        getFullAddress(
          internalIsValid.address,
          internalIsValid.city,
          internalIsValid.state,
          internalIsValid.zip,
        ),
      );
    }
  }, [internalIsValid]);

  return (
    <Autocomplete
      readOnly={props.readOnly}
      PopperComponent={({ style, ...props }) => (
        <Popper
          {...props}
          style={{ ...style, height: 0 }} // width is passed in 'style' prop
        />
      )}
      fullWidth
      freeSolo={false}
      onFocus={() => setInternalIsValid(null)}
      popupIcon={
        internalIsValid?.valid === true ? (
          <WhereToVote color='success' />
        ) : (
          <ChangeCircle color='warning' />
        )
      }
      options={suggestedAddresses}
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      value={{ description: internalValue } as google.maps.places.AutocompletePrediction}
      renderInput={params => (
        <TextField
          {...params}
          onChange={e => {
            setValue(e.target.value);
            setInternalValue(e.target.value);
          }}
          onBlur={() => {
            if (props.valueOverride !== internalValue) {
              validateAddress(internalValue);
            }
          }}
          label='Address'
          variant='outlined'
          error={props.error}
          {...(props.error ? { helperText: 'Valid Address Required' } : {})}
        />
      )}
      renderOption={(elementProps, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li
            {...elementProps}
            onClick={() => {
              setInternalValue(option.description);
              validateAddress(option.description);
            }}
          >
            <Grid container alignItems='center'>
              <Grid item>
                <Box component={LocationOn} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
