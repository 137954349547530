import { Backdrop, CircularProgress } from '@mui/material';
import { useStore } from '../store';

export const LoadingScreen = () => {
  const {
    loader: { isLoading },
  } = useStore();

  if (!isLoading) return null;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
