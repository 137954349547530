/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Grid,
  InputAdornment,
  Stack,
  Typography,
  Select,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Chip,
  Divider,
  MenuItem,
  InputLabel,
  Checkbox,
  Box,
  Button,
  Card,
  FormHelperText,
  SelectChangeEvent,
  List,
  ListItemAvatar,
  ListItemText,
  FormGroup,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { debounce } from 'debounce';
import BondFormSelectionModal from './BondFormSelectionModal';
import PrincipalOwnerModal from './PrincipalOwnerModal';
import { Owner } from '../../../services/useOwners';
import { Form, useField } from 'formik';
import { financialStatementTypes } from '../../../constants/financialStatementTypes';
import { naicsCodeDescriptions } from '../../../constants/naicsCodes';
import { useGetPrincipalPreviews, PrincipalPreview } from '../../../services/principalDetails';
import {
  NewBondRequestDraftRequest,
  usePutNewBondRequestDraft,
} from '../../../services/newBondRequest';
import { RequestDraftPayload } from '../../../services/requestDraft';
import {
  deepStringify,
  getValidationSchemaByModel,
  stripEmptyObjects,
  stripEmptyStrings,
} from '../../../services/requestFormSubmissionHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { removeNestedIds } from '../../../services/useOwners';
import { stateMenuItems } from '../../../constants/menuItems';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ErrorIcon from '@mui/icons-material/Error';
import Avatar from '@mui/material/Avatar';
import { useGetRequestDraft } from '../../../services/requestDraft';
import { useStore } from '../../../store';
import {
  BondFormTemplateType,
  BondFormTemplateTypeName,
} from '../../../constants/bondFormTemplateTypes';
import { usePutNewBidBondRequestDraft } from '../../../services/newBidBondRequest';
import { useGetRequest } from '../../../services/request';
import deepmerge from 'deepmerge';
import { INITIAL_PRINCIPAL_FORM_VALUES } from './FormContent';
import { DatePicker } from '../../../components/DatePicker';
import { cloneDeep } from 'lodash';
import { getFullAddress } from '../../../shared/util/main';
import { AddressAutocomplete } from '../../../components/AddressAutocomplete';
import { LoadingButton } from '../../../components/LoadingButton';

type RequestFormProps = {
  draftId: string;
  readOnly: boolean;
  isBidBond: boolean;
  model: 'small' | 'medium' | 'large';
  selectedPrincipal: string;
  setModel: (model: 'small' | 'medium' | 'large') => void;
  setValidationSchema: (schema: any) => void;
} & any;

export function RequestForm(props: RequestFormProps) {
  const navigate = useNavigate();
  const { response: requestData, isLoading: requestDataIsLoading } = props.readOnly
    ? (useGetRequest(props.draftId, { skipInitial: false }, props.isBidBond) as unknown as {
        response: RequestDraftPayload;
        isLoading: boolean;
      })
    : useGetRequestDraft(props.draftId, props.isBidBond);

  const {
    loader: { useLoadingScreen },
  } = useStore();

  useLoadingScreen(requestDataIsLoading);

  const {
    user: { user },
  } = useStore();

  const [field, , helpers] = useField('point_of_contact.phone');

  const { request: updateForm, isLoading: isUpdatingContractForm } = usePutNewBondRequestDraft(
    props.draftId,
  );

  const { request: updateBidForm, isLoading: isUpdatingBidForm } = usePutNewBidBondRequestDraft(
    props.draftId,
  );

  const { id: requestId } = useParams();

  const typeDescriptionsMenuItems = Object.values(BondFormTemplateType).map(
    (bondFormTemplateType, index) => (
      <MenuItem value={bondFormTemplateType} key={index + 1}>
        {BondFormTemplateTypeName[bondFormTemplateType]}
      </MenuItem>
    ),
  );

  const financialStatementTypeMenuItems = financialStatementTypes.map((type, index) => (
    <MenuItem value={type.value} key={index}>
      {type.description}
    </MenuItem>
  ));

  const naicsCodeDescriptionsMenuItems = Object.entries(naicsCodeDescriptions).map(
    ([code, description]) => (
      <MenuItem value={code} key={code}>
        {description}
      </MenuItem>
    ),
  );

  const onOwnersChange = (owners: Owner[]) => {
    props.setFieldValue('principal.owners', owners);
  };

  const setRequestModel = (principalId: string, isBidBond: boolean) => {
    const { bond_amount, bid_amount } = props.values;
    if (principalId === 'new-principal') {
      // no Principals selected
      const amount = isBidBond
        ? bid_amount !== ''
          ? bid_amount
          : 0
        : bond_amount !== ''
        ? bond_amount
        : 0;
      // comparing strings and integers is allows in js
      const newModel =
        amount <= 500000 || amount === undefined ? 'small' : amount <= 1000000 ? 'medium' : 'large';
      if (props.model !== newModel) props.setModel(newModel);
    } else {
      const path = props.isBidBond
        ? `/v1/surety/contract/bid/quotes/model?principal_id=${principalId}&bid_amount=${props.values.bid_amount}`
        : `/v1/surety/contract/quotes/model?principal_id=${principalId}&bond_amount=${props.values.bond_amount}`;

      if (
        (!props.isBidBond && (bond_amount === '' || bond_amount === '0')) ||
        (props.isBidBond && (bid_amount === '' || bid_amount === '0'))
      ) {
        // principal selected, bond is not set or is 0
        if (props.model !== 'small') props.setModel('small');
      } else {
        // get props.model for principal and bond amount
        try {
          props.modelRefetch(path);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  React.useEffect(() => {
    if (props.isSubmitting) {
      props.submitForm();
    }
  }, [props.isSubmitting]);

  React.useEffect(() => {
    setRequestModel(props.selectedPrincipal, props.isBidBond);
  }, [props.values.bond_amount, props.values.bid_amount, props.selectedPrincipal]);

  // Watch for user info to be set in the user store and, once it is loaded, hydrate the form fields with it

  React.useEffect(() => {
    props.setValues(
      deepmerge(props.values, {
        point_of_contact: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          phone: user.phone.startsWith('+1') ? user.phone : `+1${user.phone}`,
        },
      }),
    );
  }, [user]);

  React.useEffect(() => {
    if (!props.isBidBond && props.submitError) {
      if (props.submitError.error === 'existing-principal') {
        props.setSnackbarMessage(
          "You have entered a principal already in the system. We've update the principal information according to what is in our system. Please check the princpal section of the form and submit again.",
        );

        handleSelectChange(props.submitError.principal_id);
      }
    } else if (props.isBidBond && props.submitBidError) {
      if (props.submitBidError.error === 'existing-principal') {
        props.setSnackbarMessage(
          "You have entered a principal already in the system. We've update the principal information according to what is in our system. Please check the princpal section of the form and submit again.",
        );

        handleSelectChange(props.submitBidError.principal_id);
      }
    }
  }, [props.submitError, props.submitBidError]);

  React.useEffect(() => {
    if (requestData) {
      if (requestData.quote_id !== undefined && props.isBidBond) {
        navigate(`/requests/contract/bid/${requestData.quote_id}/view`);
      } else if (requestData.quote_id !== undefined && !props.isBidBond) {
        navigate(`/requests/contract/${requestData.quote_id}/view`);
      }
      const requestClone = cloneDeep(requestData);
      if (requestClone.principal?.owners !== undefined) {
        delete requestClone.principal.owners;
      }
      const newValues: any = deepmerge(props.values, deepStringify(requestClone));
      props.setValues(newValues);

      if (newValues.principal?.id !== undefined) {
        props.setSelectedPrincipal(newValues.principal?.id);
      }
    }
  }, [requestData]);

  // React.useEffect(() => {
  //   if (updateError) {
  //     console.log('error updating form', updateError);
  //     return;
  //   }
  // }, [putResponse, updateError]);

  // React.useEffect(() => {
  //   if (updateBidError) {
  //     console.log('error updating form', updateBidError);
  //     return;
  //   }
  // }, [putBidResponse, updateBidError]);

  let existingPrincipals = [] as PrincipalPreview[];

  const { response } = useGetPrincipalPreviews();
  if (response && response.data) {
    existingPrincipals = response.data as PrincipalPreview[];
  }

  const existingPrincipalsMenuItems = existingPrincipals.map(principal => (
    <MenuItem value={principal.id} key={principal.id}>
      {principal.name}
    </MenuItem>
  ));

  const handleViewTermsClick = () => {
    props.isBidBond
      ? navigate(`/requests/contract/bid/${requestId}/terms`)
      : navigate(`/requests/contract/${requestId}/terms`);
  };

  React.useEffect(() => {
    if (props.modelResponse !== null && typeof props.modelResponse === 'string') {
      console.log('props.modelResponse', props.modelResponse);
      props.setModel(props.modelResponse);
    }
  }, [props.modelResponse]);

  const onSave = () => {
    if (props.model !== null) {
      const validationSchema = getValidationSchemaByModel(props.model, true, props.isBidBond);
      if (validationSchema !== undefined) {
        const newValues = validationSchema
          .noUnknown()
          .cast(stripEmptyObjects(stripEmptyStrings(cloneDeep(props.values))), { assert: false });
        newValues.principal.owners = removeNestedIds(newValues.principal.owners);
        if (props.selectedPrincipal !== 'new-principal') {
          newValues.principal.id = props.selectedPrincipal;
        }
        props.isBidBond
          ? updateBidForm(newValues as NewBondRequestDraftRequest)
          : updateForm(newValues as NewBondRequestDraftRequest);
      }
    }
  };

  const onBondFormSelectedOrUpdated = (templateId: string, uploadId: string) => {
    props.setFieldValue('bond_form_template_id', templateId);
    props.setFieldValue('bond_form_file_upload_id', uploadId);
  };

  React.useEffect(() => {
    if (
      props.values.bond_form_template_id !== (requestData?.bond_form_template_id ?? '') ||
      props.values.bond_form_file_upload_id !== (requestData?.bond_form_file_upload_id ?? '')
    ) {
      onSave();
    }
  }, [props.values.bond_form_template_id, props.values.bond_form_file_upload_id]);

  React.useEffect(() => {
    //update formik values
    if (props.principalResponse !== null) {
      const principalClone = cloneDeep(props.principalResponse);
      if (principalClone.owners !== undefined) {
        delete principalClone.owners;
      }
      props.setValues(deepmerge(props.values, { principal: deepStringify(principalClone) }));
    }
  }, [props.principalResponse]);

  const handleSelectChange = async (value: string) => {
    if (value === 'new-principal') {
      props.setValues(deepmerge(props.values, { principal: INITIAL_PRINCIPAL_FORM_VALUES }));
      // resetting owners via apiOwners in PrincipalOwnerModal
      if (props.principalResponse !== null) {
        props.principalResponse.owners = [];
      }
      setRequestModel(props.selectedPrincipal, props.isBidBond);
    } else {
      setRequestModel(value, props.isBidBond);
    }
    props.setSelectedPrincipal(value);
  };

  function isPrincipalDetailsInvalid(props: RequestFormProps) {
    return (
      props.errors.principal?.name ||
      props.errors.principal?.address ||
      props.errors.principal?.city ||
      props.errors.principal?.state ||
      props.errors.principal?.zip ||
      props.errors.principal?.naics_code ||
      props.errors.principal?.fein ||
      props.errors.principal?.largest_construction_project_completed ||
      props.errors.principal?.lost_a_payment_suit ||
      props.errors.principal?.caused_surety_loss ||
      props.errors.principal?.failed_to_complete_job ||
      props.errors.principal?.construction_projects_gross_loss_gt_10_percent_last_36_months
    );
  }

  function isPrincipalFinancialsInvalid(props: RequestFormProps) {
    return (
      props.errors.principal?.profitable_last_fiscal_year ||
      props.errors.principal?.profitable_ytd ||
      props.errors.principal?.current_construction_project_expected_gross_loss ||
      props.errors.principal?.construction_project_backlog_cost ||
      props.errors.principal?.construction_project_backlog_gross_profit ||
      props.errors.principal?.bloc_size ||
      props.errors.principal?.last_fiscal_year_end_statement
    );
  }

  const renderSidebar = () => {
    return (
      <Grid item xs={2} style={{ position: 'relative' }}>
        <Box
          sx={{ flexGrow: 1, border: '1px grey' }}
          pt={10}
          style={{ position: 'sticky', top: 0, left: 0 }}
        >
          <Card>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                justifyContent: 'center',
              }}
              aria-label='sections'
              dense={true}
            >
              <MenuItem component='a' href='#point_of_contact'>
                {props.errors.point_of_contact ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='secondary' primary='Point of Contact'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#bond'>
                {(!props.isBidBond && (props.errors.bond_amount || props.errors.contract_amount)) ||
                (props.isBidBond &&
                  (props.errors.bid_date ||
                    props.errors.bond_amount ||
                    props.errors.bid_amount ||
                    props.errors.final_bond_type)) ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Bond'></ListItemText>
              </MenuItem>
              <MenuItem href='#principal_details' component='a'>
                {isPrincipalDetailsInvalid(props) ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Principal Details'></ListItemText>
              </MenuItem>
              <MenuItem href='#principal_owners' component='a'>
                {props.errors.principal?.owners ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Principal Owners'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#principal_financials'>
                {isPrincipalFinancialsInvalid(props) ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Principal Financials'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#project'>
                {props.errors.project ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Project'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#bond_form'>
                {props.errors.bond_form_template_id || props.errors.bond_form_file_upload_id ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Bond Form'></ListItemText>
              </MenuItem>
            </List>
          </Card>
          <br></br>
          {!props.readOnly && (
            <Typography variant='caption'>
              By clicking “Submit”, the Applicant and its Owners authorize InRev to obtain personal
              and/or business credit reports for underwriting purposes in accordance with the{' '}
              <a href='#FCRA'>Fair Credit Reporting Act.</a>
            </Typography>
          )}
          <br></br>
          <br></br>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} p={1}>
            <>
              {props.readOnly ? (
                <Button variant='contained' onClick={handleViewTermsClick}>
                  View Terms
                </Button>
              ) : (
                <>
                  <LoadingButton
                    variant='contained'
                    color='secondary'
                    text={'Save'}
                    onClick={() => {
                      debounce(onSave(), 500);
                    }}
                    spinnerColor='success'
                    disabled={isUpdatingContractForm || isUpdatingBidForm}
                    isLoading={isUpdatingContractForm || isUpdatingBidForm}
                  />
                  <LoadingButton
                    text={'Submit'}
                    disabled={
                      props.isLoadingBondRequest ||
                      props.isLoadingBidBondRequest ||
                      props.isSubmitting
                    }
                    variant='contained'
                    spinnerColor='success'
                    isLoading={props.isLoadingBondRequest || props.isLoadingBidBondRequest}
                    onClick={() => props.setIsSubmitting(true)}
                  />
                </>
              )}
            </>
          </Stack>
        </Box>
      </Grid>
    );
  };

  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  return (
    <Form>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={4}
        p={4}
      >
        <Grid item xs={6} spacing={2}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h5' id='point_of_contact' style={{ scrollMarginTop: 75 }}>
              POINT OF CONTACT
            </Typography>
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  id='poc-first-name'
                  name='point_of_contact.first_name'
                  label='First Name'
                  aria-describedby='address-text'
                  fullWidth
                  inputProps={readOnlyInputProps}
                  value={props.values.point_of_contact?.first_name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.point_of_contact?.first_name &&
                    Boolean(props.errors.point_of_contact?.first_name)
                  }
                  helperText={
                    props.touched.point_of_contact?.first_name &&
                    props.errors.point_of_contact?.first_name
                  }
                />
                <TextField
                  id='poc-last-name'
                  name='point_of_contact.last_name'
                  label='Last Name'
                  aria-describedby='lastname-text'
                  fullWidth
                  inputProps={readOnlyInputProps}
                  value={props.values.point_of_contact?.last_name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.point_of_contact?.last_name &&
                    Boolean(props.errors.point_of_contact?.last_name)
                  }
                  helperText={
                    props.touched.point_of_contact?.last_name &&
                    props.errors.point_of_contact?.last_name
                  }
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <TextField
                  id='poc-email'
                  name='point_of_contact.email'
                  label='Email Address'
                  type='email'
                  inputProps={readOnlyInputProps}
                  aria-describedby='email-text'
                  fullWidth
                  value={props.values.point_of_contact?.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.point_of_contact?.email &&
                    Boolean(props.errors.point_of_contact?.email)
                  }
                  helperText={
                    props.touched.point_of_contact?.email && props.errors.point_of_contact?.email
                  }
                />
                <MuiTelInput
                  disableDropdown
                  defaultCountry='US'
                  onlyCountries={['US']}
                  id='poc-phone-number'
                  name='point_of_contact.phone'
                  label='Phone'
                  aria-describedby='phone-number'
                  fullWidth
                  inputProps={readOnlyInputProps}
                  value={field.value}
                  onChange={val => {
                    if (!val.startsWith('+1')) {
                      helpers.setValue('+1' + val);
                    } else {
                      helpers.setValue(val);
                    }
                  }}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.point_of_contact?.phone &&
                    Boolean(props.errors.point_of_contact?.phone)
                  }
                  helperText={
                    props.touched.point_of_contact?.phone && props.errors.point_of_contact?.phone
                  }
                />
              </Stack>
            </Stack>
            <br></br>
            <Typography variant='h5' id='bond' style={{ scrollMarginTop: 75 }}>
              BOND
            </Typography>
            {!props.isBidBond && (
              <>
                <Stack direction='row' spacing={2}>
                  <TextField
                    id='bond_amount'
                    name='bond_amount'
                    aria-describedby='bond-amount-text'
                    label='Bond Amount'
                    inputProps={readOnlyInputProps}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    value={props.values.bond_amount}
                    onChange={event => {
                      props.handleChange(event);
                    }}
                    onBlur={event => {
                      if (!props.values.contract_amount) {
                        props.setFieldValue('contract_amount', event.target.value);
                      }
                      props.handleBlur(event);
                    }}
                    error={props.touched.bond_amount && Boolean(props.errors.bond_amount)}
                    helperText={props.touched.bond_amount && props.errors.bond_amount}
                  />
                  <TextField
                    id='contract_amount'
                    name='contract_amount'
                    aria-describedby='contract-amount-text'
                    label='Contract Amount'
                    inputProps={readOnlyInputProps}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    value={props.values.contract_amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.contract_amount && Boolean(props.errors.contract_amount)}
                    helperText={props.touched.contract_amount && props.errors.contract_amount}
                  />
                </Stack>
              </>
            )}
            {props.isBidBond && (
              <Stack direction='column' spacing={2}>
                <Stack direction='row' spacing={2}>
                  <TextField
                    id='bond_amount'
                    name='bond_amount'
                    aria-describedby='bid-bond-amount-text'
                    label='Bid Bond Amount'
                    inputProps={readOnlyInputProps}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    value={props.values.bond_amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.bond_amount && Boolean(props.errors.bond_amount)}
                    helperText={props.touched.bond_amount && props.errors.bond_amount}
                  />
                  <TextField
                    id='bid_amount'
                    name='bid_amount'
                    aria-describedby='bid-amount-text'
                    label='Bid Amount'
                    inputProps={readOnlyInputProps}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    value={props.values.bid_amount}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched.bid_amount && Boolean(props.errors.bid_amount)}
                    helperText={props.touched.bid_amount && props.errors.bid_amount}
                  />
                </Stack>
                <Stack
                  direction='row'
                  justifyContent='space-evenly'
                  alignItems='stretch'
                  spacing={2}
                >
                  <FormControl fullWidth error={props.errors.final_bond_type}>
                    <InputLabel id='type-select-label'>Final Bond Type</InputLabel>
                    <Select
                      id='final_bond_type'
                      name='final_bond_type'
                      aria-describedby='final-bond-type-select'
                      label='Final Bond Type'
                      inputProps={readOnlyInputProps}
                      displayEmpty
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.final_bond_type}
                      error={props.touched.final_bond_type && Boolean(props.errors.final_bond_type)}
                    >
                      {typeDescriptionsMenuItems}
                    </Select>
                    {props.touched.final_bond_type && (
                      <FormHelperText>{props.errors.final_bond_type}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl error={props.touched.bid_date && Boolean(props.errors.bid_date)}>
                    <DatePicker
                      label='Bid Date'
                      readOnly={props.readOnly}
                      disablePast={true}
                      value={props.values.bid_date}
                      onChange={value =>
                        props.setFieldValue(
                          'bid_date',
                          value === null ? '' : value.toFormat('MM/dd/yyyy'),
                        )
                      }
                      helperText={props.touched.bid_date && props.errors.bid_date}
                      error={props.touched.bid_date && Boolean(props.errors.bid_date)}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            )}
            <FormControlLabel
              label='Treasury Listing Required?'
              labelPlacement='end'
              control={
                <Checkbox
                  name='t_listing_required'
                  readOnly={props.readOnly}
                  checked={props.values.t_listing_required === 'true'}
                  onChange={() => {
                    props.setFieldValue(
                      't_listing_required',
                      props.values.t_listing_required === 'true' ? 'false' : 'true',
                    );
                  }}
                  onBlur={props.handleBlur}
                />
              }
            />
          </Stack>
          <br></br>
          <br></br>
          <Typography
            variant='h5'
            gutterBottom
            id={'principal_details'}
            style={{ scrollMarginTop: 75 }}
          >
            PRINCIPAL DETAILS
          </Typography>
          <Stack direction='column' spacing={2}>
            <FormControl fullWidth>
              {/* <InputLabel id='demo-simple-select-label'>{selectedPrincipal}</InputLabel> */}
              <Select
                value={props.selectedPrincipal}
                displayEmpty
                inputProps={readOnlyInputProps}
                id='existing-principals'
                aria-describedby='scope-text'
                onChange={async (event: SelectChangeEvent) => {
                  handleSelectChange(event.target.value);
                  await props.principalRefetch(`/v1/surety/principals/${event.target.value}`);
                }}
                onBlur={props.handleBlur}
              >
                <MenuItem value='new-principal'>New Principal</MenuItem>
                {existingPrincipalsMenuItems}
              </Select>
            </FormControl>
            <Stack direction='row' spacing={2}>
              <TextField
                id='name'
                name='principal.name'
                label='Name'
                aria-describedby='name-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.principal.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.principal?.name && Boolean(props.errors.principal?.name)}
                helperText={props.touched.principal?.name && props.errors.principal?.name}
              />
              <TextField
                id='fein'
                name='principal.fein'
                label='FEIN'
                inputProps={readOnlyInputProps}
                aria-describedby='fein-text'
                value={props.values.principal.fein}
                onChange={event =>
                  props.setFieldValue(
                    'principal.fein',
                    (event.target as HTMLInputElement).value.slice(-9),
                  )
                }
                onBlur={props.handleBlur}
                error={props.touched.principal?.fein && Boolean(props.errors.principal?.fein)}
                helperText={props.touched.principal?.fein && props.errors.principal?.fein}
              />
            </Stack>
            <Stack direction='row' spacing={2}>
              <AddressAutocomplete
                valueOverride={
                  requestData?.principal?.address !== null &&
                  requestData?.principal?.address !== undefined &&
                  requestData?.principal?.address !== '' &&
                  requestData?.principal?.city !== null &&
                  requestData?.principal?.city !== undefined &&
                  requestData?.principal?.city !== '' &&
                  requestData?.principal?.state !== null &&
                  requestData?.principal?.state !== undefined &&
                  requestData?.principal?.state.toString() !== '' &&
                  requestData?.principal?.zip !== null &&
                  requestData?.principal?.zip !== undefined &&
                  requestData?.principal?.zip !== ''
                    ? getFullAddress(
                        requestData?.principal?.address,
                        requestData?.principal?.city,
                        requestData?.principal?.state,
                        requestData?.principal?.zip,
                      )
                    : props.principalResponse?.address !== null &&
                      props.principalResponse?.address !== undefined &&
                      props.principalResponse?.address !== '' &&
                      props.principalResponse?.city !== null &&
                      props.principalResponse?.city !== undefined &&
                      props.principalResponse?.city !== '' &&
                      props.principalResponse?.state !== null &&
                      props.principalResponse?.state !== undefined &&
                      props.principalResponse?.state !== '' &&
                      props.principalResponse?.zip !== null &&
                      props.principalResponse?.zip !== undefined &&
                      props.principalResponse?.zip !== ''
                    ? getFullAddress(
                        props.principalResponse?.address,
                        props.principalResponse?.city,
                        props.principalResponse?.state,
                        props.principalResponse?.zip,
                      )
                    : null
                }
                onValidation={result => {
                  props.setFieldValue(
                    'principal.address',
                    !result?.valid ? '' : result?.address ?? '',
                  );
                  props.setFieldTouched('principal.address', true);
                  props.setFieldValue('principal.city', result?.city ?? '');
                  props.setFieldValue('principal.state', result?.state ?? '');
                  props.setFieldValue('principal.zip', result?.zip ?? '');
                }}
                error={props.values.principal?.address === '' && props.touched.principal?.address}
                readOnly={props.readOnly}
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <FormControl fullWidth error={props.errors.principal?.naics_code}>
                <InputLabel id='demo-simple-select-label'>Specialty </InputLabel>
                <Select
                  id='specialty'
                  name='principal.naics_code'
                  label='Specialty'
                  inputProps={readOnlyInputProps}
                  aria-describedby='specialty-select'
                  value={props.values.principal.naics_code}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.principal?.naics_code &&
                    Boolean(props.errors.principal?.naics_code)
                  }
                >
                  {naicsCodeDescriptionsMenuItems}
                </Select>
                {props.touched.principal?.naics_code && (
                  <FormHelperText>{props.errors.principal?.naics_code}</FormHelperText>
                )}
              </FormControl>
              {(props.model === 'small' || props.model === 'medium' || props.model === 'large') && (
                <TextField
                  id='largest-project-amount'
                  inputProps={readOnlyInputProps}
                  name='principal.largest_construction_project_completed'
                  aria-describedby='largest-project-amount-text'
                  label='Largest Project Completed'
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  value={props.values.principal.largest_construction_project_completed}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.principal?.largest_construction_project_completed &&
                    Boolean(props.errors.principal?.largest_construction_project_completed)
                  }
                  helperText={
                    props.touched.principal?.largest_construction_project_completed &&
                    props.errors.principal?.largest_construction_project_completed
                  }
                />
              )}
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={4}
              >
                {(props.model === 'small' ||
                  props.model === 'medium' ||
                  props.model === 'large') && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='body1'>
                        Has the principal ever failed to complete a project?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={
                          props.touched.principal?.failed_to_complete_a_construction_job &&
                          Boolean(props.errors.principal?.failed_to_complete_a_construction_job)
                        }
                      >
                        <RadioGroup
                          row
                          name='principal.failed_to_complete_a_construction_job'
                          value={props.values.principal?.failed_to_complete_a_construction_job}
                          onChange={event => {
                            props.setFieldValue(
                              'principal.failed_to_complete_a_construction_job',
                              event.currentTarget.value,
                            );
                          }}
                          onBlur={props.handleBlur}
                        >
                          <FormControlLabel
                            value='true'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='Yes'
                          />
                          <FormControlLabel
                            value='false'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='No'
                          />
                        </RadioGroup>
                        {props.touched.principal?.failed_to_complete_a_construction_job && (
                          <FormHelperText>
                            {props.errors.principal?.failed_to_complete_a_construction_job}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {(props.model === 'medium' || props.model === 'large') && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='body1'>
                        In the past 3 years, has the principal experienced a gross loss on any
                        project greater than 10% of that project&apos;s contract value? (ex. a gross
                        loss of at least $100,000 on a $1,000,000 project)
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={
                          props.touched.principal
                            ?.construction_projects_gross_loss_gt_10_percent_last_36_months &&
                          Boolean(
                            props.errors.principal
                              ?.construction_projects_gross_loss_gt_10_percent_last_36_months,
                          )
                        }
                      >
                        <RadioGroup
                          row
                          name='principal.construction_projects_gross_loss_gt_10_percent_last_36_months'
                          value={
                            props.values.principal
                              ?.construction_projects_gross_loss_gt_10_percent_last_36_months
                          }
                          onChange={event => {
                            props.setFieldValue(
                              'principal.construction_projects_gross_loss_gt_10_percent_last_36_months',
                              event.currentTarget.value,
                            );
                          }}
                          onBlur={props.handleBlur}
                        >
                          <FormControlLabel
                            value='true'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='Yes'
                          />
                          <FormControlLabel
                            value='false'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='No'
                          />
                        </RadioGroup>
                        {props.touched.principal
                          ?.construction_projects_gross_loss_gt_10_percent_last_36_months && (
                          <FormHelperText>
                            {
                              props.errors.principal
                                ?.construction_projects_gross_loss_gt_10_percent_last_36_months
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {(props.model === 'medium' || props.model === 'large') && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='body1'>
                        Does the principal expect a gross loss on any projects they are currently
                        working on?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={
                          props.touched.principal
                            ?.current_construction_project_expected_gross_loss &&
                          Boolean(
                            props.errors.principal
                              ?.current_construction_project_expected_gross_loss,
                          )
                        }
                      >
                        <RadioGroup
                          row
                          name='principal.current_construction_project_expected_gross_loss'
                          value={
                            props.values.principal?.current_construction_project_expected_gross_loss
                          }
                          onChange={event => {
                            props.setFieldValue(
                              'principal.current_construction_project_expected_gross_loss',
                              event.currentTarget.value,
                            );
                          }}
                          onBlur={props.handleBlur}
                        >
                          <FormControlLabel
                            value='true'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='Yes'
                          />
                          <FormControlLabel
                            value='false'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='No'
                          />
                        </RadioGroup>
                        {props.touched.principal
                          ?.current_construction_project_expected_gross_loss && (
                          <FormHelperText>
                            {
                              props.errors.principal
                                ?.current_construction_project_expected_gross_loss
                            }
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {(props.model === 'small' ||
                  props.model === 'medium' ||
                  props.model === 'large') && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='body1'>
                        Has the principal ever lost a payment suit?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={
                          props.touched.principal?.lost_a_payment_suit &&
                          Boolean(props.errors.principal?.lost_a_payment_suit)
                        }
                      >
                        <RadioGroup
                          row
                          name='principal.lost_a_payment_suit'
                          value={props.values.principal?.lost_a_payment_suit}
                          onChange={event => {
                            props.setFieldValue(
                              'principal.lost_a_payment_suit',
                              event.currentTarget.value,
                            );
                          }}
                          onBlur={props.handleBlur}
                        >
                          <FormControlLabel
                            value='true'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='Yes'
                          />
                          <FormControlLabel
                            value='false'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='No'
                          />
                        </RadioGroup>
                        {props.touched.principal?.lost_a_payment_suit && (
                          <FormHelperText>
                            {props.errors.principal?.lost_a_payment_suit}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {(props.model === 'small' ||
                  props.model === 'medium' ||
                  props.model === 'large') && (
                  <>
                    <Grid item xs={8}>
                      <Typography variant='body1'>
                        Has the principal ever caused a loss to a surety?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        error={
                          props.touched.principal?.caused_surety_loss &&
                          Boolean(props.errors.principal?.caused_surety_loss)
                        }
                      >
                        <RadioGroup
                          row
                          name='principal.caused_surety_loss'
                          value={props.values.principal?.caused_surety_loss}
                          onChange={event => {
                            props.setFieldValue(
                              'principal.caused_surety_loss',
                              event.currentTarget.value,
                            );
                          }}
                          onBlur={props.handleBlur}
                        >
                          <FormControlLabel
                            value='true'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='Yes'
                          />
                          <FormControlLabel
                            value='false'
                            disabled={props.readOnly}
                            control={
                              <Radio
                                sx={{
                                  color: '#111835',
                                  '&.Mui-checked': {
                                    color: '#111835',
                                  },
                                  '&.Mui-disabled': {
                                    color: '#111835',
                                  },
                                }}
                              />
                            }
                            label='No'
                          />
                        </RadioGroup>
                        {props.touched.principal?.caused_surety_loss && (
                          <FormHelperText>
                            {props.errors.principal?.caused_surety_loss}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>
          </Stack>
          <br></br>
          <br></br>
          <Typography variant='h5' id='principal_owners' style={{ scrollMarginTop: 75 }}>
            PRINCIPAL OWNERS
          </Typography>
          {props.touched.principal?.owners && (
            <FormHelperText error>{props.errors.principal?.owners}</FormHelperText>
          )}
          <PrincipalOwnerModal
            onOwnersChange={onOwnersChange}
            readOnly={props.readOnly}
            apiOwners={
              props.principalResponse?.owners || (requestData?.principal?.owners as Owner[])
            }
          />
          <br></br>
          <br></br>
          <Typography variant='h5' id='principal_financials' style={{ scrollMarginTop: 75 }}>
            PRINCIPAL FINANCIALS
          </Typography>
          <br></br>
          <Stack direction='column' spacing={4}>
            {(props.model === 'medium' || props.model === 'large') && (
              <FormControl>
                <Stack direction='column' spacing={2}>
                  <Typography variant='body1'>
                    {"When was the principal's last FYE statement prepared?"}
                  </Typography>
                  <DatePicker
                    style={{ width: 250 }}
                    disabled={props.readOnly}
                    disableFuture={true}
                    placeholder={'Select a date'}
                    value={props.values.principal.last_fiscal_year_end_statement.date}
                    onChange={value =>
                      props.setFieldValue(
                        'principal.last_fiscal_year_end_statement.date',
                        value !== null ? value.toFormat('MM/dd/yyyy') : '',
                      )
                    }
                    helperText={
                      props.touched.principal?.last_fiscal_year_end_statement?.date &&
                      props.errors.principal?.last_fiscal_year_end_statement?.date
                    }
                    error={
                      props.touched.principal?.last_fiscal_year_end_statement?.date &&
                      Boolean(props.errors.principal?.last_fiscal_year_end_statement?.date)
                    }
                  />
                </Stack>
              </FormControl>
            )}
            {(props.model === 'medium' || props.model === 'large') && (
              <Stack direction='column' spacing={2}>
                <Typography variant='body1'>How was the statement prepared?</Typography>
                <FormControl
                  style={{ width: 300 }}
                  error={
                    props.touched.principal?.last_fiscal_year_end_statement?.preparation_method
                  }
                >
                  <Select
                    displayEmpty
                    inputProps={readOnlyInputProps}
                    labelId='last_fiscal_year_end_statement.preparation_method_label'
                    id='principal.last_fiscal_year_end_statement.preparation_method'
                    name='principal.last_fiscal_year_end_statement.preparation_method'
                    value={props.values.principal.last_fiscal_year_end_statement.preparation_method}
                    renderValue={
                      props.values.principal.last_fiscal_year_end_statement.preparation_method !==
                      ''
                        ? undefined
                        : () => <InputLabel disabled>Select One</InputLabel>
                    }
                    onChange={props.handleChange}
                    error={
                      props.touched.principal?.last_fiscal_year_end_statement?.preparation_method &&
                      Boolean(
                        props.errors.principal?.last_fiscal_year_end_statement?.preparation_method,
                      )
                    }
                    onBlur={props.handleBlur}
                  >
                    {financialStatementTypeMenuItems}
                  </Select>
                  {props.touched.principal?.last_fiscal_year_end_statement?.preparation_method && (
                    <FormHelperText>
                      {props.errors.principal?.last_fiscal_year_end_statement?.preparation_method}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            )}
          </Stack>
          <br></br>
          <br></br>
          {props.model !== 'small' && (
            <>
              <Divider>
                {props.values.principal?.last_fiscal_year_end_statement?.date && (
                  <Chip
                    label={`Balance Sheet ${props.values.principal?.last_fiscal_year_end_statement?.date}`}
                  />
                )}
                {!props.values.principal?.last_fiscal_year_end_statement?.date && (
                  <Chip label={`Balance Sheet (Last FYE)`} />
                )}
              </Divider>
              <Stack direction='column' spacing={4} p={4}>
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={4}
                >
                  {(props.model === 'medium' || props.model === 'large') && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Cash</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='corporate-cash-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='corporate-cash-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.corporate_cash
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.corporate_cash'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.corporate_cash &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.corporate_cash,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.corporate_cash &&
                            props.errors.principal?.last_fiscal_year_end_statement?.corporate_cash
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {props.model === 'large' && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Accounts Receivable(&lt;90 days)</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='accounts-receivable-amount'
                          inputProps={readOnlyInputProps}
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.accounts_receivable
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          aria-describedby='accounts-receivable-amount-text'
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.accounts_receivable'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accounts_receivable &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.accounts_receivable,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accounts_receivable &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.accounts_receivable
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {props.model === 'large' && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Underbillings</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='underbillings-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='underbillings-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.underbillings
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.underbillings'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.underbillings &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement?.underbillings,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.underbillings &&
                            props.errors.principal?.last_fiscal_year_end_statement?.underbillings
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {(props.model === 'medium' || props.model === 'large') && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Current Assets</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='current-assets-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='current-assets-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.current_assets
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.current_assets'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_assets &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.current_assets,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_assets &&
                            props.errors.principal?.last_fiscal_year_end_statement?.current_assets
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {(props.model === 'medium' || props.model === 'large') && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Total Assets</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='total-assets-cash-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='total-assets-cash-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.total_assets
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.total_assets'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement?.total_assets &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement?.total_assets,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement?.total_assets &&
                            props.errors.principal?.last_fiscal_year_end_statement?.total_assets
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {props.model === 'large' && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Accounts Payable</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='cash-amount'
                          aria-describedby='cash-amount-text'
                          inputProps={readOnlyInputProps}
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.accounts_payable
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.accounts_payable'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accounts_payable &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.accounts_payable,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accounts_payable &&
                            props.errors.principal?.last_fiscal_year_end_statement?.accounts_payable
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Credit Cards Payable</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='credit-cards-payable-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='credit-cards-payable-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.credit_cards_payable
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.credit_cards_payable'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.credit_cards_payable &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.credit_cards_payable,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.credit_cards_payable &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.credit_cards_payable
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Revolving Line of Credit</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='bank-line-payable-amount'
                          inputProps={readOnlyInputProps}
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.bank_line_payable
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          aria-describedby='bank-line-payable-amount-text'
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.bank_line_payable'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.bank_line_payable &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.bank_line_payable,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.bank_line_payable &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.bank_line_payable
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Accrued Expenses</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='accrued-expense-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='accrued-expense-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.accrued_expense
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.accrued_expense'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accrued_expense &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.accrued_expense,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.accrued_expense &&
                            props.errors.principal?.last_fiscal_year_end_statement?.accrued_expense
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Overbillings</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='overbillings-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='overbillings-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.overbillings
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.overbillings'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement?.overbillings &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement?.overbillings,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement?.overbillings &&
                            props.errors.principal?.last_fiscal_year_end_statement?.overbillings
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Current Portion of Long Term Debt</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='current-portion-of-ltd-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='current-portion-of-ltd-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.current_portion_of_ltd
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.current_portion_of_ltd'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_portion_of_ltd &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.current_portion_of_ltd,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_portion_of_ltd &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.current_portion_of_ltd
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {(props.model === 'medium' || props.model === 'large') && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Current Liabilities</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='current-liabilities-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='current-liabilities-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.current_liabilities
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.current_liabilities'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_liabilities &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.current_liabilities,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.current_liabilities &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.current_liabilities
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {props.model === 'large' && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Term Loan Debt</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='term-loan-debt-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='term-loan-debt-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement?.term_loan_debt
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.term_loan_debt'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.term_loan_debt &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.term_loan_debt,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.term_loan_debt &&
                            props.errors.principal?.last_fiscal_year_end_statement?.term_loan_debt
                          }
                        />
                      </Grid>
                    </>
                  )}
                  {(props.model === 'medium' || props.model === 'large') && (
                    <>
                      <Grid item xs={6}>
                        <Typography variant='body1'>Total Liabilities</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id='total-liabilities-amount'
                          inputProps={readOnlyInputProps}
                          aria-describedby='total-liabilities-amount-text'
                          value={
                            props.values.principal?.last_fiscal_year_end_statement
                              ?.total_liabilities
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                          fullWidth
                          name='principal.last_fiscal_year_end_statement.total_liabilities'
                          error={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.total_liabilities &&
                            Boolean(
                              props.errors.principal?.last_fiscal_year_end_statement
                                ?.total_liabilities,
                            )
                          }
                          helperText={
                            props.touched.principal?.last_fiscal_year_end_statement
                              ?.total_liabilities &&
                            props.errors.principal?.last_fiscal_year_end_statement
                              ?.total_liabilities
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
              <br></br>
              <Divider>
                <Chip label='Current Work in Progress' />
              </Divider>
              <br></br>
              <Stack direction='row' spacing={4}>
                {(props.model === 'medium' || props.model === 'large') && (
                  <TextField
                    id='construction-project-backlog-cost-amount'
                    inputProps={readOnlyInputProps}
                    aria-describedby='construction-project-backlog-cost-amount-text'
                    value={props.values.principal?.construction_project_backlog_cost}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    label='Backlog Cost-to-Complete'
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    name='principal.construction_project_backlog_cost'
                    error={
                      props.touched.principal?.construction_project_backlog_cost &&
                      Boolean(props.errors.principal?.construction_project_backlog_cost)
                    }
                    helperText={
                      props.touched.principal?.construction_project_backlog_cost &&
                      props.errors.principal?.construction_project_backlog_cost
                    }
                  />
                )}
                {props.model === 'large' && (
                  <TextField
                    id='construction-project-backlog-gross-profit-amount'
                    inputProps={readOnlyInputProps}
                    aria-describedby='construction-project-backlog-gross-profit-amount-text'
                    label='Gross Profit in the Backlog'
                    value={props.values.principal?.construction_project_backlog_gross_profit}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    fullWidth
                    name='principal.construction_project_backlog_gross_profit'
                    error={
                      props.touched.principal?.construction_project_backlog_gross_profit &&
                      Boolean(props.errors.principal?.construction_project_backlog_gross_profit)
                    }
                    helperText={
                      props.touched.principal?.construction_project_backlog_gross_profit &&
                      props.errors.principal?.construction_project_backlog_gross_profit
                    }
                  />
                )}
              </Stack>
              <br></br>
              {props.model === 'large' && (
                <>
                  <Divider>
                    <Chip label='Bank Details' />
                  </Divider>
                  <TextField
                    id='bloc-size-amount'
                    inputProps={readOnlyInputProps}
                    aria-describedby='bloc-size-amount-text'
                    value={props.values.principal?.bloc_size}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    label='Revolving Line of Credit Limit'
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    name='principal.bloc_size'
                    error={
                      props.touched.principal?.bloc_size &&
                      Boolean(props.errors.principal?.bloc_size)
                    }
                    helperText={
                      props.touched.principal?.bloc_size && props.errors.principal?.bloc_size
                    }
                  />
                </>
              )}
            </>
          )}
          <br></br>
          <Divider>
            {props.values.principal?.last_fiscal_year_end_statement?.date && (
              <Chip
                label={`Income Statement ${props.values.principal?.last_fiscal_year_end_statement?.date}`}
              />
            )}
            {!props.values.principal?.last_fiscal_year_end_statement?.date && (
              <Chip label={`Income Statement (Last FYE)`} />
            )}
          </Divider>
          <br></br>
          <Stack direction='column' spacing={2}>
            {(props.model === 'medium' || props.model === 'large') && (
              <TextField
                id='revenue-amount'
                inputProps={readOnlyInputProps}
                aria-describedby='revenue-amount-text'
                label='Revenue (Last FYE)'
                name='principal.last_fiscal_year_end_statement.revenue'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                value={props.values.principal.last_fiscal_year_end_statement.revenue}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.principal?.last_fiscal_year_end_statement?.revenue &&
                  Boolean(props.errors.principal?.last_fiscal_year_end_statement?.revenue)
                }
                helperText={
                  props.touched.principal?.last_fiscal_year_end_statement?.revenue &&
                  props.errors.principal?.last_fiscal_year_end_statement?.revenue
                }
              />
            )}
            {props.model === 'large' && (
              <TextField
                id='admin-expense-amount'
                inputProps={readOnlyInputProps}
                aria-describedby='admin-expense-amount-text'
                label='General Administrative Expense (Last FYE)'
                name='principal.last_fiscal_year_end_statement.ga_expense'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                value={props.values.principal?.last_fiscal_year_end_statement?.ga_expense}
                onChange={event => {
                  props.setFieldValue(
                    'principal.last_fiscal_year_end_statement.ga_expense',
                    event.currentTarget.value,
                  );
                }}
                onBlur={props.handleBlur}
                error={
                  props.touched.principal?.last_fiscal_year_end_statement?.ga_expense &&
                  Boolean(props.errors.principal?.last_fiscal_year_end_statement?.ga_expense)
                }
                helperText={
                  props.touched.principal?.last_fiscal_year_end_statement?.ga_expense &&
                  props.errors.principal?.last_fiscal_year_end_statement?.ga_expense
                }
              />
            )}
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={4}
            >
              {(props.model === 'small' || props.model === 'medium' || props.model === 'large') && (
                <>
                  <Grid item xs={8}>
                    <Typography variant='body1'>Profitable this year to date? </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      error={
                        props.touched.principal?.profitable_ytd &&
                        Boolean(props.errors.principal?.profitable_ytd)
                      }
                    >
                      <RadioGroup
                        row
                        name='principal.profitable_ytd'
                        value={props.values.principal?.profitable_ytd}
                        onChange={event => {
                          props.setFieldValue(
                            'principal.profitable_ytd',
                            event.currentTarget.value,
                          );
                        }}
                        onBlur={props.handleBlur}
                      >
                        <FormControlLabel
                          value='true'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='Yes'
                        />
                        <FormControlLabel
                          value='false'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='No'
                        />
                      </RadioGroup>
                      {props.touched.principal?.profitable_ytd && (
                        <FormHelperText>{props.errors.principal?.profitable_ytd}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='body1'>Profitable Last FYE?</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      error={
                        props.touched.principal?.profitable_last_fiscal_year &&
                        Boolean(props.errors.principal?.profitable_last_fiscal_year)
                      }
                    >
                      <RadioGroup
                        row
                        name='principal.profitable_last_fiscal_year'
                        value={props.values.principal?.profitable_last_fiscal_year}
                        onChange={event => {
                          props.setFieldValue(
                            'principal.profitable_last_fiscal_year',
                            event.currentTarget.value,
                          );
                        }}
                        onBlur={props.handleBlur}
                      >
                        <FormControlLabel
                          value='true'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='Yes'
                        />
                        <FormControlLabel
                          value='false'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='No'
                        />
                      </RadioGroup>
                      {props.touched.principal?.profitable_last_fiscal_year && (
                        <FormHelperText>
                          {props.errors.principal?.profitable_last_fiscal_year}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
              {props.model === 'large' && (
                <>
                  <Grid item xs={8}>
                    <Typography variant='body1'>
                      Profitable year prior to last fiscal year?
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      error={
                        props.touched.principal?.profitable_year_prior_to_last_fiscal_year &&
                        Boolean(props.errors.principal?.profitable_year_prior_to_last_fiscal_year)
                      }
                    >
                      <RadioGroup
                        row
                        name='principal.profitable_year_prior_to_last_fiscal_year'
                        value={props.values.principal?.profitable_year_prior_to_last_fiscal_year}
                        onChange={event => {
                          props.setFieldValue(
                            'principal.profitable_year_prior_to_last_fiscal_year',
                            event.currentTarget.value,
                          );
                        }}
                        onBlur={props.handleBlur}
                      >
                        <FormControlLabel
                          value='true'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='Yes'
                        />
                        <FormControlLabel
                          value='false'
                          disabled={props.readOnly}
                          control={
                            <Radio
                              sx={{
                                color: '#111835',
                                '&.Mui-checked': {
                                  color: '#111835',
                                },
                                '&.Mui-disabled': {
                                  color: '#111835',
                                },
                              }}
                            />
                          }
                          label='No'
                        />
                      </RadioGroup>
                      {props.touched.principal?.profitable_year_prior_to_last_fiscal_year && (
                        <FormHelperText>
                          {props.errors.principal?.profitable_year_prior_to_last_fiscal_year}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>
          <br></br>
          <Typography variant='h5' id='project' style={{ scrollMarginTop: 75 }}>
            PROJECT
          </Typography>
          <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={2}>
              <TextField
                id='address'
                name='project.address'
                label='Address'
                aria-describedby='address-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.project?.address}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.project?.address && Boolean(props.errors.project?.address)}
                helperText={props.touched.project?.address && props.errors.project?.address}
              />
            </Stack>
            <Stack direction='row' spacing={2}>
              <TextField
                id='city'
                name='project.city'
                label='City'
                aria-describedby='city-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.project?.city}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.project?.city && Boolean(props.errors.project?.city)}
                helperText={props.touched.project?.city && props.errors.project?.city}
              />
              <FormControl fullWidth error={props.errors.project?.state}>
                <InputLabel id='demo-simple-select-label'>State</InputLabel>
                <Select
                  id='state'
                  name='project.state'
                  label='State'
                  inputProps={readOnlyInputProps}
                  aria-describedby='state-select'
                  value={props.values.project?.state}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.project?.state && Boolean(props.errors.project?.state)}
                  displayEmpty
                  placeholder='Select'
                >
                  {stateMenuItems}
                </Select>
                {props.touched.project?.state && (
                  <FormHelperText>{props.errors.project?.state}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id='zip'
                name='project.zip'
                label='Zip'
                aria-describedby='zip-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.project?.zip}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={props.touched.project?.zip && Boolean(props.errors.project?.zip)}
                helperText={props.touched.project?.zip && props.errors.project?.zip}
              />
            </Stack>
            <Stack direction='column' spacing={2}>
              <FormControl fullWidth error={props.errors.project?.scope_of_work}>
                <InputLabel id='demo-simple-select-label'>Scope of Work </InputLabel>
                <Select
                  id='project.scope_of_work'
                  label='Scope of Work'
                  name='project.scope_of_work'
                  aria-describedby='scope-text'
                  inputProps={readOnlyInputProps}
                  value={props.values.project?.scope_of_work}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.project?.scope_of_work &&
                    Boolean(props.errors.project?.scope_of_work)
                  }
                  displayEmpty
                >
                  {naicsCodeDescriptionsMenuItems}
                </Select>
                {props.touched.project?.scope_of_work && (
                  <FormHelperText>{props.errors.project?.scope_of_work}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id='duration'
                name='project.months_to_complete'
                label='How many months will it take to complete the work?'
                aria-describedby='duration-text'
                inputProps={readOnlyInputProps}
                value={props.values.project?.months_to_complete}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.project?.months_to_complete &&
                  Boolean(props.errors.project?.months_to_complete)
                }
                helperText={
                  props.touched.project?.months_to_complete &&
                  props.errors.project?.months_to_complete
                }
              />
              <TextField
                id='warranty'
                name='project.contract_warranty_months'
                label='How many months is the warranty period?'
                aria-describedby='warranty-text'
                inputProps={readOnlyInputProps}
                value={props.values.project.contract_warranty_months}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.project?.contract_warranty_months &&
                  Boolean(props.errors.project?.contract_warranty_months)
                }
                helperText={
                  props.touched.project?.contract_warranty_months &&
                  props.errors.project?.contract_warranty_months
                }
              />
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={8}>
                  <Typography variant='body1'>
                    Is this a completion bond (i.e., is the Principal responsible for financing the
                    project)?{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    error={
                      props.touched.project?.completion_bond &&
                      Boolean(props.errors.project?.completion_bond)
                    }
                  >
                    <RadioGroup
                      row
                      name='project.completion_bond'
                      id='project.completion_bond'
                      value={props.values.project?.completion_bond}
                      onChange={event => {
                        props.setFieldValue('project.completion_bond', event.currentTarget.value);
                      }}
                      onBlur={props.handleBlur}
                    >
                      <FormControlLabel
                        value='true'
                        disabled={props.readOnly}
                        control={
                          <Radio
                            sx={{
                              color: '#111835',
                              '&.Mui-checked': {
                                color: '#111835',
                              },
                              '&.Mui-disabled': {
                                color: '#111835',
                              },
                            }}
                          />
                        }
                        label='Yes'
                      />
                      <FormControlLabel
                        value='false'
                        disabled={props.readOnly}
                        control={
                          <Radio
                            sx={{
                              color: '#111835',
                              '&.Mui-checked': {
                                color: '#111835',
                              },
                              '&.Mui-disabled': {
                                color: '#111835',
                              },
                            }}
                          />
                        }
                        label='No'
                      />
                    </RadioGroup>
                    {props.touched.project?.completion_bond && (
                      <FormHelperText>{props.errors.project?.completion_bond}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Stack>
              <Stack
                direction='column'
                justifyContent='space-between'
                alignItems='flex-start'
                spacing={2}
              >
                <Typography variant='body1'>
                  Does this contract include any of the following damages? (Check all that apply:)
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_damages'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_damages.includes('consequential')}
                        onChange={() => {
                          if (props.values.project?.contract_damages.includes('consequential')) {
                            props.setFieldValue(
                              'project.contract_damages',
                              props.values.project?.contract_damages.filter(
                                (value: string) => value !== 'consequential',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_damages
                              ? props.values.project?.contract_damages
                              : [];
                            const updatedArray = [...previousValues, 'consequential'];
                            props.setFieldValue('project.contract_damages', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Consequential damages'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_damages'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_damages.includes('actual')}
                        onChange={() => {
                          if (props.values.project?.contract_damages.includes('actual')) {
                            props.setFieldValue(
                              'project.contract_damages',
                              props.values.project?.contract_damages.filter(
                                (value: string) => value !== 'actual',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_damages
                              ? props.values.project?.contract_damages
                              : [];
                            const updatedArray = [...previousValues, 'actual'];
                            props.setFieldValue('project.contract_damages', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Actual damages'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='liquidated_gt_2500'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_damages.includes(
                          'liquidated_gt_2500',
                        )}
                        onChange={() => {
                          if (
                            props.values.project?.contract_damages.includes('liquidated_gt_2500')
                          ) {
                            props.setFieldValue(
                              'project.contract_damages',
                              props.values.project?.contract_damages.filter(
                                (value: string) => value !== 'liquidated_gt_2500',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_damages
                              ? props.values.project?.contract_damages
                              : [];
                            const updatedArray = [...previousValues, 'liquidated_gt_2500'];
                            props.setFieldValue('project.contract_damages', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Liquidated damages greater than $2,500'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='other'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_damages.includes('other')}
                        onChange={() => {
                          if (props.values.project?.contract_damages.includes('other')) {
                            props.setFieldValue(
                              'project.contract_damages',
                              props.values.project?.contract_damages.filter(
                                (value: string) => value !== 'other',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_damages
                              ? props.values.project?.contract_damages
                              : [];
                            const updatedArray = [...previousValues, 'other'];
                            props.setFieldValue('project.contract_damages', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Other damages / I’m unsure'
                  />
                </FormGroup>
              </Stack>
              <Stack
                direction='column'
                justifyContent='space-between'
                alignItems='flex-start'
                spacing={2}
              >
                <Typography variant='body1'>
                  Do any of the following apply to the contract? (Check all that apply:)
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes('design_build')}
                        onChange={() => {
                          if (props.values.project?.contract_hazards.includes('design_build')) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'design_build',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'design_build'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Design-build '
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes(
                          'environmental_or_remediation',
                        )}
                        onChange={() => {
                          if (
                            props.values.project?.contract_hazards.includes(
                              'environmental_or_remediation',
                            )
                          ) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'environmental_or_remediation',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [
                              ...previousValues,
                              'environmental_or_remediation',
                            ];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Environmental or remediation work'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes('superfund')}
                        onChange={() => {
                          if (props.values.project?.contract_hazards.includes('superfund')) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'superfund',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'superfund'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Work at a superfund site'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes(
                          'efficiency_guarantees',
                        )}
                        onChange={() => {
                          if (
                            props.values.project?.contract_hazards.includes('efficiency_guarantees')
                          ) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'efficiency_guarantees',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'efficiency_guarantees'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Efficiency guarantees'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes(
                          'sovereign_nation_owner',
                        )}
                        onChange={() => {
                          if (
                            props.values.project?.contract_hazards.includes(
                              'sovereign_nation_owner',
                            )
                          ) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'sovereign_nation_owner',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'sovereign_nation_owner'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Work for a sovereign nation owner'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes('renewable')}
                        onChange={() => {
                          if (props.values.project?.contract_hazards.includes('renewable')) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'renewable',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'renewable'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Renewable contract'
                  />
                  <FormControlLabel
                    labelPlacement='end'
                    control={
                      <Checkbox
                        name='contract_hazards'
                        readOnly={props.readOnly}
                        checked={props.values.project?.contract_hazards.includes('takeover')}
                        onChange={() => {
                          if (props.values.project?.contract_hazards.includes('takeover')) {
                            props.setFieldValue(
                              'project.contract_hazards',
                              props.values.project?.contract_hazards.filter(
                                (value: string) => value !== 'takeover',
                              ),
                            );
                          } else {
                            const previousValues = props.values.project?.contract_hazards
                              ? props.values.project?.contract_hazards
                              : [];
                            const updatedArray = [...previousValues, 'takeover'];
                            props.setFieldValue('project.contract_hazards', updatedArray);
                          }
                        }}
                        onBlur={props.handleBlur}
                      />
                    }
                    label='Takeover of an existing contract'
                  />
                </FormGroup>
              </Stack>
            </Stack>
          </Stack>
          <br></br>
          <br></br>
          <Typography variant='h5' gutterBottom id='bond_form' style={{ scrollMarginTop: 75 }}>
            BOND FORM
          </Typography>
          {(props.touched.bond_form_template_id || props.touched.bond_form_file_upload_id) && (
            <FormHelperText error={true}>{props.errors.bond_form_template_id}</FormHelperText>
          )}
          <BondFormSelectionModal
            {...props}
            draftId={props.draftId}
            readOnly={props.readOnly}
            onBondFormSelectedOrUpdated={onBondFormSelectedOrUpdated}
          />
          <br></br>
        </Grid>
        {renderSidebar()}
      </Grid>
    </Form>
  );
}
