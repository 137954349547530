import FormNavBar from '../../../components/FormNavBar';
import { FormContent } from './FormContent';

type FinalRequestFormViewProps = {
  draftId: string;
};
export const FinalRequestFormView = ({ draftId }: FinalRequestFormViewProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <FormNavBar />
      <FormContent draftId={draftId} />
    </div>
  );
};
