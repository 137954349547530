import 'yup-phone';
import Yup from './custom';

export const FINAL_DRAFT_BOND_SCHEMA = Yup.object()
  .shape(
    {
      t_listing_required: Yup.boolean().nullable().optional(),
      bond_form_template_id: Yup.string().when('bond_form_file_upload_id', {
        is: (val: string) => !val,
        then: Yup.string().optional(),
        otherwise: Yup.string(),
      }),
      bond_form_file_upload_id: Yup.string().when('bond_form_template_id', {
        is: (val: string) => !val,
        then: Yup.string().optional(),
        otherwise: Yup.string(),
      }),
      contract_amount: Yup.number().optional(),
      second_bid: Yup.number().optional(),
      bond_amount: Yup.number().optional(),
      actual_bid_date: Yup.string().optional(),
      contract_date: Yup.string().optional(),
      point_of_contact: Yup.object({
        first_name: Yup.string().trim().optional(),
        last_name: Yup.string().trim().optional(),
        email: Yup.string().email('Not a valid email.').trim().optional(),
        phone: Yup.string()
          .trim()
          .test('test-phone', 'Invalid US Phone Number.', (value: string | undefined) => {
            if (value) return Yup.string().phone('Invalid US Phone Number').isValidSync(value);
            return true;
          })
          .optional(),
      }).noUnknown(),
    },
    [
      ['bond_form_template_id', 'bond_form_file_upload_id'],
      ['bond_form_file_upload_id', 'bond_form_template_id'],
    ],
  )
  .noUnknown();
