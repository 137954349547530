import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@mui/material';

import { LoadingButton } from '../components/LoadingButton';
import { useStore } from '../store';
import { useCompleteAgentSetup } from '../services/agents';
import { USStateOrTerritory, USStateOrTerritoryName } from '../constants/usStates';
import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input';
import debounce from 'debounce';

type Form = {
  first_name: string;
  last_name: string;
  phone: string;
  npn: string;
  resident_license_state: string;
};

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

const defaultForm = {
  first_name: '',
  last_name: '',
  phone: '',
  npn: '',
  resident_license_state: '',
};

export const AccountSetupDialog = () => {
  // Using useCheckAuth hook forces the token check to run silently before another request is made
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: isAuthLoading,
    user: auth0User,
  } = useAuth0();
  const [form, setForm] = useState<Form>(defaultForm);
  const { request: postAccountSetup, isLoading } = useCompleteAgentSetup();

  const {
    user: { setTokenMetadata, setUser, user },
  } = useStore();
  const [open, setOpen] = useState(false);

  // This useEffect manually sets the token metadata in the user store
  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) {
      getAccessTokenSilently().then(token => {
        setTokenMetadata(token, auth0User);
      });
    }
  }, [isAuthenticated]);

  // This useEffect makes sure the metadata has been set before determining if the
  // user's account is setup completely
  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) {
      if (user.agentId !== '') {
        if (!user.setupComplete) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    }
  }, [isAuthenticated, user]);

  const handleClose = (reason: CloseReason) => {
    if (reason && reason == 'closeButtonClick') {
      setOpen(false);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnSelectChange = (event: SelectChangeEvent) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = async () => {
    await postAccountSetup(form);
    getAccessTokenSilently().then(token => {
      setTokenMetadata(token, auth0User);
    });
    setForm(defaultForm);
    handleClose('closeButtonClick');
  };

  return (
    <Dialog fullWidth open={open} onClose={(_, reason) => handleClose(reason)}>
      <form>
        <DialogTitle>Account Setup</DialogTitle>
        <DialogContent>
          <Grid container>
            <Stack direction='column' spacing={4}>
              <Typography>
                Please enter the following information to complete your account setup.
              </Typography>
              <Stack direction='row' spacing={2}>
                <TextField
                  id='first-name'
                  name='first_name'
                  fullWidth
                  placeholder='First Name'
                  value={form.first_name}
                  onChange={handleOnChange}
                  required={true}
                  error={form.first_name === ''}
                  {...(form.first_name === '' && { helperText: 'First Name is Required.' })}
                />

                <TextField
                  id='last-name'
                  name='last_name'
                  fullWidth
                  placeholder='Last Name'
                  value={form.last_name}
                  onChange={handleOnChange}
                  required={true}
                  error={form.last_name === ''}
                  {...(form.last_name === '' && { helperText: 'Last Name is Required.' })}
                />
                <MuiTelInput
                  disableDropdown
                  defaultCountry='US'
                  onlyCountries={['US']}
                  id='poc-phone-number'
                  name='point_of_contact.phone'
                  aria-describedby='phone-number'
                  fullWidth
                  value={form.phone}
                  required={true}
                  onChange={value => {
                    if (!value.startsWith('+1')) {
                      setForm({
                        ...form,
                        ['phone']: '+1' + value,
                      });
                    } else {
                      setForm({
                        ...form,
                        ['phone']: value,
                      });
                    }
                  }}
                  error={!isValidPhoneNumber(form.phone) && form.phone !== '+1'}
                  {...(!isValidPhoneNumber(form.phone) && form.phone !== '+1'
                    ? { helperText: 'Invalid US Phone Number' }
                    : {})}
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <TextField
                  id='npn-number'
                  name='npn'
                  fullWidth
                  placeholder='NPN Number'
                  value={form.npn}
                  onChange={handleOnChange}
                  required={true}
                  error={form.npn.length !== 8}
                  {...(form.npn.length !== 8 && { helperText: 'NPN is Required.' })}
                />
                <FormControl fullWidth error={form.resident_license_state === ''}>
                  <InputLabel id='resident-license-state-label'>Resident License State</InputLabel>
                  <Select
                    id='resident-license-state'
                    label='Resident License State'
                    name='resident_license_state'
                    value={form.resident_license_state}
                    onChange={handleOnSelectChange}
                  >
                    {Object.values(USStateOrTerritory).map((state, index) => (
                      <MenuItem value={state} key={index}>
                        {USStateOrTerritoryName[state]}
                      </MenuItem>
                    ))}
                  </Select>
                  {form.resident_license_state === '' && (
                    <FormHelperText>License State is Required.</FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            spinnerColor='success'
            variant='contained'
            disabled={
              form.npn === '' ||
              form.npn.length !== 8 ||
              form.first_name === '' ||
              form.last_name === '' ||
              !isValidPhoneNumber(form.phone) ||
              form.resident_license_state === ''
            }
            onClick={() => {
              debounce(handleSave(), 500);
            }}
            type='submit'
            text='Save'
            isLoading={isLoading}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
