import { useOutletContext } from 'react-router-dom';
import { FinalRequestSubmittedViewOutletContext } from '..';
import { BoundView } from './BoundView';
import { FinalRequestIssueForm } from './Form';
import { PendingView } from './PendingView';

export const FinalRequestIssueView = () => {
  const { request, navigateToCurrentStep, refetchRequest } =
    useOutletContext<FinalRequestSubmittedViewOutletContext>();

  switch (request?.status) {
    case 'accepted':
      return (
        <FinalRequestIssueForm
          requestId={request.id}
          navigateToCurrentStep={navigateToCurrentStep}
          refetchRequest={refetchRequest}
        />
      );

    case 'pending':
      return <PendingView request={request} />;

    case 'bound':
      return <BoundView bondId={request.bond_id} />;
    default:
      return <></>;
  }
};
