import { useNavigate, useParams } from 'react-router-dom';
import { useGetFinalRequestDraft } from '../../services/finalRequestDraft';
import { FinalRequestFormView } from './FinalRequestForm';
import { useStore } from '../../store';

export const FinalRequestDraftView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    loader: { useLoadingScreen },
  } = useStore();

  if (id === undefined) {
    navigate('/requests');
    return <></>;
  }

  const { isLoading } = useGetFinalRequestDraft(id!);
  useLoadingScreen(isLoading);

  return isLoading ? null : <FinalRequestFormView draftId={id} />;
};
