import { useRequest } from './useRequest';

export type BondFormTemplate = {
  id: string;
  title?: string | null;
  contract_type?: string | null;
  obligee?: string | null;
  tags?: [string] | null;
};

export type BondFormTemplateResponse = [BondFormTemplate];

export const useGetBondFormTemplates = (isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/bonds/form-templates`
    : `/v1/surety/contract/bonds/form-templates`;
  return useRequest<BondFormTemplateResponse>(path, 'GET');
};
