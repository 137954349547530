import { createContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import FormNavBar from '../../components/FormNavBar';
import { RequestPayload, useGetRequest } from '../../services/request';
import { useIsFirstRender } from 'usehooks-ts';

export const RequestContext = createContext<RequestPayload | null>(null);

export type RequestSubmittedViewOutletContext = {
  request: RequestPayload;
  navigateToCurrentStep: () => void;
  refetchRequest: (...args: any) => Promise<void>;
};

export const RequestSubmittedView = () => {
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const navigate = useNavigate();
  const isFirstRender = useIsFirstRender();
  const { id } = useParams();
  const {
    response: request,
    refetch: refetchRequest,
    isLoading,
  } = useGetRequest(id!, { skipInitial: false }, isBidBond);

  const navigateToCurrentStep = () => {
    if (request !== null) {
      if (isBidBond) {
        if (['approved', 'review', 'declined', 'rejected'].includes(request.status)) {
          navigate(`/requests/contract/bid/${id}/terms`);
        } else {
          if (!isFirstRender || location.pathname.includes('issue')) {
            navigate(`/requests/contract/bid/${id}/issue`);
          }
        }
      } else {
        if (['approved', 'review', 'declined', 'rejected'].includes(request.status)) {
          navigate(`/requests/contract/${id}/terms`);
        } else {
          if (!isFirstRender || location.pathname.includes('issue')) {
            navigate(`/requests/contract/${id}/issue`);
          }
        }
      }
    }
  };

  useEffect(() => {
    navigateToCurrentStep();
  }, [request?.status]);

  return !isLoading && request !== null ? (
    <RequestContext.Provider value={request}>
      <FormNavBar />
      <Outlet context={{ request, navigateToCurrentStep, refetchRequest }} />
    </RequestContext.Provider>
  ) : (
    <Stack direction='row' justifyContent='center' sx={{ paddingY: '1rem' }}>
      <CircularProgress />
    </Stack>
  );
};
