import { NewFinalBondRequestDraftRequest } from './newFinalBondRequest';
import { useRequest, UseRequestOptions } from './useRequest';

export type FinalDraftRequestPayload = NewFinalBondRequestDraftRequest & {
  id: string;
  created_at: string;
  quote_id: string | undefined;
};

export const useGetFinalRequestDraft = (id: string, options?: UseRequestOptions) => {
  const path = `/v1/surety/contract/final/quotes/drafts/${id}`;
  return useRequest<FinalDraftRequestPayload>(path, 'GET', options);
};
