export type formatErrorMessageProps = {
  error?: string | null;
  message?: string | null;
};

export const formatErrorMessage = ({
  error,
  message = 'Request could not be received',
}: formatErrorMessageProps) => {
  return `${error}: ${message}`;
};
