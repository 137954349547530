import Container from '@mui/material/Container';
import List from '@mui/material/List';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useGetBondByIdRequests } from '../services/bondRequests';
import {
  Button,
  LinearProgress,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
  BondRequest,
  SuretyBondType,
  ContractSuretyBondType,
  BondPeriod,
  SuretyBondStatus,
  SuretyBondRenewalMethod,
} from '../shared/types/main';
import { SubNavigationBar } from '../components/SubNavigationBar';
import {
  capitalize,
  formatDateToMMDDYYYY,
  formatToDollar,
  formatToPercent,
} from '../shared/util/main';
import { useEffect } from 'react';
import { usePostFinalBondRequestDraft } from '../services/newFinalBondRequest';

/**
 * Returns relevant term for the bond
 * For status of open or unissued, find the term that has a effective data >= now
 * AND also has an expiration_date if it exists.
 * @param bondPeriods
 */
const getCurrentTerm = (bondPeriods: BondPeriod[]) => {
  if (DateTime.fromFormat(bondPeriods[0].effective_date, 'MM/dd/yyyy').diffNow().milliseconds >= 0)
    return bondPeriods[0];
  const currentTerm = bondPeriods.find(
    (term: BondPeriod) =>
      DateTime.fromFormat(term.effective_date, 'MM/dd/yyyy').diffNow().milliseconds < 0 &&
      (term.expiration_date === undefined ||
        DateTime.fromFormat(term.expiration_date, 'MM/dd/yyyy').diffNow().milliseconds > 0),
  );

  if (currentTerm === undefined) throw new Error('Unable to find current term.');

  return currentTerm;
};

/**
 * Maps data from bond to row elements
 * @param bond BondRequest
 * @returns [] of rows for the bond
 */
const getRows = (bond: BondRequest, currentTerm: BondPeriod, isBidBond: boolean) => {
  const rate: string =
    currentTerm.flat_rate !== undefined
      ? `${currentTerm.flat_rate}%`
      : currentTerm.sliding_scale_rate !== undefined
      ? currentTerm.sliding_scale_rate.map(term => formatToPercent(term.rate)).join(' / ')
      : '--';

  const final_bond_rate =
    bond.final_bond_contingent_flat_rate !== undefined
      ? `${(bond.final_bond_contingent_flat_rate * 100).toPrecision(2)}%`
      : bond.final_bond_contingent_sliding_scale_rate !== undefined
      ? bond.final_bond_contingent_sliding_scale_rate
          .map((rateTier: { rate: number; amount: number }) => (rateTier.rate * 100).toPrecision(2))
          .join(`%/`) + '%'
      : '--';

  const rows = [
    { name: 'Amount', value: formatToDollar(currentTerm.amount) },
    { name: 'Type', value: SuretyBondType[bond.type as keyof typeof SuretyBondType] },
    {
      name: isBidBond ? 'Final Bond Type' : 'Contract Type',
      value: isBidBond
        ? ContractSuretyBondType[bond.final_bond_type as keyof typeof ContractSuretyBondType]
        : ContractSuretyBondType[bond.contract_type as keyof typeof ContractSuretyBondType],
    },
    {
      name: isBidBond ? 'Bid Amount' : 'Contract Amount',
      value: isBidBond ? formatToDollar(bond.bid_amount) : formatToDollar(currentTerm.amount),
    },
    { name: 'Rate', value: rate }, // accomodate a sliding scale,
    { name: 'Premium', value: formatToDollar(currentTerm.premium) },
  ];

  if (!isBidBond) {
    rows.push(
      ...[
        { name: 'Effective Rate', value: formatToPercent(currentTerm.effective_rate) },
        { name: 'Agency Commission', value: formatToDollar(currentTerm.agency_commission) },
        { name: 'Renewable', value: bond.renewable ? 'Yes' : 'No' },
      ],
    );
  }

  if (isBidBond) {
    rows.push(
      ...[
        { name: 'Final Bond Rate', value: final_bond_rate },
        {
          name: 'Final Bond Premium',
          value: formatToDollar(bond.final_bond_contingent_premium),
        },
      ],
    );
  }

  rows.push(
    ...[
      { name: 'Effective Date', value: formatDateToMMDDYYYY(currentTerm.effective_date) },
      { name: 'Agent', value: bond.agent_email || '' },
    ],
  );

  if (!isBidBond) {
    rows.push(
      ...[
        {
          name: 'Expiration Date',
          value: currentTerm.expiration_date
            ? formatDateToMMDDYYYY(currentTerm.expiration_date)
            : '--',
        },
      ],
    );
  }

  if (bond.renewable === true) {
    rows.push(
      ...[
        {
          name: 'Renewal Method',
          value:
            bond.renewal_method !== undefined
              ? SuretyBondRenewalMethod[bond.renewal_method as keyof typeof SuretyBondRenewalMethod]
              : '--',
        },
        {
          name: 'Term periods remaining',
          value:
            bond.term_periods_remaining !== undefined
              ? bond.term_periods_remaining.toString()
              : '--',
        },
      ],
    );
  }
  return rows;
};

export const Bond = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isBidBond = location.pathname.includes('bid');
  const isFinalBond = location.pathname.includes('final');
  const {
    response: newDraftResponse,
    request: postNewFinalBondRequestDraft,
    error: newDraftError,
  } = usePostFinalBondRequestDraft();

  useEffect(() => {
    if (newDraftResponse === null) {
      return;
    }
    if (bond) {
      navigate(`/requests/contract/final/drafts/${newDraftResponse.id}?bid_bond_id=${bond.id}`);
    }
  }, [newDraftResponse]);

  useEffect(() => {
    if (newDraftError) {
      console.error(newDraftError);
    }
  }, [newDraftError]);

  if (id === undefined) return <div>Bond id is required</div>;
  const { response: bond, refetch, isLoading, error } = useGetBondByIdRequests(id, isBidBond);

  if (error) return <div>Error while retrieving bond</div>;
  if (bond === null || bond === undefined) return <span>No Bond found</span>;
  const linkColor = '#009AF1'; // TODO: move to theme
  const currentTerm =
    bond.status !== SuretyBondStatus.closed
      ? getCurrentTerm(bond.bond_periods)
      : bond.bond_periods[bond.bond_periods.length - 1];

  const bondRequestLink = isBidBond
    ? `/requests/contract/bid/${currentTerm.quote_id}/view`
    : isFinalBond
    ? `/requests/contract/final/${currentTerm.quote_id}/view`
    : `/requests/contract/${currentTerm.quote_id}/view`;

  const createFinalBondDraft = () => {
    postNewFinalBondRequestDraft({ bid_bond_id: bond.id });
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {!isLoading && (
        <>
          <SubNavigationBar title={`View Request`} link={bondRequestLink} />
          <div
            style={{
              flexGrow: 1,
              background: '#F8F8FB',
              height: '100vh',
              padding: '0 160px',
            }}
          >
            <Container sx={{ padding: '25px 0 0 0' }}>
              <Box
                sx={{
                  height: 120,
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  minWidth: '400px',
                  maxWidth: '600px',
                }}
              >
                <Typography variant='h4'>Bond {bond.number}</Typography>
                <span>
                  for{' '}
                  <Link style={{ color: linkColor }} to='/requests'>
                    {bond.principal_name}
                  </Link>
                </span>
                <span>Status: {capitalize(bond.status)}</span>
                {isBidBond && (
                  <Button variant='contained' color='info' onClick={() => createFinalBondDraft()}>
                    Issue Final Bond
                  </Button>
                )}
              </Box>
            </Container>
            <Container sx={{ margin: '0 auto', alignItems: 'center', minWidth: '400px' }}>
              <List
                dense
                disablePadding
                subheader={
                  <ListSubheader
                    component='div'
                    id='nested-list-subheader'
                    sx={{ backgroundColor: 'inherit', margin: '0 0 1rem' }}
                  >
                    {bond.renewable && <Typography variant='h6'>Bond {bond.number}</Typography>}
                  </ListSubheader>
                }
              >
                {getRows(bond, currentTerm, isBidBond).map(({ name, value }, i) => (
                  <ListItem
                    key={i}
                    style={{ backgroundColor: i % 2 !== 0 ? '#f8f8fb' : '#f1f1f1' }}
                  >
                    <ListItemText primary={name} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span
                        style={{ alignSelf: 'flex-end', fontWeight: 400, fontSize: '0.875rem' }}
                      >
                        {value}
                      </span>
                      {name === 'Renewable' && bond.bond_created_from_id && (
                        <div
                          style={{
                            fontSize: '0.6rem',
                            color: linkColor,
                            textDecoration: 'underline',
                          }}
                          onClick={() =>
                            isBidBond
                              ? refetch(
                                  `/v1/surety/contract/bid/bonds/${bond.bond_created_from_id}`,
                                )
                              : refetch(`/v1/surety/contract/bonds/${bond.bond_created_from_id}`)
                          }
                        >
                          VIEW PREVIOUS
                        </div>
                      )}
                    </div>
                  </ListItem>
                ))}
              </List>
            </Container>
          </div>
        </>
      )}
    </>
  );
};
