import { ListItem, Stack, Typography, Chip, Button } from '@mui/material';
import { ListChildComponentProps } from 'react-window';
import { BondFormContext } from '../../../components/machines/bondFormMachine';
import {
  BondFormTemplateType,
  BondFormTemplateTypeName,
} from '../../../constants/bondFormTemplateTypes';
import { useLocation } from 'react-router-dom';

type BondFormRowProps = ListChildComponentProps & {
  handleClose: () => void;
  onSelect: (obj: BondFormContext) => void;
};

export default function BondFormRow(props: BondFormRowProps) {
  const { data, index, style } = props;
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');

  const cardStyle = {
    ...style,
    display: 'block',
    backgroundColor: '#F8F8FB',
  };

  const bondFormTemplateType: BondFormTemplateType = data[index].contract_type;

  return (
    <ListItem key={index} style={cardStyle}>
      <Stack
        direction='row'
        spacing={0.5}
        p={2}
        alignItems='center'
        justifyContent={'space-between'}
      >
        <Stack direction='column' spacing={0.5} p={2}>
          <Typography variant='h6' color='primary'>
            {data[index].title}
          </Typography>
          <Typography variant='subtitle2'>
            <b>Type:</b> {isBidBond ? 'Bid' : BondFormTemplateTypeName[bondFormTemplateType]}
          </Typography>
          {data[index].obligee && (
            <Typography variant='subtitle2'>
              <b>Obligee:</b> {data[index].obligee}
            </Typography>
          )}
          <Stack direction='row' spacing={0.5}>
            {data[index].tags.map((tag: string, index: number) => (
              <Chip label={tag} key={index} size='small' variant='outlined' color='primary'></Chip>
            ))}
          </Stack>
        </Stack>
        <Button
          variant='contained'
          onClick={() => {
            props.onSelect({
              uploaded: false,
              id: data[index].id,
              type: isBidBond ? 'bid' : data[index].contract_type,
              title: data[index].title,
              obligee: data[index].obligee,
              tags: data[index].tags,
            } as BondFormContext);
            props.handleClose();
          }}
        >
          Select
        </Button>
      </Stack>
    </ListItem>
  );
}
