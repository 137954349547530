import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type SubNavigationBarProps = {
  title: string;
  link: string;
};
export const SubNavigationBar = ({ title, link }: SubNavigationBarProps) => {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(-1);
  const handleLinkClick = () => navigate(link, { replace: true });
  return (
    <Box sx={{ border: '1px grey', backgroundColor: '#f2f2f9', padding: `0 16px` }}>
      <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
        <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography component='div' style={{ cursor: 'pointer' }} onClick={handleLinkClick}>
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};
