import 'yup-phone';
import Yup from './custom';

export const FINAL_BOND_SCHEMA = Yup.object()
  .shape(
    {
      t_listing_required: Yup.boolean().nullable().optional(),
      bid_bond_id: Yup.string().required(),
      draft_id: Yup.string().required(),
      bond_form_template_id: Yup.string().when('bond_form_file_upload_id', {
        is: (val: string) => !val,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      bond_form_file_upload_id: Yup.string().when('bond_form_template_id', {
        is: (val: string) => !val,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      contract_amount: Yup.number().min(5000, 'Must be at least $5,000').required('Required'),
      second_bid: Yup.number().optional(),
      bond_amount: Yup.number()
        .min(5000, 'Must be at least $5,000')
        .max(
          Yup.ref('contract_amount'),
          'Bond amount must be less than or equal to contract amount',
        )
        .required('Required'),
      actual_bid_date: Yup.string().required('Required'),
      contract_date: Yup.string().required('Required'),
      point_of_contact: Yup.object({
        first_name: Yup.string().trim().required('Required'),
        last_name: Yup.string().trim().required('Required'),
        email: Yup.string().email('Not a valid email').trim().required('Required'),
        phone: Yup.string()
          .required('Required')
          .trim()
          .test('test-phone', 'Invalid US Phone Number.', (value: string | undefined) => {
            if (value) return Yup.string().phone('Invalid US phone number').isValidSync(value);
            return true;
          }),
      }).noUnknown(),
    },
    [
      ['bond_form_template_id', 'bond_form_file_upload_id'],
      ['bond_form_file_upload_id', 'bond_form_template_id'],
    ],
  )
  .noUnknown();
