import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import { useGetPrincipalPreviewsRequests } from '../../services/principalPreviewRequests';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../store';
import { formatToDollar } from '../../shared/util/main';

export const PrincipalPreview = () => {
  const {
    loader: { useLoadingScreen },
  } = useStore();

  const { response, isLoading } = useGetPrincipalPreviewsRequests();
  useLoadingScreen(isLoading);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      editable: false,
      width: 300,
      valueGetter: params => params.row.name || '',
    },
    {
      field: 'bond_count',
      headerName: 'Bonds',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params => (params.row.bond_count !== undefined ? params.row.bond_count : ''),
    },
    {
      field: 'aggregate_exposure',
      headerName: 'Exposure',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        params.row.aggregate_exposure !== undefined
          ? formatToDollar(params.row.aggregate_exposure)
          : '',
    },
    {
      field: 'premium',
      headerName: 'Premium',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        params.row.premium !== undefined ? formatToDollar(params.row.premium) : '',
    },
    // {
    //   field: 'rate',
    //   headerName: 'Rate',
    //   flex: 1,
    //   sortable: false,
    //   editable: false,
    //   valueGetter: params => params.row.single_limit !== undefined ? `${params.row.rate * 100}%` : '',
    // },
    {
      field: 'single_limit',
      headerName: 'Single Limit',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        params.row.single_limit !== undefined ? formatToDollar(params.row.single_limit) : '',
    },
    {
      field: 'aggregate_limit',
      headerName: 'Aggregate Limit',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        params.row.aggregate_limit !== undefined ? formatToDollar(params.row.aggregate_limit) : '',
    },
  ];
  return (
    <div style={{ flexGrow: 1, background: '#F8F8FB' }}>
      <Container maxWidth='xl' sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
        <Grid container sx={{ alignItems: 'center', paddingBottom: '5px' }}>
          <Grid>
            <div style={{ display: 'flex ', alignItems: 'center' }}>
              <h2 style={{ fontWeight: 'normal', marginRight: '25px' }}>Principals</h2>
            </div>
          </Grid>
          <Grid></Grid>
        </Grid>
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {(response === null || response === undefined) && !isLoading && (
          <div>No Principal found</div>
        )}
        {!isLoading && response !== null && (
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
              sx={{ background: 'white', marginBottom: '20px' }}
              rows={response.data}
              columns={columns}
              disableSelectionOnClick
            />
          </div>
        )}
      </Container>
    </div>
  );
};
