import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { RequestContext } from '../views/RequestSubmitted';
import { useLocation } from 'react-router-dom';
import { FinalRequestContext } from '../views/FinalRequestSubmitted';

const stepsToUrl: { [key: string]: string } = {
  Request: 'view',
  Terms: 'terms',
  Issue: 'issue',
};
const steps: string[] = Object.keys(stepsToUrl);

export default function FormProgressStepper() {
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const isFinalBond = location.pathname.includes('final');
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const request: any = isFinalBond ? useContext(FinalRequestContext) : useContext(RequestContext);
  const [allowTermsClick, setAllowTermsClick] = React.useState<boolean>(false);
  const [allowIssueClick, setAllowIssueClick] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (request) {
      if (request.status) {
        setAllowTermsClick(true);
        setAllowIssueClick(['accepted', 'pending', 'bound'].includes(request.status));
      } else {
        setAllowTermsClick(false);
        setAllowIssueClick(false);
      }
    }
  }, [request]);

  React.useEffect(() => {
    if (location.pathname.endsWith('terms')) {
      setActiveStep(1);
    } else if (location.pathname.endsWith('issue')) {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
  }, [location]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} nonLinear>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => {
                if ((index === 1 && !allowTermsClick) || (index === 2 && !allowIssueClick)) {
                  return;
                }
                isBidBond
                  ? navigate(`/requests/contract/bid/${request.id}/` + stepsToUrl[steps[index]])
                  : isFinalBond
                  ? navigate(`/requests/contract/final/${request.id}/` + stepsToUrl[steps[index]])
                  : navigate(`/requests/contract/${request.id}/` + stepsToUrl[steps[index]]);
                setActiveStep(index);
              }}
              disabled={(index === 1 && allowTermsClick) || (index === 2 && allowIssueClick)}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
