import {
  BondRequest,
  BondsPreviewResponse,
  BondRequestPreviewsResponse,
} from '../shared/types/main';
import { useRequest } from './useRequest';

export const useGetBondPreviewsRequests = () =>
  useRequest<BondRequestPreviewsResponse>('/v1/surety/quotes/previews', 'GET');

export const useGetBondRequests = () =>
  useRequest<BondsPreviewResponse>('/v1/surety/bonds/previews', 'GET');

export const useGetBondByIdRequests = (
  id: string,
  isBidBond: boolean,
): {
  response: BondRequest | null | undefined;
  refetch: (altPath: string) => Promise<void>;
  isLoading: boolean;
  error: Error | null;
} => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/bonds/${id}`
    : `/v1/surety/contract/bonds/${id}`;
  return useRequest<BondRequest>(path, 'GET');
};
