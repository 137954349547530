import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type Owner = Individual | Company | PrivateFund;

export type Individual = {
  id?: string;
  first_name: string;
  last_name: string;
  middle_initial: string | null;
  suffix: string | null;
  ssn?: string;
  address: string;
  city: string;
  state: string;
  zip?: string;
  email: string;
  phone?: string;
  married: boolean | undefined;
  spouse?: {
    first_name: string;
    last_name: string;
    middle_initial: string | null;
    suffix: string | null;
    email: string;
  } | null;
  owns_a_home: boolean;
  credit_report_permission: boolean;
  type: string;
};

export type PrivateFund = {
  id?: string;
  name: string;
  type: string;
};

export type Company = {
  id?: string;
  name: string;
  fein: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  owners: Owner[];
  type: string;
};

const addToParent = (owners: Owner[], parentCompany: Company | null, newOwner: Owner): Owner[] => {
  if (parentCompany) {
    // if we have a parent company, search for it in the current list of owners
    const parent = owners.find(own => own.id === parentCompany.id) as Company | undefined;

    // if we find the parent, generate a new array with the newOwner within it
    // for the parent company's owner property, and return the new owner array
    // with the parent element replaced. We have to make new arrays to ensure
    // that when we setOwnerState that react recognizes that changes have occurred.
    if (parent) {
      // copy the object to avoid mutating state
      const parentCopy = { ...parent };
      // new array with the previous parent owners and new owner appended
      parentCopy.owners = [...parentCopy.owners, newOwner];
      const parentIndex = owners.indexOf(parent);
      const ownersCopy = [...owners];
      ownersCopy.splice(parentIndex, 1, parentCopy);

      return ownersCopy;
    }

    // if we didn't find the parent company in the current owners list,
    // try and add it for each company in the current list.
    return owners.map(own => {
      if (own.type === 'company') {
        const company = own as Company;
        return {
          ...company,
          owners: addToParent(company.owners, parentCompany, newOwner),
        };
      }

      return own;
    });
  }

  // if we don't have a parent company, simply add it to the top level owner list.
  return [...owners, newOwner];
};

const removeFromAppropriateLocation = (owners: Owner[], ownerToRemove: Owner): Owner[] => {
  const ownerFromArray = owners.find(own => own.id === ownerToRemove.id);
  if (!ownerFromArray) {
    return owners.map(own => {
      if (own.type === 'company') {
        const company = own as Company;
        return {
          ...company,
          owners: removeFromAppropriateLocation(company.owners, ownerToRemove),
        };
      }

      return own;
    });
  }

  const newOwners = owners.filter(own => own.id !== ownerToRemove.id);
  return newOwners;
};

const editAtAppropriateLocation = (owners: Owner[], ownerToEdit: Owner): Owner[] => {
  const ownerFromArray = owners.find(own => own.id === ownerToEdit.id);
  if (!ownerFromArray) {
    return owners.map(own => {
      if (own.type === 'company') {
        const company = own as Company;
        return {
          ...company,
          owners: editAtAppropriateLocation(company.owners, ownerToEdit),
        };
      }

      return own;
    });
  }

  const ownerToEditIndex = owners.indexOf(ownerFromArray);
  const newOwners = [...owners];
  newOwners.splice(ownerToEditIndex, 1, ownerToEdit);
  return newOwners;
};

const getCompaniesForOwner = (company: Company): Company[] => {
  const companyChildren: Company[] = Array.from(company.owners).filter(
    owner => owner.type === 'company',
  ) as Company[];
  return [...companyChildren, ...companyChildren.flatMap(getCompaniesForOwner)];
};

const getChildOwners = (owner: Owner): Owner[] => {
  if (owner.type !== 'company') {
    return [];
  }
  const company = owner as Company;
  return [...company.owners, ...company.owners.flatMap(getChildOwners)];
};

export const addNestedIds = (owners: Owner[]): Owner[] => {
  const newOwners = owners.map(own => {
    const newOwner = { ...own };
    newOwner.id = uuidv4();

    if (newOwner.type === 'company') {
      const company = newOwner as Company;
      company.owners = addNestedIds(company.owners);
      return company;
    }

    return newOwner;
  });

  return newOwners;
};

export const removeNestedIds = (owners: Owner[]): Owner[] => {
  const newOwners = owners.map(own => {
    const newOwner = { ...own };
    delete newOwner.id;

    if (newOwner.type === 'company') {
      const company = newOwner as Company;
      company.owners = removeNestedIds(company.owners);
      return company;
    }

    return newOwner;
  });

  return newOwners;
};

export const useOwners = () => {
  const [owners, setOwners] = useState<Owner[]>([]);

  const addOwner = (owner: Owner, parentCompany: Company | null) => {
    owner.id = uuidv4();

    const newOwners = addToParent(owners, parentCompany, owner as Owner);
    setOwners(newOwners);
  };

  const editOwner = (owner: Owner) => {
    const newOwners = editAtAppropriateLocation(owners, owner);
    setOwners(newOwners);
  };

  const removeOwner = (owner: Owner) => {
    const newOwners = removeFromAppropriateLocation(owners, owner);
    setOwners(newOwners);
  };

  const getFlatCompanies = (): Company[] => {
    let allCompanyOwners: Company[] = [];
    owners.forEach(owner => {
      if (owner.type === 'company') {
        const childCompanies = getCompaniesForOwner(owner as Company);
        allCompanyOwners = [...allCompanyOwners, owner as Company, ...childCompanies];
      }
    });

    return allCompanyOwners;
  };

  const getFlatOwners = (): Owner[] => {
    const allOwners = [...owners, ...owners.flatMap(owner => getChildOwners(owner))] as Owner[];
    return allOwners;
  };

  return { owners, addOwner, setOwners, editOwner, removeOwner, getFlatCompanies, getFlatOwners };
};
