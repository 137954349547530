import { useOutletContext } from 'react-router-dom';
import { RequestSubmittedViewOutletContext } from '..';
import { BoundView } from './BoundView';
import { RequestIssueForm } from './Form';
import { PendingView } from './PendingView';

export const RequestIssueView = () => {
  const { request, navigateToCurrentStep, refetchRequest } =
    useOutletContext<RequestSubmittedViewOutletContext>();

  switch (request?.status) {
    case 'accepted':
      return (
        <RequestIssueForm
          requestId={request.id}
          principalName={request.principal.name}
          navigateToCurrentStep={navigateToCurrentStep}
          refetchRequest={refetchRequest}
        />
      );
    case 'pending':
      return <PendingView request={request} />;

    case 'bound':
      return <BoundView bondId={request.bond_id} />;

    default:
      return <></>;
  }
};
