import { MenuItem } from '@mui/material';
import { NameSuffix } from './nameSuffix';
import { USStateOrTerritory, USStateOrTerritoryName } from './usStates';

export const stateMenuItems = Object.values(USStateOrTerritory).map((state, index) => (
  <MenuItem value={state} key={index}>
    {USStateOrTerritoryName[state]}
  </MenuItem>
));

export const nameSuffixMenuItems = Object.values(NameSuffix).map((suffix, index) => (
  <MenuItem value={suffix} key={index}>
    {suffix}
  </MenuItem>
));
