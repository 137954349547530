import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormHelperText } from '@mui/material';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import Business from '@mui/icons-material/Business';
import Person from '@mui/icons-material/Person';
import Paid from '@mui/icons-material/Paid';

import { Delete } from '@mui/icons-material';

import {
  Owner,
  PrivateFund,
  Individual,
  Company,
  useOwners,
  addNestedIds,
} from '../../../../services/useOwners';
import { ownerTypes } from '../../../../constants/ownerTypes';
import { Formik, FormikProps } from 'formik';
import {
  PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA,
  PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA,
  PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA,
} from '../../../../validations/bondFormPrincipalOwnerValidation';

import { IndividualForm } from './IndividualForm';
import { CompanyForm } from './CompanyForm';
import { PrivateEquityForm } from './PrivateEquityForm';
import Yup from '../../../../validations/custom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: 500,
  overflowY: 'scroll',
  overflowX: 'hidden',
  p: 4,
};

type FormErrorHelperTextProps = {
  message: string;
};
const FormErrorHelperText = (props: FormErrorHelperTextProps) => (
  <FormHelperText sx={{ color: '#ff1744' }}>{props.message}</FormHelperText>
);

type PrincipalOwnerModalProps = {
  onOwnersChange: (owners: Owner[]) => void;
  readOnly: boolean;
  apiOwners?: Owner[];
};

export default function PrincipalOwnerModal(props: PrincipalOwnerModalProps) {
  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  const { owners, addOwner, editOwner, removeOwner, getFlatCompanies, setOwners, getFlatOwners } =
    useOwners();
  const { onOwnersChange, apiOwners } = props;

  const [open, setOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [errors, setErrors] = React.useState<Record<string, any>>([]);

  useEffect(() => {
    onOwnersChange(owners);
    const flatOwners = getFlatOwners();
    const erroringOwners = flatOwners
      .map(owner => {
        try {
          switch (owner.type) {
            case 'individual':
              PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA.validateSync(owner);
              break;
            case 'company':
              PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA.validateSync(owner);
              break;
            case 'privateFund':
              PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA.validateSync(owner);
              break;
          }
        } catch (err: any) {
          return { ...err, id: owner.id };
        }
        return null;
      })
      .filter(el => el !== null);

    setErrors(erroringOwners);
  }, [owners]);

  React.useEffect(() => {
    if (apiOwners !== undefined && apiOwners !== null) {
      setOwners(addNestedIds(apiOwners));
    }
  }, [apiOwners]);

  const handleOpen = (parentCompany: Company | null) => {
    setParentCompany(parentCompany);
    setOpen(true);
  };

  const handleClose = () => {
    setParentCompany(null);
    setIsEditing(false);
    clearState();
    setOpen(false);
  };

  const [ownerType, setOwnerType] = React.useState<string>('');
  const [parentCompany, setParentCompany] = React.useState<Company | null>(null);

  const [ownerId, setOwnerId] = React.useState<string | null>(null);

  const [individual, setIndividual] = React.useState<Individual | null>(null);
  const [company, setCompany] = React.useState<Company | null>(null);
  const [privateFund, setPrivateFund] = React.useState<PrivateFund | null>(null);

  const clearState = () => {
    setOwnerType('');
    setIndividual(null);
    setCompany(null);
    setPrivateFund(null);
  };

  const ownerTypeDescriptionsMenuItems = Object.entries(ownerTypes).map(([type, description]) => (
    <MenuItem value={type} key={type}>
      {description}
    </MenuItem>
  ));

  const renderOwnersList = (owners: Owner[], onEdit: (owner: Owner) => () => void) => {
    const ownersList: JSX.Element[] | null | undefined = [];
    owners.forEach(owner => {
      const error = errors.find((err: Record<string, any>) => {
        return err.id === owner.id;
      });

      if (owner.type === 'company') {
        owner = owner as Company;
        ownersList.push(
          <ListItem
            key={owner.id}
            secondaryAction={
              !props.readOnly && (
                <IconButton edge='end' aria-label='delete' onClick={onRemoveOwner(owner)}>
                  <Delete />
                </IconButton>
              )
            }
          >
            <ListItemButton
              component='a'
              href='#simple-list'
              key={owner.id}
              onClick={onEdit(owner)}
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary={owner.name} />
            </ListItemButton>
            {!props.readOnly && error && <FormErrorHelperText message={error.message} />}
          </ListItem>,
        );
      } else if (owner.type === 'individual') {
        owner = owner as Individual;
        ownersList.push(
          <ListItem
            key={owner.id}
            secondaryAction={
              !props.readOnly && (
                <IconButton edge='end' aria-label='delete' onClick={onRemoveOwner(owner)}>
                  <Delete />
                </IconButton>
              )
            }
          >
            <ListItemButton
              component='a'
              href='#simple-list'
              key={owner.id}
              onClick={onEdit(owner)}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary={
                  owner.first_name +
                  ' ' +
                  (owner.middle_initial ? `${owner.middle_initial} ` : '') +
                  owner.last_name +
                  ' ' +
                  (owner.suffix ?? '')
                }
              />
            </ListItemButton>
            {!props.readOnly && error && <FormErrorHelperText message={error.message} />}
          </ListItem>,
        );
      } else {
        owner = owner as PrivateFund;
        ownersList.push(
          <ListItem
            key={owner.id}
            secondaryAction={
              !props.readOnly && (
                <IconButton edge='end' aria-label='delete' onClick={onRemoveOwner(owner)}>
                  <Delete />
                </IconButton>
              )
            }
          >
            <ListItemButton
              component='a'
              href='#{simple-list}'
              key={owner.id}
              onClick={onEdit(owner)}
            >
              <ListItemIcon>
                <Paid />
              </ListItemIcon>
              <ListItemText primary={owner.name} />
            </ListItemButton>
            {!props.readOnly && error && <FormErrorHelperText message={error.message} />}
          </ListItem>,
        );
      }
    });

    return <List>{ownersList}</List>;
  };

  const renderCompanyOwnersList = (owners: Company[], onEdit: (owner: Owner) => () => void) => {
    let companySection: JSX.Element[] = [];
    owners.forEach(company => {
      const childCompanyRender = renderOwnersList(company.owners, onEdit);
      companySection.push(<br />);
      companySection.push(<Typography variant='h6'>OWNERS OF {company.name}</Typography>);
      companySection = companySection.concat(childCompanyRender);
      companySection.push(
        <Button
          variant='contained'
          size='medium'
          sx={{ marginBottom: '10px' }}
          onClick={() => handleOpen(company)}
        >
          Add Owner
        </Button>,
      );
    });
    return <div>{companySection}</div>;
  };

  const flatCompanyOwners = getFlatCompanies();

  const onRemoveOwner = (owner: Owner) => () => {
    removeOwner(owner);
  };

  const onEditOwner = (owner: Owner) => {
    return () => {
      setIsEditing(true);
      setOwnerId(owner.id as string);
      setOwnerType(owner.type);
      // this is hacky, but... we can't declare variables in case blocks because
      // that can introduce inconsistent behavior in Javascript due to improper
      // handling of variable scopes at the language level. We will conditionally
      // use these type-cast versions based on which owner.type in the below switch
      // statement, so we should be covered from a typesafety perspective. Do note
      // that only one of these is valid in a given function call.
      const _individual = owner as Individual;
      const _company = owner as Company;
      const _privateFund = owner as PrivateFund;
      switch (owner.type) {
        case 'individual':
          setIndividual(_individual);
          break;
        case 'company':
          setCompany(_company);
          break;
        case 'fund':
          setPrivateFund(_privateFund);
          break;
      }
      handleOpen(null);
    };
  };

  const onSaveOwner = (owner: Owner) => {
    if (!isEditing) {
      addOwner(owner, parentCompany);
    } else {
      const ownerToSave = { ...owner, id: ownerId };
      editOwner(ownerToSave as Owner);
    }
    handleClose();
  };

  const INITIAL_FORM_INDIVIDUAL_VALUES = {
    type: 'individual',
    first_name: '',
    middle_initial: '',
    last_name: '',
    suffix: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    owns_a_home: null,
    credit_report_permission: false,
    ssn: '',
    married: undefined,
    spouse: undefined,
  };

  const INITIAL_FORM_COMPANY_VALUES = {
    type: 'company',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    fein: '',
  };

  const INITIAL_FORM_PEF_VALUES = {
    type: 'fund',
    name: '',
  };

  return (
    <div>
      {props.readOnly ? (
        <p>Owners that own at least 20% of the principal.</p>
      ) : (
        <p>Only add owners that own at least 20% of the principal.</p>
      )}
      {!owners.length && <span style={{ color: 'gray' }}>None</span>}
      {owners && renderOwnersList(owners, onEditOwner)}
      {!props.readOnly && (
        <Button
          variant='contained'
          sx={{ marginBottom: '10px' }}
          size='medium'
          onClick={() => handleOpen(null)}
        >
          Add Owner
        </Button>
      )}
      {owners && renderCompanyOwnersList(flatCompanyOwners, onEditOwner)}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h5'>Add Principal Owner</Typography>
            <Stack direction='column' spacing={2}>
              <FormControl fullWidth style={{ marginTop: '16px' }}>
                <InputLabel id='type-select-label'>Owner Type</InputLabel>
                <Select
                  id='type'
                  name='type'
                  label='Owner Type'
                  inputProps={readOnlyInputProps}
                  aria-describedby='type-select'
                  placeholder='Select One'
                  value={ownerType}
                  onChange={event => {
                    setOwnerType(typeof event.target.value === 'string' ? event.target.value : '');
                  }}
                >
                  {ownerTypeDescriptionsMenuItems}
                </Select>
              </FormControl>
              {ownerType === 'individual' ? (
                <Formik
                  initialValues={
                    individual ? { ...individual } : { ...INITIAL_FORM_INDIVIDUAL_VALUES }
                  }
                  validationSchema={PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA}
                  // need this function because formik requires submit handler even though we're just using it for state management
                  onSubmit={() => {
                    console.log();
                  }}
                >
                  {(
                    formikProps:
                      | FormikProps<
                          Yup.InferType<typeof PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA>
                        >
                      | any,
                  ): JSX.Element => (
                    <IndividualForm
                      {...formikProps}
                      readOnly={props.readOnly}
                      onSaveClick={(individual: Individual) => {
                        if (individual !== null) {
                          onSaveOwner(individual);
                        }
                      }}
                      onCancelClick={() => {
                        handleClose();
                      }}
                    />
                  )}
                </Formik>
              ) : (
                <></>
              )}
              {ownerType === 'company' ? (
                <Formik
                  initialValues={
                    (company ? { ...company } : { ...INITIAL_FORM_COMPANY_VALUES }) as any
                  }
                  validationSchema={PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA}
                  // need this function because formik requires submit handler even though we're just using it for state management
                  onSubmit={() => {
                    console.log();
                  }}
                >
                  {(
                    formikProps: FormikProps<
                      Yup.InferType<typeof PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA>
                    >,
                  ): JSX.Element => (
                    <CompanyForm
                      {...formikProps}
                      readOnly={props.readOnly}
                      previousOwners={(company && company.owners) || []}
                      onSaveClick={(co: Company) => {
                        if (co !== null) {
                          onSaveOwner(co);
                        }
                      }}
                      onCancelClick={() => {
                        handleClose();
                      }}
                    />
                  )}
                </Formik>
              ) : (
                <></>
              )}
              {ownerType === 'fund' ? (
                <Formik
                  validationSchema={PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA}
                  initialValues={
                    privateFund ? { ...privateFund } : ({ ...INITIAL_FORM_PEF_VALUES } as any)
                  }
                  // need this function because formik requires submit handler even though we're just using it for state management
                  onSubmit={() => {
                    console.log();
                  }}
                >
                  {(
                    formikProps: FormikProps<
                      Yup.InferType<typeof PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA>
                    >,
                  ): JSX.Element => (
                    <PrivateEquityForm
                      {...formikProps}
                      readOnly={props.readOnly}
                      onSaveClick={privateFund => {
                        onSaveOwner(privateFund);
                      }}
                      onCancelClick={() => {
                        handleClose();
                      }}
                    />
                  )}
                </Formik>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </div>
  );
}
