import { USStateOrTerritory } from '../constants/usStates';
import { naicsCodes } from '../constants/naicsCodes';
import { financialStatementTypes } from '../constants/financialStatementTypes';
import 'yup-phone';
import { BondFormTemplateType } from '../constants/bondFormTemplateTypes';
import Yup from './custom';

export const BASE_SURETY_BOND_REQUEST_VALIDATION_SCHEMA = Yup.object()
  .shape(
    {
      t_listing_required: Yup.boolean().required(),
      bond_form_template_id: Yup.string().when('bond_form_file_upload_id', {
        is: (val: string) => !val,
        then: Yup.string().required('Please select or upload a bond form'),
        otherwise: Yup.string(),
      }),
      bond_form_file_upload_id: Yup.string().when('bond_form_template_id', {
        is: (val: string) => !val,
        then: Yup.string().required('Please select or upload a bond form'),
        otherwise: Yup.string(),
      }),
      point_of_contact: Yup.object({
        first_name: Yup.string().trim().required('Required'),
        last_name: Yup.string().trim().required('Required'),
        email: Yup.string().email('Not a valid email').trim().required('Required'),
        phone: Yup.string()
          .required('Required')
          .trim()
          .test('test-phone', 'Invalid US phone number', (value: string | undefined) => {
            if (value) return Yup.string().phone('Invalid US phone number').isValidSync(value);
            return true;
          }),
      }).noUnknown(),
      project: Yup.object({
        address: Yup.string().trim().required('Required'),
        city: Yup.string().trim().required('Required'),
        state: Yup.string()
          .uppercase()
          .oneOf(Object.values(USStateOrTerritory))
          .required('Required'),
        zip: Yup.string().trim().postalCode('Invalid zip').required('Required'),
        scope_of_work: Yup.string().oneOf(naicsCodes).required('Required'),
        months_to_complete: Yup.number().required('Required').min(1, 'Must be at least 1'),
        contract_warranty_months: Yup.number().required('Required').min(1, 'Must be at least 1'),
        completion_bond: Yup.boolean().required('Required'),
        contract_hazards: Yup.array().optional(),
        contract_damages: Yup.array().optional(),
      }).noUnknown(),
      principal: Yup.object({
        name: Yup.string().trim().required('Required'),
        address: Yup.string().trim().required('Required'),
        city: Yup.string().trim().required('Required'),
        state: Yup.string()
          .uppercase()
          .oneOf(Object.values(USStateOrTerritory))
          .required('Required'),
        zip: Yup.string().trim().postalCode('Invalid zip').required('Required'),
        naics_code: Yup.string().oneOf(naicsCodes).required('Required'),
        fein: Yup.string().ein('Invalid FEIN').required('Required'),
        owners: Yup.array().required(),
      }).noUnknown(),
    },
    [
      ['bond_form_template_id', 'bond_form_file_upload_id'],
      ['bond_form_file_upload_id', 'bond_form_template_id'],
    ],
  )
  .noUnknown();

export const CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA = Yup.object({
  bond_amount: Yup.number()
    .min(5000, 'Must be at least $5,000')
    .max(Yup.ref('contract_amount'), 'Bond amount cannot be more than contract amount')
    .required('Required'),
  contract_amount: Yup.number().min(5000, 'Must be at least $5,000').required('Required'),
}).noUnknown();

export const BID_SURETY_REQUEST_VALIDATION_SCHEMA = Yup.object({
  bond_amount: Yup.number().min(0, 'Only positive values are allowed').required('Required'),
  bid_amount: Yup.number().min(0, 'Only positive values are allowed').required('Required'),
  final_bond_type: Yup.string()
    .oneOf(
      Object.keys(BondFormTemplateType).map(function (type) {
        return type;
      }),
    )
    .required('Required'),
  bid_date: Yup.string().presentOrFutureDate().required('Required'),
}).noUnknown();

export const SMALL_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    failed_to_complete_a_construction_job: Yup.boolean()
      .required('Required')
      .defined('Required')
      .nullable(),
    largest_construction_project_completed: Yup.number()
      .required('Required')
      .min(0, 'Only positive values are allowed'),
    lost_a_payment_suit: Yup.boolean().nullable().defined('Required').required('Required'),
    caused_surety_loss: Yup.boolean().nullable().defined('Required').required('Required'),
    profitable_ytd: Yup.boolean().nullable().defined('Required').required('Required'),
    profitable_last_fiscal_year: Yup.boolean().nullable().defined('Required').required('Required'),
  }).noUnknown(),
}).noUnknown();

export const MEDIUM_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    construction_projects_gross_loss_gt_10_percent_last_36_months: Yup.boolean()
      .nullable()
      .required('Required'),
    current_construction_project_expected_gross_loss: Yup.boolean().nullable().required('Required'),
    construction_project_backlog_cost: Yup.number()
      .required('Required')
      .min(0, 'Only positive values are allowed'),
    last_fiscal_year_end_statement: Yup.object({
      preparation_method: Yup.string()
        .oneOf(
          financialStatementTypes.map(function (type) {
            return type.value;
          }),
        )
        .required('Required'),
      date: Yup.string().pastOrPresentDate().nullable().required('Required'),
      corporate_cash: Yup.number().required('Required').min(1, 'Please specify an amount'),
      current_assets: Yup.number().required('Required').min(1, 'Please specify an amount'),
      current_liabilities: Yup.number().required('Required').min(1, 'Please specify an amount'),
      total_assets: Yup.number().required('Required').min(1, 'Please specify an amount'),
      total_liabilities: Yup.number().required('Required').min(1, 'Please specify an amount'),
      revenue: Yup.number().required('Required').min(1, 'Please specify an amount'),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown();

export const LARGE_CONTRACT_SURETY_REQUEST_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    construction_project_backlog_gross_profit: Yup.number().required('Required'),
    bloc_size: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
    profitable_year_prior_to_last_fiscal_year: Yup.boolean()
      .nullable()
      .defined('Required')
      .required('Required'),
    last_fiscal_year_end_statement: Yup.object({
      accounts_payable: Yup.number()
        .required('Required')
        .defined()
        .min(0, 'Only positive values are allowed'),
      accounts_receivable: Yup.number()
        .required('Required')
        .min(0, 'Only positive values are allowed'),
      underbillings: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
      credit_cards_payable: Yup.number()
        .required('Required')
        .min(0, 'Only positive values are allowed'),
      bank_line_payable: Yup.number()
        .required('Required')
        .min(0, 'Only positive values are allowed'),
      accrued_expense: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
      overbillings: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
      current_portion_of_ltd: Yup.number()
        .required('Required')
        .min(0, 'Only positive values are allowed'),
      term_loan_debt: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
      ga_expense: Yup.number().required('Required').min(0, 'Only positive values are allowed'),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown();
