import * as R from 'ramda';

export const getValueOrInitial: any = (props: any, key: string) => {
  const { values, initialValues } = props;
  const path = key.split('.');

  if (
    !values ||
    (R.path(path, values) === undefined && R.path(path, initialValues) === undefined)
  ) {
    console.warn('no value found at key ' + key + ' for values', values);
    return '';
  }

  if (R.path(path, values) === null || R.path(path, values) === undefined) {
    return R.path(path, initialValues);
  } else {
    return R.path(path, values);
  }
};
