import { useOutletContext } from 'react-router-dom';
import { FinalRequestSubmittedViewOutletContext } from '..';
import { ApprovedView } from './ApprovedView';
import { DeclinedView } from './DeclinedView';
import { RejectedView } from './RejectedView';
import { ReviewView } from './ReviewView';

export const FinalRequestTermsView = () => {
  const { request } = useOutletContext<FinalRequestSubmittedViewOutletContext>();

  switch (request?.status) {
    case 'approved':
      return <ApprovedView />;

    case 'accepted':
      return <ApprovedView />;

    case 'pending':
      return <ApprovedView />;

    case 'bound':
      return <ApprovedView />;

    case 'review':
      return <ReviewView />;

    case 'declined':
      return <DeclinedView />;

    case 'rejected':
      return <RejectedView />;

    default:
      return <></>;
  }
};
