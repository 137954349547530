import { Grid, Stack, Typography } from '@mui/material';
import { FinalRequestPayload } from '../../../services/finalRequest';

export const PendingView = (props: { request: FinalRequestPayload }) => (
  <Grid container spacing={4} direction='column' justifyContent='center' alignItems='center' p={4}>
    <Stack
      justifyContent='center'
      alignItems='center'
      style={{ padding: '6rem' }}
      direction='column'
      spacing={2}
    >
      <Typography variant='h5'>Pending</Typography>
      <Typography variant='h5'>You’re almost there!</Typography>
      {props.request.pending_bond_form_review && (
        <Typography variant='body2'>
          We haven&apos;t seen this bond form yet, so we&apos;re reviewing it to make sure it
          conforms with this application.
        </Typography>
      )}
      {props.request.pending_indemnity_agreement && (
        <Typography variant='body2'>
          We’re waiting for all of the indemnitors to sign t he indemnity agreement.
        </Typography>
      )}
      <Typography variant='caption'>
        If you have any questions, feel free to reach out to us at:{' '}
        <a href='bonds@getinrev.com'>bonds@getinrev.com</a>
      </Typography>
    </Stack>
  </Grid>
);
