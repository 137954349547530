import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { StatusCell } from '../../components/StatusCell';
import { BondRequestPreview } from '../../shared/types/main';
import { capitalize } from '../../shared/util/main';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import grey from '@mui/material/colors/grey';
import { useRequest } from '../../services/useRequest';
import React, { Dispatch, SetStateAction } from 'react';
import { LoadingButton } from '../../components/LoadingButton';

type RequestsTableProps = {
  requests: BondRequestPreview[];
  onRequestClick?: (id: string, type: string) => void;
};

export const RequestsTable = ({ requests, onRequestClick }: RequestsTableProps) => {
  const styles = {
    background: 'white',
    marginBottom: '20px',
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
      {
        outline: 'none',
      },
    '*.MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  };

  const [requestList, setRequestList] = React.useState(requests);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const RenderStatusCell = (
    params: GridRenderCellParams<string>,
    setRequestList: Dispatch<SetStateAction<BondRequestPreview[]>>,
    requestList: BondRequestPreview[],
  ) => {
    const [draftToBeDeleted, setDraftToBeDeleted] = React.useState<any>(null);
    const {
      refetch: refetchDeleteDraft,
      isLoading: isDeleting,
      error: errorDeleting,
    } = useRequest('', 'DELETE', { skipInitial: true });

    function deleteDraft(id: string, type: string) {
      const path = type.includes('bid')
        ? `/v1/surety/contract/bid/quotes/drafts/${id}`
        : type.includes('final')
        ? `/v1/surety/contract/final/quotes/drafts/${id}`
        : `/v1/surety/contract/quotes/drafts/${id}`;
      refetchDeleteDraft(path);
    }

    React.useEffect(() => {
      if (draftToBeDeleted !== null) {
        deleteDraft(draftToBeDeleted.id, draftToBeDeleted.type);
        setRequestList(requestList.filter(request => request.id !== draftToBeDeleted.id));
      }
    }, [draftToBeDeleted]);

    React.useEffect(() => {
      if (errorDeleting) {
        setRequestList(requestList.concat(draftToBeDeleted));
        setDraftToBeDeleted(null);
      }
    }, [errorDeleting]);

    return (
      <Stack
        width='100%'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        p={1}
      >
        <StatusCell status={params.row.status} />
        {params.row.status === 'draft' && (
          <LoadingButton
            sx={{ color: grey[500] }}
            spinnerColor='success'
            onClick={e => {
              e.stopPropagation();
              setDraftToBeDeleted(params.row);
            }}
            icon={<DeleteIcon />}
            isLoading={isDeleting}
            text={''}
          />
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'principal_name',
      headerName: 'Principal',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params => params.row.principal_name || '',
    },
    {
      field: 'submitted_at',
      headerName: 'Submitted',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        (params.row.submitted_at &&
          DateTime.fromISO(params.row.submitted_at).toFormat('MM/dd/yyyy')) ||
        '',
    },
    {
      field: 'bond_amount',
      headerName: 'Bond Amount',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params =>
        (params.row.bond_amount && currencyFormatter.format(params.row.bond_amount)) || '',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params => params.row.description || '',
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: params => {
        if (params.row.type === 'bid_to_final') {
          return 'Final Contract';
        } else {
          return capitalize(params.row.type) || '';
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) =>
        RenderStatusCell(params, setRequestList, requestList),
    },
  ];

  return (
    <DataGrid
      sx={styles}
      rows={requestList}
      columns={columns}
      disableSelectionOnClick
      disableColumnMenu
      onRowClick={params => {
        onRequestClick && onRequestClick(params.id as string, params.row.type as string);
      }}
    />
  );
};
