import { useRequest } from './useRequest';

export const useGetQuoteModel = (principalId = '', amount = '', isBidBond = false) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/model?principal_id=${principalId}&bid_amount=${amount}`
    : `/v1/surety/contract/quotes/model?principal_id=${principalId}&bond_amount=${amount}`;

  return useRequest<string>(path, 'GET', {
    skipInitial: true,
  });
};
