import { useMutate, useRequest } from './useRequest';

export type RequestPDFPayload = {
  base64: 'string';
  file_name: 'string';
  contract_type?: 'string';
};

export type BidPDFPayload = {
  base64: 'string';
  file_name: 'string';
};

export type PDFPayload = RequestPDFPayload | BidPDFPayload;

export type PDFDetails = {
  contract_type?: 'string';
  id: 'string';
};

const bondFormUploadPathMap = {
  contract: (id: string) => `/v1/surety/contract/quotes/drafts/${id}/bond-form`,
  bid: (id: string) => `/v1/surety/contract/bid/quotes/drafts/${id}/bond-form`,
  final: (id: string) => `/v1/surety/contract/final/quotes/drafts/${id}/bond-form`,
};

export const usePostNewBondFormUpload = (
  quoteDraftId: string,
  type: 'contract' | 'bid' | 'final',
) => {
  return useMutate<PDFDetails, PDFPayload>(bondFormUploadPathMap[type](quoteDraftId), 'POST');
};

const bondFormUploadDetailsPathMap = {
  contract: (id: string) => `/v1/surety/contract/quotes/drafts/${id}/bond-form-details`,
  bid: (id: string) => `/v1/surety/contract/bid/quotes/drafts/${id}/bond-form-details`,
  final: (id: string) => `/v1/surety/contract/final/quotes/drafts/${id}/bond-form-details`,
};

export const useGetNewBondFormUploadDetails = (
  quoteDraftId: string,
  type: 'contract' | 'bid' | 'final',
) => {
  return useRequest<PDFDetails>(bondFormUploadDetailsPathMap[type](quoteDraftId), 'GET', {
    skipInitial: true,
  });
};

const bondFormUploadedUrlPathMap = {
  contract: (id: string) => `/v1/surety/contract/quotes/drafts/${id}/bond-form-url`,
  bid: (id: string) => `/v1/surety/contract/bid/quotes/drafts/${id}/bond-form-url`,
  final: (id: string) => `/v1/surety/contract/final/quotes/drafts/${id}/bond-form-url`,
};

export const useGetNewBondFormUploadedURL = (
  quoteDraftId: string,
  type: 'contract' | 'bid' | 'final',
) => {
  return useRequest<string>(bondFormUploadedUrlPathMap[type](quoteDraftId), 'GET', {
    skipInitial: true,
  });
};

const bondFormTemplatePreviewUrlPathMap = {
  contract: (id: string) => `/v1/surety/contract/bonds/form-templates/${id}/previews`,
  bid: (id: string) => `/v1/surety/contract/bid/bonds/form-templates/${id}/previews`,
};

export const useGetNewBondFormTemplatePreviewURL = (
  templateId: string,
  type: 'contract' | 'bid',
) => {
  return useRequest<string>(bondFormTemplatePreviewUrlPathMap[type](templateId), 'GET', {
    skipInitial: true,
  });
};
