import { createContext, ReactNode, useContext } from 'react';

import { useLoaderStore, LoaderStoreContextType } from './loader';
import { useUserStore, UserStoreContextType } from './user';

export type AppContextType = {
  loader: LoaderStoreContextType;
  user: UserStoreContextType;
};

type StorePropTypes = {
  children?: ReactNode;
};

export const StoreContext = createContext<AppContextType | null>(null);

export const StoreProvider = ({ children }: StorePropTypes) => {
  const store: AppContextType = {
    loader: useLoaderStore(),
    user: useUserStore(),
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext) as AppContextType;
