import { useSnackbar, SharedProps, OptionsObject } from 'notistack';

type ExtendedProps = SharedProps & OptionsObject;

type openSnackbarParams = {
  message?: string;
} & ExtendedProps;

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const createSnackbar = ({ message, variant }: openSnackbarParams) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  return {
    closeAll: closeSnackbar,
    createSnackbar,
  };
};
