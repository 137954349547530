import { naicsCodes } from '../constants/naicsCodes';
import 'yup-phone';
import { financialStatementTypes } from '../constants/financialStatementTypes';
import { USStateOrTerritory } from '../constants/usStates';
import { BondFormTemplateType } from '../constants/bondFormTemplateTypes';
import Yup from './custom';

export const BASE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  t_listing_required: Yup.boolean().nullable().optional(),
  bond_form_template_id: Yup.string().optional(),
  bond_form_file_upload_id: Yup.string().optional(),
  point_of_contact: Yup.object({
    first_name: Yup.string().trim().optional(),
    last_name: Yup.string().trim().optional(),
    email: Yup.string().email('Not a valid email.').trim().optional(),
    phone: Yup.string()
      .optional()
      .trim()
      .test('test-phone', 'Invalid US Phone Number.', (value: string | undefined) => {
        if (value) return Yup.string().phone('Invalid US Phone Number').isValidSync(value);
        return true;
      }),
  }).noUnknown(),
  project: Yup.object({
    address: Yup.string().trim().optional(),
    city: Yup.string().trim().optional(),
    state: Yup.string().uppercase().oneOf(Object.values(USStateOrTerritory)),
    zip: Yup.string().trim().postalCode('Invalid ZIP').optional(),
    scope_of_work: Yup.string().oneOf(naicsCodes).optional(),
    months_to_complete: Yup.number().optional().min(1, 'It should be a minimum of a month.'),
    contract_warranty_months: Yup.number().optional().min(1, 'It should be a minimum of a month.'),
    completion_bond: Yup.boolean().optional(),
    contract_hazards: Yup.array().optional(),
    contract_damages: Yup.array().optional(),
  }).noUnknown(),
  principal: Yup.object({
    name: Yup.string().trim().optional(),
    address: Yup.string().trim().optional(),
    city: Yup.string().trim().optional(),
    state: Yup.string().uppercase().oneOf(Object.values(USStateOrTerritory)).optional(),
    zip: Yup.string().trim().postalCode('Invalid ZIP').optional(),
    naics_code: Yup.string().oneOf(naicsCodes).optional(),
    fein: Yup.string().ein('Invalid FEIN').optional(),
    owners: Yup.array().optional(),
  }).noUnknown(),
}).noUnknown();

export const CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  bond_amount: Yup.number().min(0, 'Only positive values are allowed.').optional(),
  contract_amount: Yup.number().min(0, 'Only positive values are allowed.').optional(),
}).noUnknown();

export const BID_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  bond_amount: Yup.number().min(0, 'Only positive values are allowed.').optional(),
  bid_amount: Yup.number().min(0, 'Only positive values are allowed.').optional(),
  final_bond_type: Yup.string()
    .oneOf(
      Object.keys(BondFormTemplateType).map(function (type) {
        return type;
      }),
    )
    .nullable()
    .optional(),
  bid_date: Yup.string().presentOrFutureDate().optional().nullable(),
}).noUnknown();

export const SMALL_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    failed_to_complete_a_construction_job: Yup.boolean().nullable().optional(),
    largest_construction_project_completed: Yup.number()
      .optional()
      .min(0, 'Only positive values are allowed.'),
    lost_a_payment_suit: Yup.boolean().nullable().optional(),
    caused_surety_loss: Yup.boolean().nullable().optional(),
    profitable_ytd: Yup.boolean().nullable().optional(),
    profitable_last_fiscal_year: Yup.boolean().nullable().optional(),
  }).noUnknown(),
}).noUnknown();

export const MEDIUM_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    construction_projects_gross_loss_gt_10_percent_last_36_months: Yup.boolean()
      .nullable()
      .optional(),
    current_construction_project_expected_gross_loss: Yup.boolean().nullable().optional(),
    construction_project_backlog_cost: Yup.number()
      .optional()
      .min(0, 'Only positive values are allowed.'),
    last_fiscal_year_end_statement: Yup.object({
      preparation_method: Yup.string()
        .oneOf(
          financialStatementTypes.map(function (type) {
            return type.value;
          }),
        )
        .optional(),
      date: Yup.string().pastOrPresentDate().optional().nullable(),
      corporate_cash: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      current_assets: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      current_liabilities: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      total_assets: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      total_liabilities: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      revenue: Yup.number().optional().min(0, 'Only positive values are allowed.'),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown();

export const LARGE_CONTRACT_SURETY_REQUEST_DRAFT_VALIDATION_SCHEMA = Yup.object({
  principal: Yup.object({
    construction_project_backlog_gross_profit: Yup.number().optional(),
    bloc_size: Yup.number().optional().min(0, 'Only positive values are allowed.'),
    profitable_year_prior_to_last_fiscal_year: Yup.boolean().nullable().optional(),
    last_fiscal_year_end_statement: Yup.object({
      accounts_payable: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      accounts_receivable: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      underbillings: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      credit_cards_payable: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      bank_line_payable: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      accrued_expense: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      overbillings: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      current_portion_of_ltd: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      term_loan_debt: Yup.number().optional().min(0, 'Only positive values are allowed.'),
      ga_expense: Yup.number().optional().min(0, 'Only positive values are allowed.'),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown();
