import { Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { AgentInvitePreview } from '../../services/agents';
import { DeleteInviteButton } from './DeleteInviteButton';
import { ResendInviteButton } from './ResendInviteButton';
import { capitalizeFirstLetter } from '../../helpers/format';

const formatUserRole = (params: GridRenderCellParams<string>) => {
  const userRole = params.row.user_roles ? params.row.user_roles[0] : '';

  if (!userRole) return '--';

  return userRole.split('-').map(capitalizeFirstLetter).join(' ');
};

type InvitesTableProps = {
  getAgents: () => Promise<void>;
  rows: AgentInvitePreview[];
};

export const AgentsTable = ({ getAgents, rows }: InvitesTableProps) => {
  const ActionsCell = (params: GridRenderCellParams<string>) => {
    if (params.row.invitation_pending) {
      return (
        <Stack direction='row'>
          <DeleteInviteButton agentId={params.row.id} getAgents={getAgents} />
          <ResendInviteButton agentId={params.row.id} />
        </Stack>
      );
    }

    return '--';
  };
  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      valueGetter: (params: GridRenderCellParams<string>) => params.row.email,
    },
    {
      field: 'user_roles',
      headerName: 'Role',
      flex: 1,
      valueGetter: formatUserRole,
    },

    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) =>
        params.row.invitation_pending ? 'Invite Sent' : 'Active',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: ActionsCell,
    },
  ];

  return (
    <DataGrid sx={{ background: 'white', marginBottom: '20px' }} rows={rows} columns={columns} />
  );
};
