import { Box, Container, Grid, LinearProgress } from '@mui/material';

import { useGetBondRequests } from '../../services/bondRequests';
import { BondsPreview } from '../../shared/types/main';
import { BondsTable } from './BondsTable';
import { useStore } from '../../store';

export const Bonds = () => {
  const {
    loader: { useLoadingScreen },
  } = useStore();

  const { response, isLoading } = useGetBondRequests();
  useLoadingScreen(isLoading);
  let requests = [] as BondsPreview[];
  if (response && response.data) {
    requests = response.data;
  }

  return (
    <div style={{ flexGrow: 1, background: '#F8F8FB' }}>
      <Container maxWidth='xl' sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
        {/* <Grid container item sx={{ alignItems: 'center', paddingBottom: '5px' }}> */}
        <Grid container item xs={10} md={10} direction='row'>
          <div style={{ display: 'flex ', alignItems: 'center' }}>
            <h2 style={{ fontWeight: 'normal', marginRight: '25px' }}>Bonds</h2>
          </div>
          {/* </Grid> */}
        </Grid>

        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {!isLoading && (
          <div style={{ height: '80vh', width: '100%' }}>
            <BondsTable requests={requests} />
          </div>
        )}
      </Container>
    </div>
  );
};
