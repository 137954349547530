import { SetNullable } from '../types/utils';
import Yup from './custom';

export const ISSUE_BOND_BASE_VALIDATION_SCHEMA = Yup.object({
  project_owner_class_prefix: Yup.string().trim().nullable().required('Required'),
});

export const ISSUE_BOND_INDEMNITY_AGREEMENT_INDIVIDUAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA =
  Yup.object({
    contact_id: Yup.string().trim().uuid({ version: 'uuidv4' }).required('Required'),
    email: Yup.string().email('Not a valid email.').trim().required('Required'),
  }).noUnknown();

export const ISSUE_BOND_INDEMNITY_AGREEMENT_COMPANY_INDEMNITOR_SIGNER_VALIDATION_SCHEMA =
  Yup.object({
    contact_id: Yup.string().trim().uuid({ version: 'uuidv4' }).required(),
    first_name: Yup.string().trim().required('Required'),
    middle_initial: Yup.string().trim().uppercase().max(1, 'Must be only one character').optional(),
    last_name: Yup.string().trim().required('Required'),
    suffix: Yup.string().trim().nullable().optional(),
    title: Yup.string().trim().required('Required'),
    email: Yup.string().email('Not a valid email.').trim().required('Required'),
  }).noUnknown();

export const ISSUE_BOND_INDEMNITY_AGREEMENT_PRINCIPAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA =
  Yup.object({
    first_name: Yup.string().trim().required('Required'),
    middle_initial: Yup.string().trim().uppercase().max(1, 'Must be only one character').optional(),
    last_name: Yup.string().trim().required('Required'),
    suffix: Yup.string().trim().nullable().optional(),
    title: Yup.string().trim().required('Required'),
    email: Yup.string().email('Not a valid email.').trim().required('Required'),
  }).noUnknown();

export const ISSUE_BOND_FORM_BASE_VALIDATION_SCHEMA = Yup.object({
  issuance_method: Yup.string().oneOf(['electronic', 'physical']).required('Required'),
});

export const ISSUE_BOND_FORM_DATA_OBLIGEE_BASE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().trim().required('Required'),
  address: Yup.string().trim().required('Required'),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().uppercase().nullable().required('Required'),
  zip: Yup.string().trim().postalCode('Invalid zip').required('Required'),
  role: Yup.string().trim().nullable().required('Required'),
}).noUnknown();

export const ISSUE_BOND_FORM_DATA_BASE_VALIDATION_SCHEMA = Yup.object({
  principal_signer: Yup.object({
    first_name: Yup.string().trim().required('Required'),
    middle_initial: Yup.string().trim().uppercase().max(1, 'Must be only one character').optional(),
    last_name: Yup.string().trim().required('Required'),
    suffix: Yup.string().trim().nullable().optional(),
    title: Yup.string().trim().required('Required'),
    email: Yup.string().email('Invalid email').trim().required('Required'),
  }).noUnknown(),
}).noUnknown();

export const ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_VALIDATION_SCHEMA =
  ISSUE_BOND_FORM_DATA_OBLIGEE_BASE_VALIDATION_SCHEMA.concat(
    Yup.object({
      familiar: Yup.boolean().nullable().required('Required'),
    }),
  ).noUnknown();

export const ISSUE_CONTRACT_BOND_FORM_VALIDATION_SCHEMA =
  ISSUE_BOND_FORM_BASE_VALIDATION_SCHEMA.concat(
    Yup.object({
      data: ISSUE_BOND_FORM_DATA_BASE_VALIDATION_SCHEMA.concat(
        Yup.object({
          obligees: Yup.array()
            .of(ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_VALIDATION_SCHEMA)
            .length(1)
            .required(),
          contract_description: Yup.string().trim().required('Required'),
          contract_date: Yup.string().date().required('Required'),
        }),
      ),
    }),
  ).noUnknown();

export const ISSUE_BID_BOND_FORM_VALIDATION_SCHEMA = ISSUE_BOND_FORM_BASE_VALIDATION_SCHEMA.concat(
  Yup.object({
    data: ISSUE_BOND_FORM_DATA_BASE_VALIDATION_SCHEMA.concat(
      Yup.object({
        project_description: Yup.string().trim().required('Required'),
        obligees: Yup.array()
          .of(ISSUE_BOND_FORM_DATA_OBLIGEE_BASE_VALIDATION_SCHEMA)
          .length(1)
          .required(),
      }),
    ),
  }),
).noUnknown();

export const ISSUE_FINAL_BOND_VALIDATION_SCHEMA = ISSUE_BOND_BASE_VALIDATION_SCHEMA.concat(
  Yup.object({
    bond_form: ISSUE_BOND_FORM_BASE_VALIDATION_SCHEMA.concat(
      Yup.object({
        data: ISSUE_BOND_FORM_DATA_BASE_VALIDATION_SCHEMA.concat(
          Yup.object({
            contract_description: Yup.string().trim().required('Required'),
            obligees: Yup.array()
              .of(ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_VALIDATION_SCHEMA)
              .length(1)
              .required(),
          }),
        ),
      }),
    ).noUnknown(),
  }),
).noUnknown();

export const ISSUE_BOND_FORM_DATA_OBLIGEE_INTIAL_VALUES_BASE: SetNullable<
  Yup.InferType<typeof ISSUE_BOND_FORM_DATA_OBLIGEE_BASE_VALIDATION_SCHEMA>,
  ['role']
> = {
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  role: null,
};

export const ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_INTIAL_VALUES: SetNullable<
  Yup.InferType<typeof ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_VALIDATION_SCHEMA>,
  ['role', 'familiar']
> = {
  ...ISSUE_BOND_FORM_DATA_OBLIGEE_INTIAL_VALUES_BASE,
  familiar: null,
};

export const ISSUE_BOND_FORM_DATA_INITIAL_VALUES_BASE: SetNullable<
  Yup.InferType<typeof ISSUE_BOND_FORM_DATA_BASE_VALIDATION_SCHEMA>,
  []
> = {
  principal_signer: {
    first_name: '',
    middle_initial: '',
    last_name: '',
    suffix: null,
    title: '',
    email: '',
  },
};

export const ISSUE_BOND_INITIAL_VALUES_BASE: SetNullable<
  Yup.InferType<typeof ISSUE_BOND_BASE_VALIDATION_SCHEMA>,
  ['project_owner_class_prefix']
> = {
  project_owner_class_prefix: null,
};
