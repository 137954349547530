import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

import { BondsPreview } from '../../shared/types/main';
import { formatToDollar } from '../../shared/util/main';

const columns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'Bond Number',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params => params.row.number || '',
  },
  {
    field: 'principal_name',
    headerName: 'Principal',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params => params.row.principal_name || '',
  },
  {
    field: 'obligee',
    headerName: 'Obligee',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params =>
      params.row.obligees !== undefined ? params.row.obligees.join(', ') : '',
  },
  {
    field: 'effective_date',
    headerName: 'Effective',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params =>
      (params.row.effective_date &&
        DateTime.fromISO(params.row.effective_date).toFormat('MM/dd/yyyy')) ||
      '',
  },
  {
    field: 'amount',
    headerName: 'Bond Amount',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params => formatToDollar(params.row.amount) || '',
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params =>
      params.row.type !== undefined
        ? `${params.row.type[0].toUpperCase()}${params.row.type.slice(1)}`
        : '',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: false,
    editable: false,
    valueGetter: params =>
      params.row.status !== undefined
        ? `${params.row.status[0].toUpperCase()}${params.row.status.slice(1)}`
        : '',
  },
];

type BondsTableProps = {
  requests: BondsPreview[];
};

export const BondsTable = ({ requests }: BondsTableProps) => {
  const navigate = useNavigate();

  const handleOnRowClick = (params: GridRowParams) => {
    if (params.row.type === 'bid') {
      navigate(`/bonds/contract/bid/${params.row.id}`);
    } else if (params.row.type === 'final') {
      navigate(`/bonds/contract/final/${params.row.id}`);
    } else {
      navigate(`/bonds/contract/${params.row.id}`);
    }
  };

  return (
    <DataGrid
      sx={{ background: 'white', marginBottom: '20px' }}
      rows={requests}
      columns={columns}
      onRowClick={handleOnRowClick}
    />
  );
};
