import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import { Grid, Stack, Typography } from '@mui/material';

export const RejectedView = () => (
  <Grid container spacing={4} direction='column' justifyContent='center' alignItems='center' p={4}>
    <Stack
      justifyContent='center'
      alignItems='center'
      style={{ padding: '2rem' }}
      direction='row'
      spacing={2}
    >
      <CloseIcon sx={{ color: red[500] }} />
      <Typography variant='h5'>Rejected</Typography>
    </Stack>
    <Typography variant='h6'>It looks like the terms of this bond have been rejected. </Typography>
    <Typography variant='caption'>
      We can&apos;t win them all but if you&apos;d like to discuss this further with an underwriter,
      please reach out to us at:
      <a href='bonds@getinrev.com'>bonds@getinrev.com</a>
    </Typography>
  </Grid>
);
