import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#111835',
    },
    secondary: {
      main: '#FAFAFC',
    },
    blue: {
      main: '#477CCC',
      contrastText: '#FFF',
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: '#f2c232',
    },
  },
  typography: {
    fontFamily: ['"Outfit"', 'Arial', 'Helvetica', 'sans-serif'].join(', '),
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h5' },
          style: { fontSize: 18, fontWeight: 700 },
        },
        {
          props: { variant: 'h6' },
          style: { fontSize: 14, fontWeight: 700 },
        },
      ],
    },
  },
});

declare module '@mui/material/styles' {
  // eslint-disable-next-line
  interface Palette {
    blue: Palette['primary'];
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line
  interface PaletteOptions {
    blue?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  // eslint-disable-next-line
  interface ButtonPropsColorOverrides {
    blue: true;
  }
}

export default theme;
