import { CompanyFinancialStatementPreparationMethod } from '../constants/financialStatementTypes';
import { NAICSCode } from '../constants/naicsCodes';
import { NameSuffix } from '../constants/nameSuffix';
import { USStateOrTerritory } from '../constants/usStates';
import { useRequest, useMutate } from './useRequest';

export enum ContractSuretyUnderwritingModelName {
  NUCLEUS_LIGHT_BLUE = 'nucleus_light_blue',
  NUCLEUS_BLUE = 'nucleus_blue',
  NUCLEUS_DARK_BLUE = 'nucleus_dark_blue',
}

export enum ContractSuretyBondType {
  PNP = 'pnp',
  PERFORMANCE = 'performance',
  PAYMENT = 'payment',
}

export enum ContractSuretyQuoteStatus {
  APPROVED = 'approved',
  REVIEW = 'review',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending',
  BOUND = 'bound',
}

export type PointOfContact = {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
};

export type NucleusLightBlueRequestPrincipalOwnerPayload =
  | NucleusLightBlueRequestPrincipalOwnerPayload.Individual
  | NucleusLightBlueRequestPrincipalOwnerPayload.Company
  | NucleusLightBlueRequestPrincipalOwnerPayload.Fund;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NucleusLightBlueRequestPrincipalOwnerPayload {
  interface Base {
    contact_id: string;
  }

  export interface Individual extends Base {
    type: 'individual';
    first_name: string;
    middle_initial?: string;
    last_name: string;
    suffix?: NameSuffix;
    address: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    phone?: string;
    bankruptcy?: boolean;
    ofac_hit?: boolean;
    owns_a_home?: boolean;
    credit_report_permission: boolean;
  }

  export interface Company extends Base {
    type: 'company';
    name: string;
    address: string;
    city: string;
    state: USStateOrTerritory;
    zip: string;
    fein?: string;
    fsr?: number;
    bankruptcy?: boolean;
    lien_balance?: number;
    owners: NucleusLightBlueRequestPrincipalOwnerPayload[];
  }

  export interface Fund extends Base {
    type: 'fund';
    name: string;
  }
}

export type NucleusLightBlueRequestPrincipalPayload = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: USStateOrTerritory;
  zip: string;
  email?: string;
  phone?: string;
  fein?: string;
  naics_code: NAICSCode;
  years_in_business_or_credit_age?: number;
  largest_construction_project_completed: number;
  bankruptcy?: boolean;
  judgment_balance?: number;
  lien_balance?: number;
  fsr?: number;
  adjusted_fsr?: number;
  average_owner_fico?: number;
  lost_a_payment_suit: boolean;
  failed_to_complete_a_construction_job: boolean;
  caused_surety_loss: boolean;
  profitable_ytd: boolean;
  profitable_last_fiscal_year: boolean;
  owners: NucleusLightBlueRequestPrincipalOwnerPayload[];
};

export type NucleusBlueRequestPrincipalFinancialStatementPayload = {
  preparation_method: CompanyFinancialStatementPreparationMethod;
  date: string;
  corporate_cash: number;
  current_assets: number;
  current_liabilities: number;
  total_assets: number;
  total_liabilities: number;
  revenue: number;
};

export type NucleusPurpleRequestPrincipalFinancialStatementPayload =
  NucleusBlueRequestPrincipalFinancialStatementPayload & {
    accounts_payable: number;
    accounts_receivable: number;
    underbillings: number;
    credit_cards_payable: number;
    bank_line_payable: number;
    accrued_expense: number;
    overbillings: number;
    current_portion_of_ltd: number;
    term_loan_debt: number;
    ga_expense: number;
  };

export type NucleusBlueRequestPrincipalPayload = NucleusLightBlueRequestPrincipalPayload & {
  construction_projects_gross_loss_gt_10_percent_last_36_months: boolean;
  current_construction_project_expected_gross_loss: boolean;
  construction_project_backlog_cost: number;
  last_fiscal_year_end_statement: NucleusBlueRequestPrincipalFinancialStatementPayload;
};

export type NucleusPurpleRequestPrincipalPayload = NucleusBlueRequestPrincipalPayload & {
  construction_project_backlog_gross_profit: number;
  bloc_size: number;
  last_fiscal_year_end_statement: NucleusPurpleRequestPrincipalFinancialStatementPayload;
  profitable_year_prior_to_last_fiscal_year: boolean;
};

export type NucleusLightBlueRequestProjectPayload = {
  address: string;
  city: string;
  state: USStateOrTerritory;
  zip: string;
  scope_of_work: NAICSCode;
  months_to_complete: number;
  contract_warranty_months: number;
  completion_bond: boolean;
  contract_hazards: Array<string>;
  contract_damages: Array<string>;
};

export type NucleusBlueRequestProjectPayload = NucleusLightBlueRequestProjectPayload & {
  contract_includes_consequential_damages: boolean;
};

export type NucleusPurpleRequestProjectPayload = NucleusBlueRequestProjectPayload;

export type NucleusLightBlueRequestPayloadBase = {
  model: ContractSuretyUnderwritingModelName.NUCLEUS_LIGHT_BLUE;
  project: NucleusLightBlueRequestProjectPayload;
  principal: NucleusLightBlueRequestPrincipalPayload;
};

export type NucleusBlueRequestPayloadBase = {
  model: ContractSuretyUnderwritingModelName.NUCLEUS_BLUE;
  project: NucleusBlueRequestProjectPayload;
  principal: NucleusBlueRequestPrincipalPayload;
};

export type NucleusPurpleRequestPayloadBase = {
  model: ContractSuretyUnderwritingModelName.NUCLEUS_BLUE;
  project: NucleusPurpleRequestProjectPayload;
  principal: NucleusPurpleRequestPrincipalPayload;
};

export type RequestPayloadBase = {
  id: string;
  contract_type: ContractSuretyBondType;
  model_version: string;
  contract_amount: number;
  bid_amount: number;
  final_bond_type: string;
  bid_date: string;
  bond_amount: number;
  expires: string;
  pending_bond_form_review: boolean;
  pending_indemnity_agreement: boolean;
  bond_form_template_id?: string;
  bond_form_template_to_review_id?: string;
  bond_form_file_upload_id?: string;
  t_listing_required: boolean;
  status: ContractSuretyQuoteStatus;
  bond_id?: string;
  agency_id: string;
  agent_id?: string;
  carrier_name: string;
  point_of_contact: PointOfContact;
  terms?: {
    bond_amount: number;
    contract_amount: number;
    flat_rate?: number;
    sliding_scale_rate?: { amount: number; rate: number }[];
    premium: number;
    pending_bond_form_review: boolean;
    final_bond_contingent_premium?: number;
    final_bond_contingent_sliding_scale_rate?: { amount: number; rate: number }[];
    final_bond_contingent_flat_rate?: number;
  };
};

export type RequestPayload = RequestPayloadBase &
  (
    | NucleusLightBlueRequestPayloadBase
    | NucleusBlueRequestPayloadBase
    | NucleusPurpleRequestPayloadBase
  );

export const useGetRequest = (id: string, options = { skipInitial: false }, isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/${id}`
    : `/v1/surety/contract/quotes/${id}`;

  return useRequest<RequestPayload>(path, 'GET', options);
};

export const useAcceptRequestTerms = (id: string, isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/${id}/accept`
    : `/v1/surety/contract/quotes/${id}/accept`;

  return useMutate(path, 'PATCH');
};

export const useRejectRequestTerms = (id: string, isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/${id}/reject`
    : `/v1/surety/contract/quotes/${id}/reject`;

  return useMutate(path, 'PATCH');
};
