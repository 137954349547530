import { ownerTypes } from '../constants/ownerTypes';
import 'yup-phone';
import { USStateOrTerritory } from '../constants/usStates';
import Yup from './custom';

export const PRINCIPAL_OWNER_INDIVIDUAL_VALIDATION_SCHEMA = Yup.object({
  type: Yup.string().trim().oneOf(Object.keys(ownerTypes)),
  first_name: Yup.string().trim().required('Required'),
  middle_initial: Yup.string().trim().uppercase().max(1, 'Must be only one character').optional(),
  last_name: Yup.string().trim().required('Required'),
  suffix: Yup.string().trim().optional(),
  address: Yup.string().trim().required('Required'),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().uppercase().oneOf(Object.values(USStateOrTerritory)).required('Required'),
  zip: Yup.string().trim().postalCode('Invalid zip').required('Required'),
  email: Yup.string().email('Invalid email').trim().required('Required'),
  phone: Yup.string()
    .optional()
    .trim()
    .test('test-phone', 'Invalid US Phone Number.', (value: string | undefined) => {
      if (value) {
        if (value === '+1') {
          return true;
        } else {
          return Yup.string().phone('Invalid US Phone Number').isValidSync(value);
        }
      }
      return true;
    }),
  married: Yup.boolean().notRequired().required('Required'),
  spouse: Yup.object().when('married', {
    is: true,
    then: schema =>
      schema.shape({
        first_name: Yup.string().trim().required('Required'),
        middle_initial: Yup.string()
          .trim()
          .uppercase()
          .max(1, 'Must be only one character')
          .optional(),
        last_name: Yup.string().trim().required('Required'),
        suffix: Yup.string().trim().optional(),
        email: Yup.string().email('Invalid email').trim().required('Required'),
      }),
    otherwise: schema => schema.is([undefined]),
  }),
  owns_a_home: Yup.boolean().nullable().defined().required('Required'),
  credit_report_permission: Yup.boolean()
    .isTrue('Missing credit check permission')
    .defined('Missing credit check permission')
    .required('Required'),
  ssn: Yup.string()
    .matches(/^[0-9]{9}$/, 'Must be nine digits (0-9)')
    .trim()
    .required('Required'),
}).noUnknown();

export const PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA = Yup.object({
  type: Yup.string().trim().oneOf(Object.keys(ownerTypes)),
  name: Yup.string().trim().required('Required'),
  address: Yup.string().trim().required('Required'),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().uppercase().oneOf(Object.values(USStateOrTerritory)).required('Required'),
  zip: Yup.string().trim().postalCode('Invalid zip').required('Required'),
  fein: Yup.string().ein('Invalid FEIN').required('Required'),
}).noUnknown();

export const PRINCIPAL_OWNER_PEF_VALIDATION_SCHEMA = Yup.object({
  type: Yup.string().trim().oneOf(Object.keys(ownerTypes)),
  name: Yup.string().trim().required('Required'),
}).noUnknown();
