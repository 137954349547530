import { useState } from 'react';
import { Button } from '@mui/material';
import { InviteForm } from './InviteForm';

type InviteButtonProps = {
  getAgents: () => Promise<void>;
};

export const InviteButton = ({ getAgents }: InviteButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' color='blue' onClick={handleClickOpen}>
        Invite
      </Button>
      <InviteForm getAgents={getAgents} open={open} handleClose={handleClose} />
    </div>
  );
};
