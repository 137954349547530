import { ReactElement } from 'react';
import { yellow, green, grey, red } from '@mui/material/colors';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DraftsIcon from '@mui/icons-material/Drafts';
type StatusConfig = {
  icon: ReactElement;
  text: string;
};

const statusConfig: Record<string, StatusConfig> = {
  review: {
    icon: <ReportProblemIcon sx={{ color: yellow[500] }} />,
    text: 'Review',
  },
  declined: {
    icon: <CloseIcon sx={{ color: red[500] }} />,
    text: 'Declined',
  },
  approved: {
    icon: <CheckIcon sx={{ color: green[500] }} />,
    text: 'Approved',
  },
  accepted: {
    icon: <CheckCircleIcon sx={{ color: green[500] }} />,
    text: 'Accepted',
  },
  rejected: {
    icon: <CloseIcon sx={{ color: grey[500] }} />,
    text: 'Rejected',
  },
  pending: {
    icon: <PendingActionsIcon sx={{ color: grey[500] }} />,
    text: 'Pending',
  },
  draft: {
    icon: <DraftsIcon sx={{ color: grey[500] }} />,
    text: 'Draft',
  },
  unissued: {
    icon: <></>,
    text: 'Unissued',
  },
};

type StatusCellProps = {
  status: string;
};

export const StatusCell = ({ status }: StatusCellProps) => {
  if (!(status in statusConfig)) {
    console.error("got a status that we don't have config for", status);
    return <></>;
  }

  const config = statusConfig[status];
  const { icon, text } = config;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      <span style={{ paddingLeft: '5px' }}>{text}</span>
    </div>
  );
};
