import { useRef, ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type GlobalSnackbarProviderProps = {
  children: ReactNode | ReactNode[];
};

export const GlobalSnackbarProvider = (props: GlobalSnackbarProviderProps) => {
  const snackbarRef = useRef<SnackbarProvider>(null);

  const handleActionClick = (snackbarId: string | number) => {
    if (!snackbarRef || !snackbarRef.current) return;

    snackbarRef.current.closeSnackbar(snackbarId);
  };

  return (
    <SnackbarProvider
      maxSnack={2}
      ref={snackbarRef}
      action={(snackbarId: string | number) => (
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={() => handleActionClick(snackbarId)}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
    >
      {props.children}
    </SnackbarProvider>
  );
};
