import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import FormProgressStepper from './FormProgressStepper';
import { Grid, Stack, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function FormNavBar() {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(-1);
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const isFinalBond = location.pathname.includes('final');

  return (
    <Box
      sx={{ flexGrow: 1, border: '1px grey' }}
      style={{ position: 'sticky', top: 0, left: 0, zIndex: 1000 }}
    >
      <AppBar position='sticky' color='secondary'>
        <Toolbar variant='dense'>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={1}
            p={0.5}
          >
            <Grid item xs={1}>
              <Stack direction='row' justifyContent='space-evenly' alignItems='center'>
                <Button
                  variant='text'
                  color='inherit'
                  component='div'
                  onClick={handleBackClick}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              {isFinalBond && (
                <Typography variant='h6' color='inherit' component='div'>
                  New Final Bond Request
                </Typography>
              )}
              {!isBidBond && !isFinalBond && (
                <Typography variant='h6' color='inherit' component='div'>
                  New Contract Bond Request
                </Typography>
              )}
              {isBidBond && (
                <Typography variant='h6' color='inherit' component='div'>
                  New Bid Bond Request
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <FormProgressStepper />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
