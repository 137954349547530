import { FixedSizeList } from 'react-window';
import BondFormRow from './BondFormRow';
import { BondFormContext } from '../../../components/machines/bondFormMachine';
import { BondFormTemplate } from '../../../services/bondForms';
import stringSimilarity, { Rating } from 'string-similarity';

type BondFormListProps = {
  handleClose: () => void;
  onSelect: (obj: BondFormContext) => void;
  filterParams: BondFormFilterParams;
  bondFormTemplates: BondFormTemplate[];
  setBondFormsCount: (count: number) => void;
};

type BondFormFilterParams = {
  obligee: string | null;
  type: string | null;
  keywords: string | null;
};

export default function BondFormList(props: BondFormListProps) {
  let bondformTemplates = props.bondFormTemplates;

  if (props.filterParams.type) {
    bondformTemplates = bondformTemplates.filter(
      template => template.contract_type === props.filterParams.type,
    );
  }

  if (props.filterParams.obligee) {
    bondformTemplates = bondformTemplates.filter(
      template => template.obligee === props.filterParams.obligee,
    );
  }

  if (props.filterParams.keywords) {
    const templateStrings: string[] = [];
    bondformTemplates.forEach(template => {
      templateStrings.push(Object.values(template).join(' '));
    });
    const matches = stringSimilarity.findBestMatch(props.filterParams.keywords, templateStrings);
    const targets: string[] = matches.ratings
      .sort((a: Rating, b: Rating) => {
        return a.rating - b.rating;
      })
      .reverse()
      .map((rating: Rating) => rating.target);
    const sortedBondFormTemplates = bondformTemplates.sort(
      (a: BondFormTemplate, b: BondFormTemplate) => {
        const indexA = targets.findIndex(target => target.startsWith(a.id));
        const indexB = targets.findIndex(target => target.startsWith(b.id));
        return indexA - indexB;
      },
    );
    bondformTemplates = sortedBondFormTemplates;
  }

  props.setBondFormsCount(bondformTemplates.length);

  return (
    <FixedSizeList
      style={{ backgroundColor: '#FFFFFF' }}
      height={400}
      width={'100%'}
      itemSize={180}
      itemCount={bondformTemplates.length}
      overscanCount={5}
      itemData={bondformTemplates}
    >
      {rowProps => {
        return (
          <BondFormRow {...rowProps} handleClose={props.handleClose} onSelect={props.onSelect} />
        );
      }}
    </FixedSizeList>
  );
}
