export const financialStatementTypes = [
  { value: 'internal', description: 'Internal' },
  { value: 'cpa_comp', description: 'CPA Compilation' },
  { value: 'cpa_review', description: 'CPA Review' },
  { value: 'cpa_audit', description: 'CPA Audit' },
  { value: 'does_not_keep', description: 'Does not keep financial statements' },
];

export type CompanyFinancialStatementPreparationMethod =
  | 'internal'
  | 'cpa_comp'
  | 'cpa_review'
  | 'cpa_audit'
  | 'does_not_keep';
