import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  ListItem,
  Chip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from 'react-drag-drop-files';
import BondFormList from './BondFormList';
import { useMachine } from '@xstate/react';
import { bondFormMachine, BondFormContext } from '../../../components/machines/bondFormMachine';
import {
  BondFormTemplateType,
  BondFormTemplateTypeName,
} from '../../../constants/bondFormTemplateTypes';
import { BondFormTemplate, useGetBondFormTemplates } from '../../../services/bondForms';
import { ReactNode } from 'react';
import {
  PDFPayload,
  useGetNewBondFormTemplatePreviewURL,
  useGetNewBondFormUploadDetails,
  useGetNewBondFormUploadedURL,
  usePostNewBondFormUpload,
} from '../../../services/bondFormUpload';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

type BondFormSelectionModalProps = {
  draftId: string;
  readOnly: boolean;
  onBondFormSelectedOrUpdated: (templateId: string, uploadId: string) => void;
} & any;

export default function BondFormSelectionModal(props: BondFormSelectionModalProps) {
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [manualPageOpen, setManualPageOpen] = React.useState(false);
  const handleManualPageOpen = () => setManualPageOpen(true);
  const handleManualPageClose = () => {
    setManualPageOpen(false);
    setSuretyType('');
    setFileList(undefined);
  };
  const [fileList, setFileList] = React.useState<FileList>();
  const [suretyType, setSuretyType] = React.useState<string | null>(null);
  const [obligee, setObligee] = React.useState<string | null>(null);
  const [keywords, setKeywords] = React.useState<string | null>(null);
  const [bondFormsCount, setBondFormsCount] = React.useState<number>(0);
  const {
    request: uploadPDF,
    response: uploadResponse,
    error: uploadError,
  } = usePostNewBondFormUpload(props.draftId, isBidBond ? 'bid' : 'contract');

  const {
    response: uploadedLink,
    refetch: fetchPreviewLink,
    error: fetchLinkError,
  } = useGetNewBondFormUploadedURL(props.draftId, isBidBond ? 'bid' : 'contract');

  const {
    response: pdfDetails,
    refetch: fetchUploadedFileDetails,
    error: fetchUploadedFileDetailsError,
  } = useGetNewBondFormUploadDetails(props.draftId, isBidBond ? 'bid' : 'contract');

  const {
    response: templatePreviewLink,
    refetch: fetchTemplatePreviewLink,
    error: fetchTemplateLinkError,
  } = useGetNewBondFormTemplatePreviewURL(props.draftId, isBidBond ? 'bid' : 'contract');

  React.useEffect(() => {
    if (!uploadError && uploadResponse) {
      send({
        type: 'UPLOADED',
        data: {
          uploaded: true,
          type: isBidBond ? 'bid' : suretyType,
          title: '[UPLOADED]',
          obligee: null,
          tags: null,
          id: uploadResponse.id,
        } as BondFormContext,
      });
      props.onBondFormSelectedOrUpdated('', uploadResponse.id);
    }
    setSuretyType('');
    setObligee('');
  }, [uploadError, uploadResponse]);

  React.useEffect(() => {
    if (!fetchLinkError && uploadedLink !== null) {
      window.open(uploadedLink, '_blank')?.focus();
    }
  }, [fetchLinkError, uploadedLink]);

  React.useEffect(() => {
    if (!fetchTemplateLinkError && templatePreviewLink !== null) {
      window.open(templatePreviewLink[0], '_blank')?.focus();
    }
  }, [fetchTemplateLinkError, templatePreviewLink]);

  let bondFormTemplates: BondFormTemplate[] = [];
  const { response: templates } = useGetBondFormTemplates(isBidBond);
  if (templates) {
    bondFormTemplates = templates;
  }

  //Hydrating
  React.useEffect(() => {
    if (
      !fetchUploadedFileDetailsError &&
      pdfDetails !== null &&
      props.values.bond_form_file_upload_id
    ) {
      send({
        type: 'UPLOADED',
        data: {
          uploaded: true,
          type: pdfDetails.contract_type,
          title: '[UPLOADED]',
          obligee: null,
          tags: null,
          id: pdfDetails.id,
        } as unknown as BondFormContext,
      });
    }
  }, [fetchUploadedFileDetailsError, pdfDetails, props.values.bond_form_file_upload_id]);

  React.useEffect(() => {
    if (props.values.bond_form_file_upload_id) {
      const path = isBidBond
        ? props.readOnly
          ? `/v1/surety/contract/bid/quotes/${props.draftId}/bond-form-details`
          : `/v1/surety/contract/bid/quotes/drafts/${props.draftId}/bond-form-details`
        : props.readOnly
        ? `/v1/surety/contract/quotes/${props.draftId}/bond-form-details`
        : `/v1/surety/contract/quotes/drafts/${props.draftId}/bond-form-details`;
      fetchUploadedFileDetails(path);
    }
    if (props.values.bond_form_template_id) {
      const bondFormTemplate: BondFormTemplate = bondFormTemplates.filter(template => {
        return template.id === props.values.bond_form_template_id;
      })[0];
      send({
        type: 'SELECTED',
        data: {
          uploaded: false,
          type: isBidBond ? 'bid' : bondFormTemplate.contract_type,
          title: bondFormTemplate.title,
          obligee: bondFormTemplate.obligee,
          tags: bondFormTemplate.tags,
          id: props.values.bond_form_template_id,
        } as unknown as BondFormContext,
      });
    }
  }, [props.values.bond_form_file_upload_id, props.values.bond_form_template_id]);

  const [state, send] = useMachine(bondFormMachine);

  const cardStyle = {
    display: 'block',
    backgroundColor: '#F8F8FB',
  };

  const obligees = new Array<string>();
  const obligeeDescriptionsMenuItems = new Array<ReactNode>();

  const typeDescriptionsMenuItems = new Array<ReactNode>();
  const types = bondFormTemplates.map(template => template.contract_type);
  const uniqueTypes = [...new Set(types)];

  typeDescriptionsMenuItems.push(
    <MenuItem value={''} key={0}>
      All
    </MenuItem>,
  );

  const allTypeDescriptionsMenuItems = [...typeDescriptionsMenuItems];

  typeDescriptionsMenuItems.push(
    Object.values(BondFormTemplateType)
      .filter(type => uniqueTypes.includes(type))
      .map((bondFormTemplateType, index) => (
        <MenuItem value={bondFormTemplateType} key={index + 1}>
          {BondFormTemplateTypeName[bondFormTemplateType]}
        </MenuItem>
      )),
  );

  allTypeDescriptionsMenuItems.push(
    Object.values(BondFormTemplateType).map((bondFormTemplateType, index) => (
      <MenuItem value={bondFormTemplateType} key={index + 1}>
        {BondFormTemplateTypeName[bondFormTemplateType]}
      </MenuItem>
    )),
  );

  obligeeDescriptionsMenuItems.push(
    <MenuItem value={''} key={0}>
      All
    </MenuItem>,
  );

  bondFormTemplates.forEach(bondFormTemplate => {
    if (bondFormTemplate.obligee) {
      obligees.push(bondFormTemplate.obligee);
    }
  });

  const uniqueObligees = new Set<string>(obligees);

  uniqueObligees.forEach((obligee, index) => {
    obligeeDescriptionsMenuItems.push(
      <MenuItem value={obligee} key={index + 1}>
        {obligee}
      </MenuItem>,
    );
  });

  const onSelect = (onSelectData: BondFormContext) => {
    send({
      type: 'SELECTED',
      data: onSelectData,
    });
    props.onBondFormSelectedOrUpdated(onSelectData.id, '');
    setSuretyType('');
    setObligee('');
  };

  const onUpload = (file: File) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (reader.result) {
        isBidBond
          ? uploadPDF({
              file_name: file.name,
              base64: reader.result.slice(28),
            } as PDFPayload)
          : uploadPDF({
              file_name: file.name,
              contract_type: suretyType,
              base64: reader.result.slice(28),
            } as PDFPayload);
      }
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  return (
    <div>
      {state.value === 'bondFormNotSelected' && !props.readOnly ? (
        <Button variant='contained' size='medium' onClick={handleOpen}>
          Select
        </Button>
      ) : (
        <span />
      )}
      {state.value === 'bondFormSelected' ? (
        <ListItem key={'selected'} style={cardStyle}>
          <Stack
            direction='row'
            spacing={0.5}
            p={2}
            alignItems='center'
            justifyContent={'space-between'}
          >
            <Stack direction='column' spacing={0.5} p={2}>
              <Typography variant='h6' color='primary'>
                {state.context.title}
              </Typography>
              {!isBidBond && (
                <Typography variant='subtitle2'>
                  <b>Type:</b>{' '}
                  {BondFormTemplateTypeName[state.context.type as BondFormTemplateType]}
                </Typography>
              )}
              {isBidBond && (
                <Typography variant='subtitle2'>
                  <b>Type:</b> Bid
                </Typography>
              )}
              {state.context.obligee && (
                <Typography variant='subtitle2'>
                  <b>Obligee:</b> {state.context.obligee}
                </Typography>
              )}
              {state.context.tags != null ? (
                <Stack direction='row' spacing={0.5}>
                  {state.context.tags.map((tag, i) => {
                    return (
                      <Chip key={i} label={tag} size='small' variant='outlined' color='primary' />
                    );
                  })}
                </Stack>
              ) : (
                <span />
              )}
            </Stack>
            {!props.readOnly && (
              <Stack direction='column' spacing={0.5}>
                <Button
                  color='error'
                  variant='contained'
                  onClick={() => {
                    send({
                      type: 'REMOVE',
                      data: {
                        uploaded: false,
                        type: '',
                        title: '',
                        obligee: null,
                        tags: null,
                        id: '',
                      } as BondFormContext,
                    });
                    props.onBondFormSelectedOrUpdated('', '');
                  }}
                >
                  REMOVE
                </Button>
                <Button
                  color='info'
                  variant='contained'
                  onClick={() => {
                    const path = isBidBond
                      ? `/v1/surety/contract/bid/bonds/form-templates/${state.context.id}/previews`
                      : `/v1/surety/contract/bonds/form-templates/${state.context.id}/previews`;
                    fetchTemplatePreviewLink(path);
                  }}
                >
                  PREVIEW
                </Button>
              </Stack>
            )}
          </Stack>
        </ListItem>
      ) : (
        <span />
      )}
      {state.value === 'bondFormUploaded' ? (
        <ListItem key={'selected'} style={cardStyle}>
          <Stack
            direction='row'
            spacing={0.5}
            p={2}
            alignItems='center'
            justifyContent={'space-between'}
          >
            <Stack direction='column' spacing={0.5} p={2}>
              <Typography variant='h6' color='primary'>
                {state.context.title}
              </Typography>
              {!isBidBond && (
                <Typography variant='subtitle2'>
                  <b>Type:</b>{' '}
                  {BondFormTemplateTypeName[state.context.type as BondFormTemplateType]}
                </Typography>
              )}
              {isBidBond && (
                <Typography variant='subtitle2'>
                  <b>Type:</b> Bid
                </Typography>
              )}
            </Stack>
            <Stack direction='column' spacing={0.5}>
              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  send({
                    type: 'REMOVE',
                    data: {
                      uploaded: false,
                      type: '',
                      title: '',
                      obligee: null,
                      tags: null,
                      id: '',
                    } as BondFormContext,
                  });
                  props.onBondFormSelectedOrUpdated('', '');
                }}
              >
                REMOVE
              </Button>
              <Button
                color='info'
                variant='contained'
                onClick={() => {
                  const path = isBidBond
                    ? `/v1/surety/contract/bid/quotes/drafts/${props.draftId}/bond-form-url`
                    : `/v1/surety/contract/quotes/drafts/${props.draftId}/bond-form-url`;
                  fetchPreviewLink(path);
                }}
              >
                PREVIEW
              </Button>
            </Stack>
          </Stack>
        </ListItem>
      ) : (
        <span />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {!manualPageOpen ? (
          <Card sx={style}>
            <Stack direction='column' spacing={2}>
              <Stack direction='row' spacing={2}>
                {!isBidBond && (
                  <FormControl fullWidth>
                    <InputLabel id='type-select-label'>Type </InputLabel>
                    <Select
                      id='type'
                      label='Type'
                      aria-describedby='type-select'
                      onChange={event => {
                        setSuretyType(
                          typeof event.target.value === 'string' ? event.target.value : '',
                        );
                      }}
                    >
                      {typeDescriptionsMenuItems}
                    </Select>
                  </FormControl>
                )}
                <FormControl fullWidth>
                  <InputLabel id='obligee-select-label'>Obligee </InputLabel>
                  <Select
                    id='obligee'
                    label='Obligee'
                    aria-describedby='obligee-select'
                    onChange={event =>
                      setObligee(typeof event.target.value === 'string' ? event.target.value : '')
                    }
                  >
                    {obligeeDescriptionsMenuItems}
                  </Select>
                </FormControl>
              </Stack>
              <TextField
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                id='keyword-search'
                label='Keywords'
                aria-describedby='keyword-text'
                inputProps={{
                  'aria-label': 'weight',
                }}
                onChange={event => setKeywords(event.target.value)}
              />
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Typography variant='caption'>Showing {bondFormsCount} Results</Typography>
                <a href='#cantfind' onClick={() => handleManualPageOpen()}>
                  <Typography variant='caption'>I can&apos;t find my bond Form</Typography>
                </a>
              </Stack>
              <BondFormList
                handleClose={handleClose}
                onSelect={onSelect}
                filterParams={{ obligee: obligee, type: suretyType, keywords: keywords }}
                bondFormTemplates={bondFormTemplates}
                setBondFormsCount={setBondFormsCount}
              />
            </Stack>
          </Card>
        ) : (
          <Card sx={style}>
            <BackIcon onClick={() => handleManualPageClose()}>Back to Search</BackIcon>
            <Stack direction='column' spacing={4} alignItems='center'>
              <Typography variant='h5'>Upload Your Bond Form</Typography>
              <Typography variant='caption'>Only PDF file type is accepted.</Typography>
              <FileUploader
                hoverTitle='Drop your Bond Form'
                multiple='false'
                maxSize={1}
                label='Drop your Bond Form'
                handleChange={(fileList: FileList) => setFileList(fileList)}
                name='bond-file'
                types={['PDF']}
              />
              {!isBidBond && (
                <FormControl fullWidth>
                  <InputLabel id='type-select-label'>
                    What type of Contract Surety is this?
                  </InputLabel>
                  <Select
                    id='type'
                    label='What type of Contract Surety is this?'
                    aria-describedby='type-select'
                    value={suretyType}
                    onChange={event => {
                      setSuretyType(
                        typeof event.target.value === 'string' ? event.target.value : '',
                      );
                    }}
                  >
                    {allTypeDescriptionsMenuItems}
                  </Select>
                </FormControl>
              )}
              <Button
                variant='contained'
                component='label'
                disabled={!isBidBond && (!suretyType || suretyType.length === 0 || !fileList)}
                onClick={() => {
                  if (fileList) {
                    onUpload(fileList[0]);
                  }
                  handleClose();
                }}
              >
                Submit Bond Form
              </Button>
            </Stack>
          </Card>
        )}
      </Modal>
    </div>
  );
}
