import { Owner } from './useOwners';
import { useRequest } from './useRequest';

export type PrincipalPreview = {
  id: string;
  name: string;
  contract_surety_quotes: number;
  contract_surety_quotes_amount: number;
  contract_surety_bonds: number;
  contract_surety_bonds_amount: number;
};

export type PrincipalsPreviewsResponse = {
  data: PrincipalPreview[];
  count: number;
};

export type AccountParameters = {
  aggregate_bonds_amount: number;
  contract: AccountParametersCarrier;
};

export type AccountParametersCarrier = {
  name: string;
  display_name: string;
  rate: RateTier;
  single_limit: number;
  aggregate_limit: number;
};

export type RateTier = {
  amount: number;
  rate: number;
};

export type FiscalYearEndStatement = {
  date: string;
  preparation_method: string;
  corporate_cash: number;
  current_assets: number;
  current_liabilities: number;
  total_assets: number;
  total_liabilities: number;
  accounts_receivable: number;
  underbillings: number;
  credit_cards_payable: number;
  bank_line_payable: number;
  accrued_expense: number;
  overbillings: number;
  current_portion_of_ltd: number;
  accounts_payable: number;
  term_loan_debt: number;
  revenue: number;
  ga_expense: number;
};

export type PrincipalDetailsResponse = {
  id: string;
  type: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  fein: string;
  naics_code: number;
  largest_construction_project_completed: number | null;
  lost_a_payment_suit: boolean | null;
  failed_to_complete_a_construction_job: boolean | null;
  caused_surety_loss: boolean | null;
  construction_projects_gross_loss_gt_10_percent_last_36_months: boolean | null;
  current_construction_project_expected_gross_loss: boolean | null;
  construction_project_backlog_cost: number | null;
  construction_project_backlog_gross_profit: number | null;
  last_fiscal_year_end_statement: FiscalYearEndStatement | null;
  account_parameters: AccountParameters | null;
  owners: Owner[];
};

export const useGetPrincipalPreviews = () => {
  return useRequest<PrincipalsPreviewsResponse>('/v1/surety/principals/previews', 'GET');
};

export const useGetPrincipalDetails = () => {
  return useRequest<PrincipalDetailsResponse>(`/v1/surety/principals`, 'GET', {
    skipInitial: true,
  });
};
