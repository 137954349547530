import { useMutate } from './useRequest';

type Contact = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
};

type ProjectDetails = {
  address: string;
  city: string;
  state: string;
  zip: string;
  scope_of_work: string;
  familiar_obligee: boolean;
  completion_bond: string;
  days_to_complete: number;
  contract_warranty_months: number;
};

type Individual = {
  type: string;
  first_name: string;
  middle_initial?: string | null;
  last_name: string;
  suffix?: string | null;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  owns_a_home: boolean;
  credit_report_permission: boolean;
};

type Company = {
  type: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  fein: string;
  owners: [];
};

type PrivateFund = {
  type: string;
  name: string;
};

type Owner = Individual | Company | PrivateFund;

type YearEndFiscalStatement = {
  preparation_method: string;
  date: string;
  corporate_cash: number;
  current_assets: number;
  current_liabilities: number;
  total_assets: number;
  total_liabilities: number;
  revenue: number;
  accounts_receivable: number;
  underbillings: number;
  credit_cards_payable: number;
  bank_line_payable: number;
  accrued_expense: number;
  overbillings: number;
  current_portion_of_ltd: number;
  term_loan_debt: number;
  ga_expense: number;
};

type PrincipalDetails = {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  naics_code: string;
  fein: string;
  largest_construction_project_completed: number;
  lost_a_payment_suit: boolean;
  failed_to_complete_job?: boolean | null;
  caused_surety_loss?: boolean | null;
  profitable_ytd?: boolean | null;
  profitable_last_fiscal_year?: boolean | null;
  construction_projects_gross_loss_gt_10_percent_last_36_months?: boolean | null;
  current_construction_project_expected_gross_loss?: boolean | null;
  construction_project_backlog_cost?: number | null;
  construction_project_backlog_gross_profit?: number | null;
  bloc_size?: number | null;
  owners?: Owner[] | null;
  last_fiscal_year_end_statement?: YearEndFiscalStatement | null;
};

export type NewBondRequestDraftRequest = {
  bond_form_template_id?: string | null;
  bond_form_file_upload_id?: string | null;
  bond_form_template_to_review_id?: string | null;
  contract_type?: string | null;
  bond_amount?: number | null;
  contract_amount?: number | null;
  t_listing_required?: boolean | null;
  point_of_contact?: Contact | null;
  project?: ProjectDetails | null;
  principal?: PrincipalDetails | null;
  principal_id?: string | null;
};

export type NewBondRequestDraftResponse = {
  id: string;
} & NewBondRequestDraftRequest;

export type NewBondRequest = {
  draft_id: string;
} & NewBondRequestDraftResponse;

export const usePostNewBondRequestDraft = () => {
  return useMutate<NewBondRequestDraftResponse, NewBondRequestDraftRequest>(
    '/v1/surety/contract/quotes/drafts',
    'POST',
  );
};

export const usePutNewBondRequestDraft = (draftId: string) => {
  return useMutate<NewBondRequestDraftResponse, NewBondRequestDraftRequest>(
    `/v1/surety/contract/quotes/drafts/${draftId}`,
    'PUT',
  );
};

export const usePostNewBondRequest = () => {
  return useMutate<NewBondRequestDraftResponse, NewBondRequest>(
    '/v1/surety/contract/quotes',
    'POST',
  );
};
