import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { LoadingButton } from '../../components/LoadingButton';
import { usePostAgentInvite } from '../../services/agents';
import { AGENT, AGENT_ADMIN } from '../../constants/userRoles';

type InviteFormProps = {
  getAgents: (path: string) => Promise<void>;
  handleClose: () => void;
  open: boolean;
};

export const InviteForm = ({ getAgents, handleClose, open }: InviteFormProps) => {
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const { request: postAgentInvite, isLoading } = usePostAgentInvite();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleOnSelectChange = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  const handleSendInvite = async () => {
    await postAgentInvite({ email, role });
    getAgents('/v1/agents/previews');
    setEmail('');
    setRole('');
    handleClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Invite User</DialogTitle>

      <DialogContent>
        <Stack alignItems='center' direction='row' spacing={2}>
          <TextField
            id='email'
            fullWidth
            placeholder='Email'
            value={email}
            onChange={handleOnChange}
          />
          <FormControl fullWidth>
            <InputLabel id='role-label'>Role</InputLabel>
            <Select
              labelId='role-label'
              id='role'
              value={role}
              placeholder='Role'
              onChange={handleOnSelectChange}
            >
              <MenuItem value={AGENT}>Agent</MenuItem>
              <MenuItem value={AGENT_ADMIN}>Agent Admin</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Close
        </Button>

        <LoadingButton
          spinnerColor='success'
          variant='contained'
          disabled={!email || !role || isLoading}
          onClick={handleSendInvite}
          text='Send Invite'
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
