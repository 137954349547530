import * as Yup from 'yup';
import { isValid as isEIN } from 'ein-validator';
import { isPostalCode } from 'class-validator';
import { DateTime } from 'luxon';

Yup.addMethod(Yup.string, 'ein', function (errorMessage) {
  return this.test(`test-is-ein`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      if (!isEIN(value)) {
        return createError({ path, message: errorMessage });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'postalCode', function (errorMessage) {
  return this.test(`test-is-postal-code`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      if (!isPostalCode(value, 'US')) {
        return createError({ path, message: errorMessage });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'date', function () {
  return this.test(`string-is-date`, function (value) {
    const { path, createError } = this;
    if (value) {
      if (!DateTime.fromFormat(value, 'MM/dd/yyyy').isValid) {
        return createError({ path, message: 'Must be a date in MM/dd/yyyy format' });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'pastDate', function () {
  return this.test(`string-is-past-date`, function (value) {
    const { path, createError } = this;
    if (value) {
      const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');
      if (!dt.isValid) {
        return createError({ path, message: 'Must be a date in MM/dd/yyyy format' });
      } else if (dt.diff(DateTime.now().startOf('day'), 'days').days >= 0) {
        return createError({ path, message: 'Must be a date in the past' });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'pastOrPresentDate', function () {
  return this.test(`string-is-past-or-present-date`, function (value) {
    const { path, createError } = this;
    if (value) {
      const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');
      if (!dt.isValid) {
        return createError({ path, message: 'Must be a date in MM/dd/yyyy format' });
      } else if (dt.diff(DateTime.now().startOf('day'), 'days').days > 0) {
        return createError({ path, message: 'Must be today or earlier' });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'futureDate', function () {
  return this.test(`string-is-future-date`, function (value) {
    const { path, createError } = this;
    if (value) {
      const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');
      if (!dt.isValid) {
        return createError({ path, message: 'Must be a date in MM/dd/yyyy format' });
      } else if (dt.diff(DateTime.now().startOf('day'), 'days').days <= 0) {
        return createError({ path, message: 'Must be a future date' });
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'presentOrFutureDate', function () {
  return this.test(`string-is-present-or-future-date`, function (value) {
    const { path, createError } = this;
    if (value) {
      const dt = DateTime.fromFormat(value, 'MM/dd/yyyy');
      if (!dt.isValid) {
        return createError({ path, message: 'Must be a date in MM/dd/yyyy format' });
      } else if (dt.diff(DateTime.now().startOf('day'), 'days').days < 0) {
        return createError({ path, message: 'Must be today or later' });
      }
    }
    return true;
  });
});

export default Yup;
