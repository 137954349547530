import { useState, Dispatch, SetStateAction } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { User } from '@auth0/auth0-react';

// metadata key needs to be hardcoded in order to get strict type when decoding the token
const metadataKey = 'https://api.getinrev.com/app_metadata';

if (!metadataKey)
  throw new Error(
    'You must specify an Auth0 metadata key in your .env file with the key REACT_APP_AUTH0_METADATA_KEY',
  );

type JwtPayloadExtended = {
  [metadataKey]: {
    agency_id: string;
    agent_id: string;
    roles: [string];
    setup_complete: boolean;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
} & JwtPayload;

const state = {
  agencyId: '',
  agentId: '',
  roles: [],
  setupComplete: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export type UserStore = {
  agencyId: string;
  agentId: string;
  roles: Array<string>;
  setupComplete: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type JwtPayloadMetadata = {
  agency_id: string;
  agent_id: string;
  roles: Array<string>;
  setup_complete: boolean;
};

export type GetUserRole = () => string;

export type SetTokenValues = (token: string, auth0User?: User) => void;

export type UserStoreContextType = {
  user: UserStore;
  getUserRole: GetUserRole;
  setUser: Dispatch<SetStateAction<UserStore>>;
  setTokenMetadata: SetTokenValues;
};

export const useUserStore = () => {
  const [user, setUser] = useState<UserStore>(state);

  const setTokenMetadata = (token: string, auth0User?: User) => {
    const decodedToken = jwtDecode<JwtPayloadExtended>(token);
    const { agency_id, agent_id, roles, setup_complete, first_name, last_name, phone } =
      decodedToken[metadataKey];

    setUser({
      ...user,
      agencyId: agency_id,
      agentId: agent_id,
      roles,
      setupComplete: setup_complete,
      firstName: first_name,
      lastName: last_name,
      email: auth0User?.email ?? '',
      phone,
    });
  };

  const getUserRole = () => (user.roles.length ? user.roles[0] : '');

  return { user, getUserRole, setUser, setTokenMetadata };
};
