import { FinalRequestPayload } from './finalRequest';
import { RequestPayload } from './request';
import { useMutate } from './useRequest';

export enum ContractSuretyBondObligeeRole {
  PROJECT_OWNER = 'project_owner',
  LENDER = 'lender',
  GC = 'gc',
  OTHER = 'other',
}

export enum ContractSuretyBondObligeeRoleLabel {
  project_owner = 'Project Owner',
  lender = 'Lender',
  gc = 'General Contractor',
  other = 'Other',
}

export enum ContractSuretyBondProjectOwnerClassPrefix {
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}

export enum IssuanceMethod {
  electronic = 'electronic',
  physical = 'physical',
  agent = 'agent',
}

export enum IssuanceMethodLabel {
  electronic = 'Electronic (Fastest)',
  physical = 'Physical copy with wet signatures and raised seals',
  agent = 'I`ll issue it myself (Coming soon)',
}

export const ContractSuretyBondProjectOwnerClassPrefixLabel = {
  '6': 'Federal',
  '7': 'Other public',
  '8': 'Private',
};

export type BindRequestRequestDto = {
  project_owner_class_prefix: string | null;
  obligee_role: string | null;
  familiar_obligee: boolean | null;
  bond_form: {
    issuance_method: string | null;
    data: {
      contract_date: Date | null;
      contract_description: string;
      obligees: {
        name: string;
        address: string;
        city: string;
        state: string | null;
        zip: string;
      }[];
      principal_signer: {
        first_name: string;
        last_name: string;
        suffix?: string | null;
        title: string;
        email: string;
      };
    };
  };
  indemnity_agreement?: {
    principal_signer: {
      first_name: string;
      last_name: string;
      suffix?: string | null;
      title: string;
      email: string;
    };
    individual_signers: {
      contact_id: string;
      email: string;
    }[];
    company_signers: {
      contact_id: string;
      first_name: string;
      middle_initial?: string;
      last_name: string;
      suffix?: string | null;
      title: string;
      email: string;
    }[];
  };
};

export type BindBidRequestDto = {
  project_owner_class_prefix: string | null;
  obligee_role: string | null;
  familiar_obligee: boolean | null;
  bond_form: {
    issuance_method: string | null;
    data: {
      project_description: string;
      obligees: {
        name: string;
        address: string;
        city: string;
        state: string | null;
        zip: string;
      }[];
      principal_signer: {
        first_name: string;
        last_name: string;
        suffix?: string | null;
        title: string;
        email: string;
      };
    };
  };
  indemnity_agreement?: {
    principal_signer: {
      first_name: string;
      last_name: string;
      suffix?: string | null;
      title: string;
      email: string;
    };
    individual_signers: {
      contact_id: string;
      email: string;
    }[];
    company_signers: {
      contact_id: string;
      first_name: string;
      middle_initial?: string;
      last_name: string;
      suffix?: string | null;
      title: string;
      email: string;
    }[];
  };
};

export type BindFinalRequestRequestDto = {
  project_owner_class_prefix: string | null;
  bond_form: {
    issuance_method: string;
    data: {
      contract_description: string;
      obligees: {
        name: string;
        address: string;
        city: string;
        state: string | null;
        zip: string;
        role: string | null;
        familiar: boolean | null;
      }[];
      principal_signer: {
        first_name: string;
        last_name: string;
        suffix?: string | null;
        title: string;
        email: string;
      };
    };
  };
};

export const useBindRequestRequest = (requestId: string, isBidBond: boolean) => {
  const path = isBidBond
    ? `/v1/surety/contract/bid/quotes/${requestId}/bind`
    : `/v1/surety/contract/quotes/${requestId}/bind`;
  return useMutate<RequestPayload, BindRequestRequestDto>(path, 'POST');
};

export const useBindFinalRequestRequest = (requestId: string) => {
  return useMutate<FinalRequestPayload, BindFinalRequestRequestDto>(
    `/v1/surety/contract/final/quotes/${requestId}/bind`,
    'POST',
  );
};
