// TODO: Organize this file
export enum SuretyBondType {
  bid = 'Bid',
  contract = 'Contract',
  subdivision = 'Subdivision',
  maintenance = 'Maintenance',
}

export enum SuretyBondRenewalMethod {
  certificate = 'Certificate',
  new_bond = 'New Bond',
  auto = 'Auto',
}

export enum ContractSuretyBondType {
  pnp = 'Performance and Payment',
  performance = 'Performance',
  payment = 'Payment',
  supply = 'Supply',
}

export enum SuretyBondStatus {
  unissued = 'unissued',
  open = 'open',
  closed = 'closed',
}

export type Rate = {
  amount: number;
  rate: number;
};

export type BondRequestPreview = {
  id: string;
  type: string;
  status: string;
  description: string;
  bond_amount: number | null;
  created_at: string;
  submitted_at: string;
  principal_id?: string | null;
  principal_name?: string;
};

export type BondPeriod = {
  id: string;
  agency_commission: number;
  amount: number;
  flat_rate?: number;
  sliding_scale_rate?: Rate[];
  premium: number;
  effective_rate: number;
  effective_date: string;
  expiration_date?: string;
  quote_id: string;
  final_bond_contingent_premium?: string;
  final_bond_contingent_flat_rate?: string;
  final_bond_contingent_sliding_scale_rate?: Rate[];
};

export type BondRequest = {
  id: string;
  type: string;
  contract_type: string;
  final_bond_type?: string;
  status: string;
  number: string;
  agency_id: string;
  agent_id?: string;
  agent_email?: string;
  carrier_name: string;
  principal_id: string;
  principal_name: string;
  bond_created_from_id?: string;
  bond_created_from_number?: string;
  child_bond_id?: string;
  child_bond_number?: string;
  bond_term_length?: {
    days?: number;
    weeks?: number;
    months?: number;
    years?: number;
  };
  bond_periods: BondPeriod[];
  renewable: boolean;
  renewal_method?: string;
  closed_at?: string;
  continuous: boolean;
  term_periods_remaining?: number;
  point_of_contact: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
  };
  bond_form: {
    id: string;
    completed_at?: string;
  };
  poa: {
    id: string;
    completed_at?: string;
  };
  attachments: {
    id: string;
    title: string;
    created_at: string;
  }[];
  final_bond_contingent_flat_rate?: number;
  final_bond_contingent_premium?: number;
  final_bond_contingent_sliding_scale_rate?: Rate[];
  bid_amount?: number;
};

export type BondsPreview = {
  amount: number;
  effective_date: string;
  description: string;
  id: string;
  number: string;
  obligees: string[];
  principal_id: string;
  principal_name: string;
  status: string;
  type: string;
};

export type BondsPreviewResponse = {
  data: BondsPreview[];
  count: number;
};

export type BondRequestPreviewsResponse = {
  data: BondRequestPreview[];
  count: number;
};

export type PrincipalPreviewResponse = {
  contract_surety_bonds: number;
  contract_surety_bonds_amount: number;
  contract_surety_quotes: number;
  contract_surety_quotes_amount: number;
  id: string;
  name: string;
};

export type UseGetPrincipalPreviewsRequests = {
  count: number;
  data: PrincipalPreviewResponse[];
};
