import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  SvgIconProps,
} from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

type LoadingButtonProps = {
  isLoading: boolean;
  text: string;
  spinnerColor: CircularProgressProps['color'];
  icon?: React.ReactElement<SvgIconProps>;
} & ButtonProps;

type SpinnerProps = {
  spinnerSize?: string | number;
  spinnerStyles?: SxProps<Theme>;
} & CircularProgressProps;

export const Spinner = (props: SpinnerProps) => {
  const { ...rest } = props;

  return <CircularProgress color={props.color} size={20} {...rest} />;
};

export const LoadingButton = (props: LoadingButtonProps) => {
  const { isLoading = false, text, ...buttonProps } = props;

  return (
    <Button {...buttonProps}>
      {isLoading ? '' : text}
      {isLoading ? '' : props.icon}
      {isLoading ? <Spinner color={props.spinnerColor} /> : ''}
    </Button>
  );
};
