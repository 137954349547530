import { Navigate } from 'react-router-dom';
import logo from '../assets/logo.svg';

export const Root = () => {
  return (
    <div className='App'>
      <Navigate to='/requests' replace={true} />
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
      </header>
    </div>
  );
};
