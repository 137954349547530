import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import {
  WithAuthenticationRequiredOptions,
  withAuthenticationRequired,
  Auth0ProviderOptions,
  AppState,
  Auth0Provider,
  useAuth0,
} from '@auth0/auth0-react';

import { LoadingScreen } from './components/LoadingScreen';
import { Navbar } from './components/Navbar';
import { GlobalSnackbarProvider } from './components/GlobalSnackbarProvider';
import { RequestsView } from './views/Requests';
import { Bond } from './views/Bond';
import { NoRouteMatch } from './views/NoRouteMatch';
import './App.css';
import { PrincipalPreview } from './views/PrincipalPreview/PrincipalPreview';
import { Agents } from './views/Agents/index';
import { Bonds } from './views/Bonds/index';
import { AccountSetupDialog } from './views/AccountSetupDialog';
import { useStore } from './store';
import { AGENT_ADMIN } from './constants/userRoles';
import { RequestSubmittedView } from './views/RequestSubmitted';
import { RequestDraftView } from './views/RequestDraft';
import { RequestFormReadOnlyView } from './views/RequestSubmitted/RequestFormReadOnly';
import { RequestTermsView } from './views/RequestSubmitted/Terms';
import { RequestIssueView } from './views/RequestSubmitted/Issue';
import { Grid } from '@mui/material';
import { Root } from './views/Root';
import { FinalRequestDraftView } from './views/FinalRequestDraft';
import { FinalRequestSubmittedView } from './views/FinalRequestSubmitted';
import { FinalRequestFormReadOnlyView } from './views/FinalRequestSubmitted/FinalRequestFormReadOnly';
import { FinalRequestTermsView } from './views/FinalRequestSubmitted/Terms';
import { FinalRequestIssueView } from './views/FinalRequestSubmitted/Issue';

type ProtectedRouteProps = WithAuthenticationRequiredOptions & {
  component: React.ComponentType<object>;
};

export const ProtectedRoute = ({ component, ...args }: ProtectedRouteProps) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export const ProtectedAdminRoute = ({ component, ...args }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated } = useAuth0();
  const {
    user: { user, getUserRole },
  } = useStore();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // if we're loading, don't redirect
    if (isLoading || getUserRole() === '') {
      return;
    }

    if (getUserRole() !== AGENT_ADMIN) {
      navigate('/requests');
    } else if (getUserRole() === AGENT_ADMIN) {
      setIsAdmin(true);
    }
  }, [user, isLoading, isAuthenticated]);

  const Component = withAuthenticationRequired(component, args);

  if (!isAdmin) return null;

  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      {...props}
      redirectUri={window.location.origin + '/requests'}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  let auth0Domain: string;
  let auth0ClientId: string;
  let auth0Audience: string;
  if (
    process.env.REACT_APP_AUTH0_DOMAIN &&
    process.env.REACT_APP_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_AUTH0_AUDIENCE
  ) {
    auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  } else {
    throw new Error('environment variable are not set');
  }

  useEffect(() => {
    console.log('App component mounted');
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={auth0Domain}
        clientId={auth0ClientId}
        audience={auth0Audience}
      >
        <GlobalSnackbarProvider>
          <Grid container direction='column' style={{ height: '100vh', overflowY: 'hidden' }}>
            <Grid item>
              <Navbar />
            </Grid>
            <Grid item xs={true} style={{ overflowY: 'scroll' }}>
              <Routes>
                <Route path='/' element={<Root />} />
                <Route path='/requests' element={<ProtectedRoute component={RequestsView} />} />
                <Route
                  path='/requests/contract/drafts/:id'
                  element={<ProtectedRoute component={RequestDraftView} />}
                />
                <Route
                  path='/requests/contract/:id'
                  element={<ProtectedRoute component={RequestSubmittedView} />}
                >
                  <Route
                    path='view'
                    element={<ProtectedRoute component={RequestFormReadOnlyView} />}
                  />
                  <Route path='terms' element={<ProtectedRoute component={RequestTermsView} />} />
                  <Route path='issue' element={<ProtectedRoute component={RequestIssueView} />} />
                </Route>
                <Route
                  path='/requests/contract/bid/drafts/:id'
                  element={<ProtectedRoute component={RequestDraftView} />}
                />
                <Route
                  path='/requests/contract/bid/:id'
                  element={<ProtectedRoute component={RequestSubmittedView} />}
                >
                  <Route
                    path='view'
                    element={<ProtectedRoute component={RequestFormReadOnlyView} />}
                  />
                  <Route path='terms' element={<ProtectedRoute component={RequestTermsView} />} />
                  <Route path='issue' element={<ProtectedRoute component={RequestIssueView} />} />
                </Route>
                <Route
                  path='/requests/contract/final/drafts/:id'
                  element={<ProtectedRoute component={FinalRequestDraftView} />}
                />
                <Route
                  path='/requests/contract/final/:id'
                  element={<ProtectedRoute component={FinalRequestSubmittedView} />}
                >
                  <Route
                    path='view'
                    element={<ProtectedRoute component={FinalRequestFormReadOnlyView} />}
                  />
                  <Route
                    path='terms'
                    element={<ProtectedRoute component={FinalRequestTermsView} />}
                  />
                  <Route
                    path='issue'
                    element={<ProtectedRoute component={FinalRequestIssueView} />}
                  />
                </Route>
                <Route
                  path='/bonds/contract/:id'
                  element={<ProtectedRoute component={() => <Bond />} />}
                />
                <Route
                  path='/bonds/contract/bid/:id'
                  element={<ProtectedRoute component={() => <Bond />} />}
                />
                <Route path='/bonds' element={<ProtectedRoute component={Bonds} />} />
                <Route
                  path='/principals'
                  element={<ProtectedRoute component={PrincipalPreview} />}
                />
                <Route
                  path='/bonds/contract/:id'
                  element={<ProtectedRoute component={() => <Bond />} />}
                />
                <Route
                  path='/bonds/contract/bid/:id'
                  element={<ProtectedRoute component={() => <Bond />} />}
                />
                <Route
                  path='/bonds/contract/final/:id'
                  element={<ProtectedRoute component={() => <Bond />} />}
                />
                <Route path='/bonds' element={<ProtectedRoute component={Bonds} />} />
                <Route
                  path='/principals'
                  element={<ProtectedRoute component={PrincipalPreview} />}
                />
                <Route path='/agents' element={<ProtectedAdminRoute component={Agents} />} />
                <Route path='*' element={<NoRouteMatch />} />
              </Routes>
            </Grid>
          </Grid>
          <AccountSetupDialog />
          <LoadingScreen />
        </GlobalSnackbarProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default App;
