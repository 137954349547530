/* eslint-disable react/prop-types */
import * as React from 'react';
import {
  Grid,
  InputAdornment,
  Stack,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Box,
  Button,
  Card,
  FormHelperText,
  List,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { debounce } from 'debounce';
import { MuiTelInput } from 'mui-tel-input';
import BondFormSelectionModal from './BondFormSelectionModal';
import { Form, useField } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../../store';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ErrorIcon from '@mui/icons-material/Error';
import Avatar from '@mui/material/Avatar';
import {
  FinalDraftRequestPayload,
  useGetFinalRequestDraft,
} from '../../../services/finalRequestDraft';
import { FINAL_DRAFT_BOND_SCHEMA } from '../../../validations/finalBondFormDraftValidationSchema';
import {
  NewFinalBondRequestDraftRequest,
  usePutFinalBondRequestDraft,
} from '../../../services/newFinalBondRequest';
import {
  deepStringify,
  stripEmptyObjects,
  stripEmptyStrings,
} from '../../../services/requestFormSubmissionHelper';
import { DatePicker } from '../../../components/DatePicker';
import { useGetFinalRequest } from '../../../services/finalRequest';
import deepmerge from 'deepmerge';
import { LoadingButton } from '../../../components/LoadingButton';

type RequestFormProps = {
  draftId: string;
  readOnly: boolean;
} & any;

export function RequestForm(props: RequestFormProps) {
  const navigate = useNavigate();
  const { response: requestData } = props.readOnly
    ? (useGetFinalRequest(props.draftId, { skipInitial: false }) as unknown as {
        response: FinalDraftRequestPayload;
        isLoading: boolean;
      })
    : useGetFinalRequestDraft(props.draftId, props.isBidBond);
  const {
    user: { user },
  } = useStore();
  const [field, , helpers] = useField('point_of_contact.phone');
  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  const {
    request: updateFinalForm,
    response: putFinalResponse,
    error: updateFinalError,
    isLoading: isFinalFormUpdating,
  } = usePutFinalBondRequestDraft(props.draftId);

  const { id: requestId } = useParams();

  React.useEffect(() => {
    if (props.isSubmitting) {
      props.submitForm();
    }
  }, [props.isSubmitting]);

  const onSave = () => {
    updateFinalForm(
      FINAL_DRAFT_BOND_SCHEMA.noUnknown().cast(stripEmptyObjects(stripEmptyStrings(props.values)), {
        assert: false,
      }) as NewFinalBondRequestDraftRequest,
    );
  };

  // Watch for user info to be set in the user store and, once it is loaded, hydrate the form fields with it
  React.useEffect(() => {
    props.setValues(
      deepmerge(props.values, {
        point_of_contact: {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          phone: user.phone.startsWith('+1') ? user.phone : `+1${user.phone}`,
        },
      }),
    );
  }, [user]);

  React.useEffect(() => {
    if (requestData) {
      if (requestData.quote_id !== undefined) {
        navigate(`/requests/contract/final/${requestData.quote_id}/view`);
      }
      props.setValues(deepmerge(props.values, deepStringify(requestData)));
    }
  }, [requestData]);

  React.useEffect(() => {
    if (updateFinalError) {
      console.error('error updating form', updateFinalError);
    }
  }, [putFinalResponse, updateFinalError]);

  const handleViewTermsClick = () => {
    navigate(`/requests/contract/final/${requestId}/terms`);
  };

  const onBondFormSelectedOrUpdated = (templateId: string, uploadId: string) => {
    props.setFieldValue('bond_form_template_id', templateId);
    props.setFieldValue('bond_form_file_upload_id', uploadId);
  };

  React.useEffect(() => {
    if (
      props.values.bond_form_template_id !== (requestData?.bond_form_template_id ?? '') ||
      props.values.bond_form_file_upload_id !== (requestData?.bond_form_file_upload_id ?? '')
    ) {
      onSave();
    }
  }, [props.values.bond_form_template_id, props.values.bond_form_file_upload_id]);

  const renderSidebar = () => {
    return (
      <Grid item xs={2} style={{ position: 'relative' }}>
        <Box
          sx={{ flexGrow: 1, border: '1px grey' }}
          pt={10}
          style={{ position: 'sticky', top: 0, left: 0 }}
        >
          <Card>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                justifyContent: 'center',
              }}
              aria-label='sections'
              dense={true}
            >
              <MenuItem component='a' href='#bond'>
                {props.errors.actual_bid_date ||
                props.errors.contract_amount ||
                props.errors.second_bid ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Bond'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#final_bond'>
                {props.errors.actual_bid_amount || props.errors.t_listing_required ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Final Bond'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#point_of_contact'>
                {props.errors.point_of_contact ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='secondary' primary='Point of Contact'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#bond_form'>
                {props.errors.bond_form_template_id || props.errors.bond_form_file_upload_id ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Bond Form'></ListItemText>
              </MenuItem>
              <MenuItem component='a' href='#project'>
                {props.errors.project ? (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <ErrorIcon color='error' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                ) : (
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.paper' }}>
                      <DoubleArrowIcon color='primary' fontSize='small' />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText color='primary' primary='Project'></ListItemText>
              </MenuItem>
            </List>
          </Card>
          <br></br>
          <br></br>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1} p={1}>
            <>
              {props.readOnly ? (
                <Button variant='contained' onClick={handleViewTermsClick}>
                  View Terms
                </Button>
              ) : (
                <>
                  <LoadingButton
                    variant='contained'
                    color='secondary'
                    text={'Save'}
                    onClick={() => {
                      debounce(onSave(), 500);
                    }}
                    spinnerColor='success'
                    disabled={isFinalFormUpdating}
                    isLoading={isFinalFormUpdating}
                  />
                  <LoadingButton
                    text={'Submit'}
                    disabled={props.isFinalFormSubmissionLoading || props.isSubmitting}
                    variant='contained'
                    isLoading={props.isFinalFormSubmissionLoading}
                    spinnerColor='success'
                    onClick={() => props.setIsSubmitting(true)}
                  />
                </>
              )}
            </>
          </Stack>
        </Box>
      </Grid>
    );
  };

  return (
    <Form>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={4}
        p={4}
      >
        <Grid item xs={6} spacing={2}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h5' id='bond' style={{ scrollMarginTop: 75 }}>
              BOND
            </Typography>
            <>
              <Stack direction='column' spacing={2}>
                <TextField
                  id='contract_amount'
                  name='contract_amount'
                  aria-describedby='contract-amount-text'
                  label="Low Bid Amount (Principal's Bid)"
                  inputProps={readOnlyInputProps}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  fullWidth
                  value={props.values.contract_amount}
                  onChange={props.handleChange}
                  error={props.touched.contract_amount && Boolean(props.errors.contract_amount)}
                  helperText={props.touched.contract_amount && props.errors.contract_amount}
                />
                <TextField
                  id='second_bid'
                  name='second_bid'
                  aria-describedby='second-bid-amount-text'
                  label='Second Lowest Bid Amount'
                  inputProps={readOnlyInputProps}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  fullWidth
                  value={props.values.second_bid}
                  onChange={props.handleChange}
                  error={props.touched.second_bid && Boolean(props.errors.second_bid)}
                  helperText={props.touched.second_bid && props.errors.second_bid}
                />
                <DatePicker
                  label='Bid Date'
                  readOnly={props.readOnly}
                  disableFuture={true}
                  value={props.values.actual_bid_date}
                  onChange={value => {
                    props.setFieldValue(
                      'actual_bid_date',
                      value !== null ? value.toFormat('MM/dd/yyyy') : '',
                    );
                  }}
                  fullWidth
                  helperText={props.touched.actual_bid_date && props.errors.actual_bid_date}
                  error={props.touched.actual_bid_date && Boolean(props.errors.actual_bid_date)}
                />
              </Stack>
            </>
          </Stack>
          <br></br>
          <br />
          <Stack direction='column' spacing={2}>
            <Typography variant='h5' id='final_bond' style={{ scrollMarginTop: 75 }}>
              FINAL BOND
            </Typography>
            <>
              <Stack direction='column' spacing={2}>
                <TextField
                  id='bond_amount'
                  name='bond_amount'
                  aria-describedby='bond-amount-text'
                  label='Final Bond Amount'
                  inputProps={readOnlyInputProps}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  fullWidth
                  value={props.values.bond_amount}
                  onChange={props.handleChange}
                  error={props.touched.bond_amount && Boolean(props.errors.bond_amount)}
                  helperText={props.touched.bond_amount && props.errors.bond_amount}
                />
              </Stack>
              <FormControlLabel
                label='Treasury Listing Required?'
                labelPlacement='end'
                control={
                  <Checkbox
                    name='t_listing_required'
                    readOnly={props.readOnly}
                    checked={props.values.t_listing_required === 'true'}
                    onChange={() => {
                      props.setFieldValue(
                        't_listing_required',
                        props.values.t_listing_required === 'true' ? 'false' : 'true',
                      );
                    }}
                    onBlur={props.handleBlur}
                  />
                }
              />
            </>
          </Stack>
          <br></br>
          <br></br>
          <Typography variant='h5' id='point_of_contact' style={{ scrollMarginTop: 75 }}>
            POINT OF CONTACT
          </Typography>
          <br />
          <Stack direction='column' spacing={2}>
            <Stack direction='row' spacing={2}>
              <TextField
                id='poc-first-name'
                name='point_of_contact.first_name'
                label='First Name'
                aria-describedby='address-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.point_of_contact?.first_name}
                onChange={props.handleChange}
                error={
                  props.touched.point_of_contact?.first_name &&
                  Boolean(props.errors.point_of_contact?.first_name)
                }
                helperText={
                  props.touched.point_of_contact?.first_name &&
                  props.errors.point_of_contact?.first_name
                }
              />
              <TextField
                id='poc-last-name'
                name='point_of_contact.last_name'
                label='Last Name'
                aria-describedby='lastname-text'
                fullWidth
                inputProps={readOnlyInputProps}
                value={props.values.point_of_contact?.last_name}
                onChange={props.handleChange}
                error={
                  props.touched.point_of_contact?.last_name &&
                  Boolean(props.errors.point_of_contact?.last_name)
                }
                helperText={
                  props.touched.point_of_contact?.last_name &&
                  props.errors.point_of_contact?.last_name
                }
              />
            </Stack>
            <Stack direction='row' spacing={2}>
              <TextField
                id='poc-email'
                name='point_of_contact.email'
                label='Email Address'
                type='email'
                inputProps={readOnlyInputProps}
                aria-describedby='email-text'
                fullWidth
                value={props.values.point_of_contact?.email}
                onChange={props.handleChange}
                error={
                  props.touched.point_of_contact?.email &&
                  Boolean(props.errors.point_of_contact?.email)
                }
                helperText={
                  props.touched.point_of_contact?.email && props.errors.point_of_contact?.email
                }
              />
              <MuiTelInput
                disableDropdown
                defaultCountry='US'
                onlyCountries={['US']}
                id='poc-phone-number'
                name='point_of_contact.phone'
                aria-describedby='phone-number'
                fullWidth
                inputProps={readOnlyInputProps}
                value={field.value}
                onChange={val => {
                  if (!val.startsWith('+1')) {
                    helpers.setValue('+1' + val);
                  } else {
                    helpers.setValue(val);
                  }
                }}
                error={
                  props.touched.point_of_contact?.phone &&
                  Boolean(props.errors.point_of_contact?.phone)
                }
                helperText={
                  props.touched.point_of_contact?.phone && props.errors.point_of_contact?.phone
                }
              />
            </Stack>
          </Stack>
          <br></br>
          <Typography variant='h5' gutterBottom id='bond_form' style={{ scrollMarginTop: 75 }}>
            BOND FORM
          </Typography>
          {(props.touched.bond_form_template_id || props.touched.bond_form_file_upload_id) && (
            <FormHelperText error={true}>{props.errors.bond_form_template_id}</FormHelperText>
          )}
          <BondFormSelectionModal
            {...props}
            draftId={props.draftId}
            readOnly={props.readOnly}
            onBondFormSelectedOrUpdated={onBondFormSelectedOrUpdated}
          />
          <br></br>
          <br></br>
          <Typography variant='h5' id='project' style={{ scrollMarginTop: 75 }}>
            PROJECT
          </Typography>
          <br />
          <Stack direction='column' spacing={2}>
            <Stack direction='column' spacing={2}>
              <FormControl>
                <DatePicker
                  label='Contract Date'
                  disablePast={true}
                  value={props.values.contract_date}
                  onChange={value =>
                    props.setFieldValue(
                      'contract_date',
                      value !== null ? value.toFormat('MM/dd/yyyy') : '',
                    )
                  }
                  fullWidth
                  helperText={props.touched.contract_date && props.errors.contract_date}
                  error={props.touched.contract_date && Boolean(props.errors.contract_date)}
                />
              </FormControl>
            </Stack>
          </Stack>
          <br></br>
          <br></br>
        </Grid>
        {renderSidebar()}
      </Grid>
    </Form>
  );
}
