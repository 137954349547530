import { ContractSuretyBondType, ContractSuretyQuoteStatus, PointOfContact } from './request';
import { useRequest, useMutate } from './useRequest';

export type FinalRequestPayload = {
  id: string;
  bid_bond_id: string;
  contract_type: ContractSuretyBondType;
  model_version: string;
  bond_amount: number;
  expires: string;
  pending_bond_form_review: boolean;
  pending_indemnity_agreement: boolean;
  bond_form_template_id?: string;
  bond_form_template_to_review_id?: string;
  bond_form_file_upload_id?: string;
  t_listing_required: boolean;
  status: ContractSuretyQuoteStatus;
  bond_id: string;
  agency_id: string;
  agent_id?: string;
  carrier_name: string;
  point_of_contact: PointOfContact;
  terms?: {
    bond_amount: number;
    contract_amount: number;
    flat_rate?: number;
    sliding_scale_rate?: { amount: number; rate: number }[];
    premium: number;
    pending_bond_form_review: boolean;
    pending_indemnity_agreement: boolean;
  };
  contract_amount: number;
  second_bid: number;
  contract_date: string;
  actual_bid_date: string;
};

export const useGetFinalRequest = (id: string, options = { skipInitial: false }) => {
  const path = `/v1/surety/contract/final/quotes/${id}`;

  return useRequest<FinalRequestPayload>(path, 'GET', options);
};

export const useAcceptRequestTerms = (id: string) => {
  const path = `/v1/surety/contract/final/quotes/${id}/accept`;

  return useMutate(path, 'PATCH');
};

export const useRejectRequestTerms = (id: string) => {
  const path = `/v1/surety/contract/final/quotes/${id}/reject`;

  return useMutate(path, 'PATCH');
};
