import Box from '@mui/material/Box';

export function NoRouteMatch() {
  //TODO: update content
  return (
    <Box sx={{ border: '1px grey' }}>
      <span>No Match placeholder</span>
    </Box>
  );
}
