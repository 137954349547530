import {
  Grid,
  Stack,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField,
  Select,
  Button,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { Formik, FormikProps, Form } from 'formik';
import { nameSuffixMenuItems } from '../../../constants/menuItems';
import {
  ContractSuretyBondProjectOwnerClassPrefix,
  ContractSuretyBondProjectOwnerClassPrefixLabel,
  ContractSuretyBondObligeeRole,
  ContractSuretyBondObligeeRoleLabel,
  BindFinalRequestRequestDto,
  IssuanceMethod,
  IssuanceMethodLabel,
} from '../../../services/bindRequest';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useMutate } from '../../../services/useRequest';
import { useEffect } from 'react';
import Yup from '../../../validations/custom';
import { SetNullable } from '../../../types/utils';
import {
  ISSUE_BOND_FORM_DATA_INITIAL_VALUES_BASE,
  ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_INTIAL_VALUES,
  ISSUE_BOND_INITIAL_VALUES_BASE,
  ISSUE_FINAL_BOND_VALIDATION_SCHEMA,
} from '../../../validations/issueBondValidationSchema';
import { AddressAutocomplete } from '../../../components/AddressAutocomplete';
import { getFullAddress } from '../../../shared/util/main';

const ISSUE_FINAL_BOND_INITIAL_VALUES: SetNullable<
  Yup.InferType<typeof ISSUE_FINAL_BOND_VALIDATION_SCHEMA>,
  [
    'project_owner_class_prefix',
    'bond_form.data.obligees.role',
    'bond_form.data.obligees.familiar',
    'bond_form.issuance_method',
  ]
> = {
  ...ISSUE_BOND_INITIAL_VALUES_BASE,
  bond_form: {
    issuance_method: null,
    data: {
      ...ISSUE_BOND_FORM_DATA_INITIAL_VALUES_BASE,
      contract_description: '',
      obligees: [
        ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_INTIAL_VALUES as Yup.InferType<
          typeof ISSUE_FINAL_BOND_VALIDATION_SCHEMA
        >['bond_form']['data']['obligees'][number],
      ],
    },
  },
};

export const FinalRequestIssueForm = (props: {
  requestId: string;
  refetchRequest: (path: string) => void;
  navigateToCurrentStep: () => void;
}) => {
  const {
    response: bindFinalRequestResponse,
    request: bindFinalRequestRequest,
    isLoading: bindFinalRequestLoading,
  } = useMutate(`/v1/surety/contract/final/quotes/${props.requestId}/bind`, 'POST');

  const handleFinalRequestSubmit = async (formData: BindFinalRequestRequestDto) => {
    await bindFinalRequestRequest(formData);
  };

  useEffect(() => {
    const path = `/v1/surety/contract/final/quotes/${props.requestId}`;
    if (bindFinalRequestResponse !== null) {
      props.refetchRequest(path);
    }
  }, [bindFinalRequestResponse]);

  return (
    <Formik
      initialValues={ISSUE_FINAL_BOND_INITIAL_VALUES}
      validationSchema={ISSUE_FINAL_BOND_VALIDATION_SCHEMA}
      onSubmit={data => {
        handleFinalRequestSubmit(data as BindFinalRequestRequestDto);
      }}
    >
      {(props: FormikProps<Yup.InferType<typeof ISSUE_FINAL_BOND_VALIDATION_SCHEMA>> | any) => {
        return (
          <Form>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='stretch'
              spacing={10}
              pb={10}
            >
              <Grid item xs={6} spacing={2} mt={6}>
                <Stack direction='column' spacing={2}>
                  <Box display='inline-block'>
                    <Paper
                      sx={{
                        display: 'inline-block',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#F8F8FB',
                          padding: '30px 35px',
                          fontWeight: 500,
                          fontSize: '14px',
                          display: 'inline-flex',
                          flexDirection: 'column',
                        }}
                      >
                        To issue your bond, we need to collect some additional details to complete
                        the following forms:
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '14px 0 0 10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            <TextSnippetOutlinedIcon style={{ marginRight: '4px' }} />
                            Bond Form
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '4px',
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            <TextSnippetOutlinedIcon style={{ marginRight: '4px' }} />
                            Indemnity Agreement
                          </div>
                        </div>
                      </Box>
                    </Paper>
                  </Box>
                  <Stack direction='column' spacing={2}>
                    <Typography variant='h5' style={{ marginTop: 40, marginBottom: 10 }}>
                      ISSUANCE METHOD
                    </Typography>
                    <Stack direction='column' spacing={4}>
                      <Stack direction='column' spacing={2}>
                        <FormControl>
                          <InputLabel id='bond_form.issuance_method'>Issuance method</InputLabel>
                          <Select
                            id='bond_form.issuance_method'
                            name='bond_form.issuance_method'
                            label='Issuance method'
                            aria-describedby='bond_form.issuance_method'
                            value={props.values.bond_form.issuance_method}
                            onChange={props.handleChange}
                            error={
                              props.touched.bond_form?.issuance_method &&
                              Boolean(props.errors.bond_form?.issuance_method)
                            }
                            displayEmpty
                          >
                            {Object.values(IssuanceMethod).map(prefix => {
                              return (
                                <MenuItem value={prefix} key={prefix} disabled={prefix === 'agent'}>
                                  {IssuanceMethodLabel[prefix]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {props.touched.bond_form?.issuance_method && (
                            <FormHelperText>
                              {props.errors.bond_form?.issuance_method}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Typography variant='h5' style={{ marginTop: 40, marginBottom: 10 }}>
                    BOND FORM
                  </Typography>
                  <Stack direction='column' spacing={4}>
                    <Stack direction='column' spacing={2}>
                      <TextField
                        id='bond_form.data.contract_description'
                        name='bond_form.data.contract_description'
                        label='Contract Description'
                        aria-describedby='bond_form.data.contract-description-text'
                        fullWidth
                        value={props.values.bond_form.data.contract_description}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.contract_description &&
                          Boolean(props.errors.bond_form?.data?.contract_description)
                        }
                        helperText={
                          props.touched.bond_form?.data?.contract_description &&
                          props.errors.bond_form?.data?.contract_description
                        }
                      />
                      <FormControl
                        fullWidth
                        error={
                          props.touched.project_owner_class_prefix &&
                          props.errors.project_owner_class_prefix
                        }
                      >
                        <InputLabel id='project_owner_class_prefix'>Project Owner Type</InputLabel>
                        <Select
                          id='project_owner_class_prefix'
                          name='project_owner_class_prefix'
                          label='Project Owner Type'
                          aria-describedby='project_owner_class_prefix-text'
                          value={props.values.project_owner_class_prefix}
                          onChange={props.handleChange}
                          error={
                            props.touched.project_owner_class_prefix &&
                            Boolean(props.errors.project_owner_class_prefix)
                          }
                          displayEmpty
                        >
                          {Object.values(ContractSuretyBondProjectOwnerClassPrefix).map(prefix => {
                            return (
                              <MenuItem value={prefix} key={prefix}>
                                {ContractSuretyBondProjectOwnerClassPrefixLabel[prefix]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {props.touched.project_owner_class_prefix && (
                          <FormHelperText>{props.errors.project_owner_class_prefix}</FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                    <Stack direction='row'>
                      <Grid item xs={8}>
                        <Typography variant='body1'>
                          Has this principal worked with this obligee before?
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          error={
                            props.touched.bond_form?.data?.obligees?.[0]?.familiar &&
                            Boolean(props.errors.bond_form?.data?.obligees?.[0]?.familiar)
                          }
                        >
                          <RadioGroup
                            row
                            name='bond_form.data.obligees[0].familiar'
                            value={props.values.bond_form?.data?.obligees?.[0]?.familiar?.toString()}
                            onChange={event => {
                              props.setFieldValue(
                                'bond_form.data.obligees[0].familiar',
                                event.currentTarget.value === 'true',
                              );
                            }}
                          >
                            <FormControlLabel value='true' control={<Radio />} label='Yes' />
                            <FormControlLabel value='false' control={<Radio />} label='No' />
                          </RadioGroup>
                          {props.touched.bond_form?.data?.obligees?.[0]?.familiar && (
                            <FormHelperText>
                              {props.errors.bond_form?.data?.obligees?.[0]?.familiar}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Stack>
                    <Stack direction='column' spacing={2}>
                      <Typography variant='h6' style={{ marginTop: 0 }}>
                        Obligee
                      </Typography>
                      <TextField
                        id='bond_form.data.obligees[0].name'
                        name='bond_form.data.obligees[0].name'
                        label='Name'
                        aria-describedby='bond_form.data.obligees[0]name-text'
                        fullWidth
                        value={props.values.bond_form.data.obligees?.[0]?.name}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.obligees?.[0]?.name &&
                          Boolean(props.errors.bond_form?.data?.obligees?.[0]?.name)
                        }
                        helperText={
                          props.touched.bond_form?.data?.obligees?.[0]?.name &&
                          props.errors.bond_form?.data?.obligees?.[0]?.name
                        }
                      />
                      <FormControl
                        fullWidth
                        error={
                          props.touched.bond_form?.data?.obligees?.[0]?.role &&
                          props.errors.bond_form?.data?.obligees?.[0]?.role
                        }
                      >
                        <InputLabel id='bond_form.data.obligees[0].role'>Role</InputLabel>
                        <Select
                          id='bond_form.data.obligees[0].role'
                          name='bond_form.data.obligees[0].role'
                          label='Role'
                          aria-describedby='bond_form.data.obligees[0].role-text'
                          value={props.values.bond_form?.data?.obligees?.[0]?.role}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.obligees?.[0]?.role &&
                            Boolean(props.errors.bond_form?.data?.obligees?.[0]?.role)
                          }
                          displayEmpty
                        >
                          {Object.values(ContractSuretyBondObligeeRole).map(role => {
                            return (
                              <MenuItem value={role} key={role}>
                                {ContractSuretyBondObligeeRoleLabel[role]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {props.touched.bond_form?.data?.obligees?.[0]?.role && (
                          <FormHelperText>
                            {props.errors.bond_form?.data?.obligees?.[0]?.role}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Stack direction='row' spacing={2}>
                        <AddressAutocomplete
                          valueOverride={
                            props.values.bond_form.data?.obligees?.[0]?.address !== null &&
                            props.values.bond_form.data?.obligees?.[0]?.address !== undefined &&
                            props.values.bond_form.data?.obligees?.[0]?.address !== '' &&
                            props.values.bond_form.data?.obligees?.[0]?.city !== null &&
                            props.values.bond_form.data?.obligees?.[0]?.city !== undefined &&
                            props.values.bond_form.data?.obligees?.[0]?.city !== '' &&
                            props.values.bond_form.data?.obligees?.[0]?.state !== null &&
                            props.values.bond_form.data?.obligees?.[0]?.state !== undefined &&
                            props.values.bond_form.data?.obligees?.[0]?.state !== '' &&
                            props.values.bond_form.data?.obligees?.[0]?.zip !== null &&
                            props.values.bond_form.data?.obligees?.[0]?.zip !== undefined &&
                            props.values.bond_form.data?.obligees?.[0]?.zip !== ''
                              ? getFullAddress(
                                  props.values.bond_form.data?.obligees?.[0]?.address,
                                  props.values.bond_form.data?.obligees?.[0]?.city,
                                  props.values.bond_form.data?.obligees?.[0]?.state,
                                  props.values.bond_form.data?.obligees?.[0]?.zip,
                                )
                              : null
                          }
                          onValidation={result => {
                            props.setFieldValue(
                              'bond_form.data.obligees[0].address',
                              !result?.valid ? '' : result?.address ?? '',
                            );
                            props.setFieldTouched('bond_form.data.obligees[0].address', true);
                            props.setFieldValue(
                              'bond_form.data.obligees[0].city',
                              result?.city ?? '',
                            );
                            props.setFieldValue(
                              'bond_form.data.obligees[0].state',
                              result?.state ?? '',
                            );
                            props.setFieldValue(
                              'bond_form.data.obligees[0].zip',
                              result?.zip ?? '',
                            );
                          }}
                          error={
                            props.values.bond_form?.data?.obligees?.[0]?.address === '' &&
                            props.touched.bond_form?.data?.obligees?.[0]?.address
                          }
                          readOnly={false}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction='column' spacing={2}>
                      <Typography variant='h6' style={{ marginTop: 0 }}>
                        Principal Signer
                      </Typography>
                      <Stack direction='row' spacing={2}>
                        <TextField
                          id='bond_form.data.principal_signer.first_name'
                          name='bond_form.data.principal_signer.first_name'
                          label='First Name'
                          aria-describedby='bond_form.data.principal_signer.first_name-text'
                          fullWidth
                          value={props.values.bond_form?.data?.principal_signer?.first_name}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.principal_signer?.first_name &&
                            Boolean(props.errors.bond_form?.data?.principal_signer?.first_name)
                          }
                          helperText={
                            props.touched.bond_form?.data?.principal_signer?.first_name &&
                            props.errors.bond_form?.data?.principal_signer?.first_name
                          }
                        />
                        <TextField
                          id='bond_form.data.principal_signer.last_name'
                          name='bond_form.data.principal_signer.last_name'
                          label='Last Name'
                          aria-describedby='bond_form.data.principal_signer.last_name-text'
                          fullWidth
                          value={props.values.bond_form.data.principal_signer?.last_name}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.principal_signer?.last_name &&
                            Boolean(props.errors.bond_form?.data?.principal_signer?.last_name)
                          }
                          helperText={
                            props.touched.bond_form?.data?.principal_signer?.last_name &&
                            props.errors.bond_form?.data?.principal_signer?.last_name
                          }
                        />
                        <FormControl
                          fullWidth
                          error={
                            props.touched.bond_form?.data?.principal_signer?.suffix &&
                            props.errors.bond_form?.data?.principal_signer?.suffix
                          }
                        >
                          <InputLabel id='bond_form.data.principal_signer.suffix'>
                            Suffix
                          </InputLabel>
                          <Select
                            id='bond_form.data.principal_signer.suffix'
                            name='bond_form.data.principal_signer.suffix'
                            label='Suffix'
                            aria-describedby='bond_form.data.principal_signer.suffix-select'
                            value={props.values.bond_form?.data?.principal_signer?.suffix}
                            onChange={props.handleChange}
                            error={
                              props.touched.bond_form?.data?.principal_signer?.suffix &&
                              Boolean(props.errors.bond_form?.data?.principal_signer?.suffix)
                            }
                            displayEmpty
                            placeholder='Select'
                          >
                            {nameSuffixMenuItems}
                          </Select>
                          {props.touched.bond_form?.data?.principal_signer?.suffix && (
                            <FormHelperText>
                              {props.errors.bond_form?.data?.principal_signer?.suffix}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                      <TextField
                        id='bond_form.data.principal_signer.title'
                        name='bond_form.data.principal_signer.title'
                        label='Title'
                        aria-describedby='bond_form.data.principal_signer.title-text'
                        fullWidth
                        value={props.values.bond_form.data.principal_signer?.title}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.principal_signer?.title &&
                          Boolean(props.errors.bond_form?.data?.principal_signer?.title)
                        }
                        helperText={
                          props.touched.bond_form?.data?.principal_signer?.title &&
                          props.errors.bond_form?.data?.principal_signer?.title
                        }
                      />
                      <TextField
                        id='bond_form.data.principal_signer.email'
                        name='bond_form.data.principal_signer.email'
                        label='Email'
                        aria-describedby='bond_form.data.principal_signer?.email-text'
                        fullWidth
                        value={props.values.bond_form.data.principal_signer?.email}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.principal_signer?.email &&
                          Boolean(props.errors.bond_form?.data?.principal_signer?.email)
                        }
                        helperText={
                          props.touched.bond_form?.data?.principal_signer?.email &&
                          props.errors.bond_form?.data?.principal_signer?.email
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2} style={{ position: 'relative' }}>
                <Box pt={6} style={{ position: 'sticky', top: 0, left: 0 }}>
                  <Button
                    type='submit'
                    style={{
                      width: '100%',
                      color: '#FFF',
                      backgroundColor: '#34B668',
                      fontWeight: 600,
                    }}
                  >
                    {bindFinalRequestLoading ? <CircularProgress size={20} /> : 'Issue Bond'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
