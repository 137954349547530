import { CSSProperties, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';

import { formatToDollar } from '../../../shared/util/main';
import { useAcceptRequestTerms, useRejectRequestTerms } from '../../../services/request';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RequestSubmittedViewOutletContext } from '..';
import { useStore } from '../../../store';
import { useGetRequiredIndemnitors } from '../../../services/requiredIndemnitors';
import { LoadingButton } from '../../../components/LoadingButton';
import { debounce } from 'debounce';

const boxStyles = {
  background: '#F8F8FB',
  borderRadius: '5px',
  padding: '1rem',
  width: '50%',
};

const indemnitorBoxStyles: CSSProperties = {
  background: '#F8F8FB',
  borderRadius: '3px',
  padding: '15px 20px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  boxSizing: `border-box`,
};

export const ApprovedView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const { request, refetchRequest } = useOutletContext<RequestSubmittedViewOutletContext>();
  const requestId = request?.id;
  const terms = request?.terms;
  const {
    loader: { useLoadingScreen },
  } = useStore();

  const {
    response: requiredIndemnitors,
    isLoading: requiredIndemnitorsLoading,
    error: requiredIndemnitorsError,
  } = useGetRequiredIndemnitors(requestId, isBidBond ? 'bid' : 'contract');
  useLoadingScreen(requiredIndemnitorsLoading);

  const { request: acceptTerms, isLoading: isAcceptingTerms } = useAcceptRequestTerms(
    requestId,
    isBidBond,
  );
  useLoadingScreen(isAcceptingTerms);
  const { request: rejectTerms, isLoading: isRejectingTerms } = useRejectRequestTerms(
    requestId,
    isBidBond,
  );
  useLoadingScreen(isRejectingTerms);

  const [agreesToTerms, setAgreesToTerms] = useState<boolean>(false);

  const handleAcceptBondTerms = async () => {
    await acceptTerms(null);
    isBidBond
      ? refetchRequest(`/v1/surety/contract/bid/quotes/${request.id}`)
      : refetchRequest(`/v1/surety/contract/quotes/${request.id}`);
  };

  const handleRejectBondTerms = async () => {
    await rejectTerms(null);
    isBidBond
      ? refetchRequest(`/v1/surety/contract/bid/quotes/${request.id}`)
      : refetchRequest(`/v1/surety/contract/quotes/${request.id}`);
  };

  if (!terms) {
    return null;
  }

  if (isAcceptingTerms || isRejectingTerms) {
    return (
      <Stack direction='row' justifyContent='center' sx={{ paddingY: '1rem' }}>
        <CircularProgress />
      </Stack>
    );
  }

  const rate =
    terms.flat_rate !== undefined
      ? terms.flat_rate * 100 + '%'
      : terms.sliding_scale_rate !== undefined
      ? terms.sliding_scale_rate
          .map((rateTier: { rate: number; amount: number }) => (rateTier.rate * 100).toPrecision(2))
          .join(`%/`) + '%'
      : '--';

  const final_bond_rate =
    terms.final_bond_contingent_flat_rate !== undefined
      ? terms.final_bond_contingent_flat_rate * 100 + '%'
      : terms.final_bond_contingent_sliding_scale_rate !== undefined
      ? terms.final_bond_contingent_sliding_scale_rate
          .map((rateTier: { rate: number; amount: number }) => (rateTier.rate * 100).toPrecision(2))
          .join(`%/`) + '%'
      : '--';

  const enableAcceptOrReject = ['approved', 'rejected'].includes(request.status);

  return (
    <Container maxWidth='lg' sx={{ paddingTop: '20px', paddingBottom: '40px' }}>
      <Grid
        container
        spacing={4}
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        p={4}
      >
        <Grid item xs={12} md={8}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='center'
            spacing={1}
            sx={{ paddingY: '2rem' }}
          >
            <CheckIcon sx={{ color: green[500] }}></CheckIcon>
            <Typography variant='h6' component='h6'>
              Approved
            </Typography>
          </Stack>

          <Divider />

          <Stack alignItems='center' justifyContent='center' spacing={2} sx={{ paddingY: '2rem' }}>
            <div>Summary</div>

            <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Bond Amount</Typography>
                  <Typography>{formatToDollar(terms.bond_amount)}</Typography>
                </Stack>
              </Box>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Contract Amount</Typography>
                  <Typography>{formatToDollar(terms.bond_amount)}</Typography>
                </Stack>
              </Box>
            </Stack>

            <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Rate</Typography>
                  <Typography>{rate}</Typography>
                </Stack>
              </Box>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Premium</Typography>
                  <Typography>{formatToDollar(terms.premium)}</Typography>
                </Stack>
              </Box>
            </Stack>
            {isBidBond && (
              <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                <Box sx={boxStyles}>
                  <Stack alignItems='center'>
                    <Typography>Final Bond Rate</Typography>
                    <Typography>{final_bond_rate}</Typography>
                  </Stack>
                </Box>
                <Box sx={boxStyles}>
                  <Stack alignItems='center'>
                    <Typography>Final Bond Premium</Typography>
                    {terms.final_bond_contingent_premium && (
                      <Typography>{formatToDollar(terms.final_bond_contingent_premium)}</Typography>
                    )}
                  </Stack>
                </Box>
              </Stack>
            )}
            {/* TODO: will add in the future */}
            {/* <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Number of Terms</Typography>
                  <Typography>10</Typography>
                </Stack>
              </Box>
              <Box sx={boxStyles}>
                <Stack alignItems='center'>
                  <Typography>Term Length</Typography>
                  <Typography>1 Year</Typography>
                </Stack>
              </Box>
            </Stack> */}
          </Stack>
          <Divider />
          {!requiredIndemnitorsLoading &&
            !requiredIndemnitorsError &&
            requiredIndemnitors !== null && (
              <Stack justifyContent='center' spacing={2} sx={{ paddingY: '2rem' }}>
                <Box display='flex' justifyContent='center' width='100%'>
                  Indemnitors
                </Box>
                {requiredIndemnitors.principal !== undefined && (
                  <Box style={indemnitorBoxStyles}>{requiredIndemnitors.principal.name}</Box>
                )}
                {requiredIndemnitors.individuals.map((individual, index) => {
                  return (
                    <Box key={index} style={indemnitorBoxStyles}>{`${individual.first_name} ${
                      individual.middle_initial !== undefined ? individual.middle_initial + ' ' : ''
                    }${individual.last_name}${
                      individual.suffix !== undefined ? ' ' + individual.suffix : ''
                    }`}</Box>
                  );
                })}
                {requiredIndemnitors.companies.map((company, index) => {
                  return (
                    <Box key={index} style={indemnitorBoxStyles}>
                      {company.name}
                    </Box>
                  );
                })}
              </Stack>
            )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            {enableAcceptOrReject && (
              <>
                <Box
                  sx={{
                    border: '1px solid #DFDFE1',
                    boxShadow: '0px 4px 40px -20px rgba(53, 112, 201, 0.35)',
                    borderRadius: '3px',
                    padding: '1rem',
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={agreesToTerms} />}
                      onChange={() => setAgreesToTerms(!agreesToTerms)}
                      label='I have read and agree to InRev’s terms of service.'
                    />
                  </FormGroup>
                </Box>
                <LoadingButton
                  variant='contained'
                  color='blue'
                  disabled={!agreesToTerms}
                  onClick={debounce(handleAcceptBondTerms, 500)}
                  text='Accept Bond Terms'
                  spinnerColor='success'
                  isLoading={isAcceptingTerms}
                />
                <LoadingButton
                  variant='text'
                  sx={{ color: 'gray' }}
                  spinnerColor='success'
                  onClick={debounce(handleRejectBondTerms, 500)}
                  text='Reject Bond Terms'
                  isLoading={isRejectingTerms}
                />
              </>
            )}
            {!enableAcceptOrReject && (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    isBidBond
                      ? navigate(`/requests/contract/bid/${requestId}/issue`)
                      : navigate(`/requests/contract/${requestId}/issue`)
                  }
                >
                  Next
                </Button>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
