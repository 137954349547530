/* eslint-disable react/prop-types */
import * as React from 'react';
import { debounce } from 'debounce';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { RequestForm } from './Form';
import {
  getValidationSchemaByModel,
  stripEmptyObjects,
  stripEmptyStrings,
} from '../../../services/requestFormSubmissionHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeNestedIds } from '../../../services/useOwners';
import { NewBondRequest, usePostNewBondRequest } from '../../../services/newBondRequest';
import { usePostNewBidBondRequest } from '../../../services/newBidBondRequest';
import { Alert, Snackbar } from '@mui/material';
import { useGetPrincipalDetails } from '../../../services/principalDetails';
import { useGetQuoteModel } from '../../../services/aggregateExposure';
import { cloneDeep } from 'lodash';

type InitialFormValuesType = {
  bid_amount: string;
  final_bond_type: string;
  bid_date: string;
  bond_form_template_id: string;
  bond_form_file_upload_id: string;
  bond_amount: string;
  contract_amount: string;
  t_listing_required: string;
  point_of_contact: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  project: {
    address: string;
    city: string;
    state: string;
    zip: string;
    scope_of_work: string;
    completion_bond: string;
    contract_hazards: string[];
    contract_damages: string[];
    months_to_complete: string;
    contract_warranty_months: string;
    familiar_obligee: string;
  };
  principal: {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    naics_code: string;
    fein: string;
    largest_construction_project_completed: string;
    lost_a_payment_suit: string;
    failed_to_complete_a_construction_job: string;
    caused_surety_loss: string;
    profitable_ytd: string;
    profitable_last_fiscal_year: string;
    profitable_year_prior_to_last_fiscal_year: string;
    construction_projects_gross_loss_gt_10_percent_last_36_months: string;
    current_construction_project_expected_gross_loss: string;
    construction_project_backlog_cost: string;
    construction_project_backlog_gross_profit: string;
    bloc_size: string;
    last_fiscal_year_end_statement: {
      preparation_method: string;
      date: string;
      corporate_cash: string;
      current_assets: string;
      current_liabilities: string;
      total_assets: string;
      total_liabilities: string;
      revenue: string;
      accounts_payable: string;
      accounts_receivable: string;
      underbillings: string;
      credit_cards_payable: string;
      bank_line_payable: string;
      accrued_expense: string;
      overbillings: string;
      current_portion_of_ltd: string;
      term_loan_debt: string;
      ga_expense: string;
    };
    owners: Array<any>;
  };
};

export const INITIAL_PRINCIPAL_FORM_VALUES: InitialFormValuesType['principal'] = {
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  naics_code: '',
  fein: '',
  largest_construction_project_completed: '',
  lost_a_payment_suit: '',
  failed_to_complete_a_construction_job: '',
  caused_surety_loss: '',
  profitable_ytd: '',
  profitable_last_fiscal_year: '',
  profitable_year_prior_to_last_fiscal_year: '',
  construction_projects_gross_loss_gt_10_percent_last_36_months: '',
  current_construction_project_expected_gross_loss: '',
  construction_project_backlog_cost: '',
  construction_project_backlog_gross_profit: '',
  bloc_size: '',
  last_fiscal_year_end_statement: {
    preparation_method: '',
    date: '',
    corporate_cash: '',
    current_assets: '',
    current_liabilities: '',
    total_assets: '',
    total_liabilities: '',
    revenue: '',
    accounts_payable: '',
    accounts_receivable: '',
    underbillings: '',
    credit_cards_payable: '',
    bank_line_payable: '',
    accrued_expense: '',
    overbillings: '',
    current_portion_of_ltd: '',
    term_loan_debt: '',
    ga_expense: '',
  },
  owners: [],
};

const INITIAL_FORM_VALUES: InitialFormValuesType = {
  bid_amount: '',
  final_bond_type: '',
  bid_date: '',
  bond_form_template_id: '',
  bond_form_file_upload_id: '',
  bond_amount: '',
  contract_amount: '',
  t_listing_required: 'true',
  point_of_contact: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
  project: {
    address: '',
    city: '',
    state: '',
    zip: '',
    scope_of_work: '',
    months_to_complete: '12',
    contract_warranty_months: '12',
    familiar_obligee: '',
    completion_bond: '',
    contract_hazards: [],
    contract_damages: [],
  },
  principal: INITIAL_PRINCIPAL_FORM_VALUES,
};

type FormContentProps = {
  draftId: string;
  readOnly?: boolean;
};
export const FormContent = ({ readOnly, draftId }: FormContentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isBidBond = location.pathname.includes('bid');
  const [model, setModel] = React.useState<string>('small');
  const [selectedPrincipal, setSelectedPrincipal] = React.useState<string>('new-principal');
  const { refetch: principalRefetch, response: principalResponse } = useGetPrincipalDetails();
  const { refetch: modelRefetch, response: modelResponse } = useGetQuoteModel();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(null);

  const handleSnackbarClose = () => {
    setSnackbarMessage(null);
  };

  const {
    request: submitForm,
    response: postResponse,
    error: submitError,
    isLoading: isLoadingBondRequest,
  } = usePostNewBondRequest();

  const {
    request: submitBidForm,
    response: postBidResponse,
    error: submitBidError,
    isLoading: isLoadingBidBondRequest,
  } = usePostNewBidBondRequest();

  React.useEffect(() => {
    if (!isBidBond && submitError) {
      if (submitError.error === 'address-validation') {
        const invalidAddress = submitError.addresses[0];
        setSnackbarMessage(
          `The address you have input is invalid; Review the address for correctness: ${invalidAddress.address}`,
        );
      }
    }
    if (isBidBond && submitBidError) {
      if (submitBidError.error === 'address-validation') {
        const invalidAddress = submitBidError.addresses[0];
        setSnackbarMessage(
          `The address you have input is invalid; Review the address for correctness: ${invalidAddress.address}`,
        );
      }
    }
  }, [submitError, submitBidError]);

  React.useEffect(() => {
    if (postResponse !== null) {
      const { id } = postResponse;
      navigate(`/requests/contract/${id}/terms`);
    } else if (postBidResponse !== null) {
      const { id } = postBidResponse;
      navigate(`/requests/contract/bid/${id}/terms`);
    }
  }, [postResponse, postBidResponse]);

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{ ...INITIAL_FORM_VALUES }}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
      validate={async (values: any) => {
        let errors = {};
        const validationSchema = getValidationSchemaByModel(
          model,
          !isSubmitting && !hasSubmitted,
          isBidBond,
        );
        if (validationSchema !== undefined) {
          try {
            await validateYupSchema(
              validationSchema
                .noUnknown()
                .cast(stripEmptyStrings(cloneDeep(values)), { assert: false }),
              validationSchema,
            );
          } catch (validationErrors) {
            if (isSubmitting) {
              setHasSubmitted(true);
              setIsSubmitting(false);
            }
            errors = yupToFormErrors(validationErrors);
          }
        }
        return errors;
      }}
      onSubmit={(values: any) => {
        if (model !== null) {
          const validationSchema = getValidationSchemaByModel(
            model,
            !isSubmitting && !hasSubmitted,
            isBidBond,
          );
          if (validationSchema !== undefined) {
            const newValues = validationSchema
              .noUnknown()
              .cast(stripEmptyObjects(stripEmptyStrings(cloneDeep(values))), { assert: false });
            newValues.principal.owners = removeNestedIds(newValues.principal.owners);
            if (selectedPrincipal !== 'new-principal') {
              newValues.principal.id = selectedPrincipal;
            }
            newValues['draft_id'] = draftId;
            isBidBond
              ? submitBidForm(newValues as NewBondRequest)
              : submitForm(newValues as NewBondRequest);
            if (!hasSubmitted) setHasSubmitted(true);
          }
          setIsSubmitting(false);
        }
      }}
    >
      {props => (
        <>
          <RequestForm
            {...props}
            draftId={draftId}
            readOnly={readOnly}
            isBidBond={isBidBond}
            model={model}
            setModel={setModel}
            modelResponse={modelResponse}
            modelRefetch={modelRefetch}
            submitError={submitError}
            submitBidError={submitBidError}
            setSnackbarMessage={setSnackbarMessage}
            selectedPrincipal={selectedPrincipal}
            setSelectedPrincipal={setSelectedPrincipal}
            principalResponse={principalResponse}
            principalRefetch={principalRefetch}
            isSubmitting={isSubmitting}
            setIsSubmitting={debounce(setIsSubmitting, 500)}
            isLoadingBondRequest={isLoadingBondRequest}
            isLoadingBidBondRequest={isLoadingBidBondRequest}
          ></RequestForm>
          <Snackbar
            open={snackbarMessage !== null}
            autoHideDuration={10000}
            onClose={handleSnackbarClose}
          >
            <Alert severity='error'>{snackbarMessage}</Alert>
          </Snackbar>
        </>
      )}
    </Formik>
  );
};
