import Container from '@mui/material/Container';
import { Box, LinearProgress, Stack } from '@mui/material';

import { AgentsTable } from './AgentsTable';
import { InviteButton } from './InviteButton';
import { useGetAgents, AgentInvitePreviewResponse } from '../../services/agents';

export const Agents = () => {
  const { response, isLoading, refetch: getAgents } = useGetAgents();
  let agents = [] as AgentInvitePreviewResponse;
  if (response) {
    agents = response;
  }

  return (
    <Container maxWidth='xl'>
      <Box sx={{ width: '100%' }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <h2>Agents</h2>

          <InviteButton getAgents={getAgents} />
        </Stack>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      {!isLoading && (
        <div style={{ height: '80vh', width: '100%' }}>
          <AgentsTable rows={agents} getAgents={getAgents} />
        </div>
      )}
    </Container>
  );
};
