import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { yellow } from '@mui/material/colors';
import { Grid, Stack, Typography } from '@mui/material';

export const ReviewView = () => (
  <Grid container spacing={4} direction='column' justifyContent='center' alignItems='center' p={4}>
    <Stack
      justifyContent='center'
      alignItems='center'
      style={{ padding: '2rem' }}
      direction='row'
      spacing={2}
    >
      <ReportProblemIcon sx={{ color: yellow[500] }} />
      <Typography variant='h5'>Submitted for Review</Typography>
    </Stack>
    <Typography variant='h6' align='center'>
      Something caught our automated underwriter&apos;s eye, so we&apos;re having a human review the
      request. <br />
      We will update you as soon as possible. <br />
      Be sure to check your email for updates!
    </Typography>
    <Typography variant='caption'>
      If you have any questions, feel free to reach out to us at:{' '}
      <a href='bonds@getinrev.com'>bonds@getinrev.com</a>
    </Typography>
  </Grid>
);
