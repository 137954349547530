import { TextField, Stack } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { Company, Owner } from '../../../../services/useOwners';
import { PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA } from '../../../../validations/bondFormPrincipalOwnerValidation';
import { ModalButtons } from './ModalButtons';
import { getValueOrInitial } from './helpers';
import Yup from '../../../../validations/custom';
import { AddressAutocomplete } from '../../../../components/AddressAutocomplete';
import { getFullAddress } from '../../../../shared/util/main';

type CompanyFormProps =
  | FormikProps<Yup.InferType<typeof PRINCIPAL_OWNER_COMPANY_VALIDATION_SCHEMA>> & {
      onSaveClick: (values: Company) => void;
      onCancelClick: () => void;
      previousOwners: Owner[];
      readOnly: boolean;
    };

export const CompanyForm = (props: CompanyFormProps) => {
  const readOnlyInputProps = props.readOnly ? { readOnly: true } : { readOnly: false };

  const onSave = () => {
    props.validateForm().then((errors: any) => {
      if (Object.keys(errors).length == 0) {
        const values = {
          ...props.values,
          type: 'company',
          owners: [...props.previousOwners],
        };
        props.onSaveClick(values as Company);
      }
    });
  };

  return (
    <Form>
      <Stack direction='column'>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={2}>
            <TextField
              name='name'
              id='comanyName'
              label='Name'
              aria-describedby='company-name-text'
              fullWidth
              onChange={props.handleChange}
              value={getValueOrInitial(props, 'name')}
              error={props.touched.name && Boolean(props.errors.name)}
              helperText={props.touched.name && props.errors.name}
              inputProps={readOnlyInputProps}
            />
            <TextField
              name='fein'
              id='fein'
              label='FEIN'
              aria-describedby='middle-name-initial-text'
              onChange={event =>
                props.setFieldValue('fein', (event.target as HTMLInputElement).value.slice(-9))
              }
              value={getValueOrInitial(props, 'fein')}
              error={props.touched.fein && Boolean(props.errors.fein)}
              helperText={props.touched.fein && props.errors.fein}
              inputProps={readOnlyInputProps}
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            <AddressAutocomplete
              valueOverride={
                getValueOrInitial(props, 'address') !== null &&
                getValueOrInitial(props, 'address') !== undefined &&
                getValueOrInitial(props, 'address') !== '' &&
                getValueOrInitial(props, 'city') !== null &&
                getValueOrInitial(props, 'city') !== undefined &&
                getValueOrInitial(props, 'city') !== '' &&
                getValueOrInitial(props, 'state') !== null &&
                getValueOrInitial(props, 'state') !== undefined &&
                getValueOrInitial(props, 'state') !== '' &&
                getValueOrInitial(props, 'zip') !== null &&
                getValueOrInitial(props, 'zip') !== undefined &&
                getValueOrInitial(props, 'zip') !== ''
                  ? getFullAddress(
                      getValueOrInitial(props, 'address'),
                      getValueOrInitial(props, 'city'),
                      getValueOrInitial(props, 'state'),
                      getValueOrInitial(props, 'zip'),
                    )
                  : null
              }
              onValidation={result => {
                props.setFieldValue('address', !result?.valid ? '' : result?.address ?? '');
                props.setFieldTouched('address', true);
                props.setFieldValue('city', result?.city ?? '');
                props.setFieldValue('state', result?.state ?? '');
                props.setFieldValue('zip', result?.zip ?? '');
              }}
              error={props.values.address === '' && props.touched.address}
              readOnly={props.readOnly}
            />
          </Stack>
        </Stack>
      </Stack>
      <ModalButtons
        readOnly={props.readOnly}
        onSaveClick={onSave}
        onCancelClick={props.onCancelClick}
      />
    </Form>
  );
};
