import { Button, Grid, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const BoundView = (props: { bondId: string | undefined }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');

  return (
    <Grid
      container
      spacing={4}
      direction='column'
      justifyContent='center'
      alignItems='center'
      p={4}
    >
      <Stack
        justifyContent='center'
        alignItems='center'
        style={{ padding: '6rem' }}
        direction='column'
        spacing={2}
      >
        <Typography variant='h5'>You did it! This bond has been issued.</Typography>
        {props.bondId && (
          <Button
            color='primary'
            variant='contained'
            onClick={() =>
              isBidBond
                ? navigate(`/bonds/contract/bid/${props.bondId}`)
                : navigate(`/bonds/contract/${props.bondId}`)
            }
          >
            View Bond
          </Button>
        )}
        <Typography variant='caption'>
          If you have any questions, feel free to reach out to us at:{' '}
          <a href='bonds@getinrev.com'>bonds@getinrev.com</a>
        </Typography>
      </Stack>
    </Grid>
  );
};
