import {
  Grid,
  Stack,
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  TextField,
  Select,
  Divider,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Formik, FormikProps, Form, validateYupSchema, yupToFormErrors } from 'formik';
import { nameSuffixMenuItems } from '../../../constants/menuItems';
import {
  BindRequestRequestDto,
  ContractSuretyBondProjectOwnerClassPrefix,
  ContractSuretyBondProjectOwnerClassPrefixLabel,
  ContractSuretyBondObligeeRole,
  ContractSuretyBondObligeeRoleLabel,
  BindBidRequestDto,
  useBindRequestRequest,
  IssuanceMethod,
  IssuanceMethodLabel,
} from '../../../services/bindRequest';
import { useGetRequiredIndemnitors } from '../../../services/requiredIndemnitors';
import {
  ISSUE_BOND_INDEMNITY_AGREEMENT_PRINCIPAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
  ISSUE_BOND_INDEMNITY_AGREEMENT_INDIVIDUAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
  ISSUE_BOND_INDEMNITY_AGREEMENT_COMPANY_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
  ISSUE_BOND_INITIAL_VALUES_BASE,
  ISSUE_BOND_FORM_DATA_INITIAL_VALUES_BASE,
  ISSUE_CONTRACT_BOND_FORM_VALIDATION_SCHEMA,
  ISSUE_BID_BOND_FORM_VALIDATION_SCHEMA,
  ISSUE_BOND_BASE_VALIDATION_SCHEMA,
  ISSUE_BOND_FORM_DATA_OBLIGEE_INTIAL_VALUES_BASE,
  ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_INTIAL_VALUES,
} from '../../../validations/issueBondValidationSchema';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../../components/DatePicker';
import Yup from '../../../validations/custom';
import { stripEmptyStrings } from '../../../services/requestFormSubmissionHelper';
import { cloneDeep } from 'lodash';
import { AddressAutocomplete } from '../../../components/AddressAutocomplete';
import { getFullAddress } from '../../../shared/util/main';

const INITIAL_INDIVIDUAL_INDEMNITOR_FORM_VALUE = (contactId: string, email: string) => {
  return {
    contact_id: contactId,
    email,
  };
};

const INITIAL_COMPANY_INDEMNITOR_FORM_VALUE = (contactId: string) => {
  return {
    contact_id: contactId,
    first_name: '',
    middle_initial: '',
    last_name: '',
    suffix: '',
    title: '',
    email: '',
  };
};

const INITIAL_PRINCIPAL_INDEMNITOR_SIGNER_FORM_VALUE = {
  first_name: '',
  last_name: '',
  suffix: '',
  title: '',
  email: '',
};

const INITIAL_CONTRACT_FORM_DATA_VALUES_BASE = {
  contract_date: '',
  contract_description: '',
};

const INITIAL_BID_FORM_DATA_VALUES_BASE = {
  project_description: '',
};

export const RequestIssueForm = (wrapperProps: {
  requestId: string;
  principalName: string;
  navigateToCurrentStep: () => void;
  refetchRequest: (path: string) => void;
}) => {
  const location = useLocation();
  const isBidBond = location.pathname.includes('bid');
  const stringsToParseIntoBooleans = ['bond_form.familiar_obligee'];
  const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema | null>(null);
  const [initialValues, setInitialValues] = useState<any | null>(null);

  const parseStringsToBools = (values: Record<string, any>): any => {
    let newValues = { ...values };

    stringsToParseIntoBooleans.forEach(field => {
      const path = field.split('.');
      const oldVal = R.path(path, values);
      if (oldVal !== undefined) {
        const newVal = oldVal === 'true';
        newValues = R.assocPath(path, newVal, newValues);
      }
    });

    return newValues;
  };

  const {
    response: requiredIndemnitors,
    isLoading: requiredIndemnitorsLoading,
    error: requiredIndemnitorsError,
  } = useGetRequiredIndemnitors(wrapperProps.requestId, isBidBond ? 'bid' : 'contract');

  const {
    response: bindRequestResponse,
    request: bindRequestRequest,
    isLoading: bindRequestLoading,
  } = useBindRequestRequest(wrapperProps.requestId, isBidBond);

  const {
    response: bindBidBondResponse,
    request: bindBidBondRequest,
    isLoading: bindBidBondLoading,
  } = useBindRequestRequest(wrapperProps.requestId, isBidBond);

  const handleRequestSubmit = async (formData: BindRequestRequestDto) => {
    const formattedValues = parseStringsToBools(formData);
    await bindRequestRequest(formattedValues);
  };

  const handleBidSubmit = async (formData: BindBidRequestDto) => {
    const formattedValues = parseStringsToBools(formData);
    await bindBidBondRequest(formattedValues);
  };

  useEffect(() => {
    if (!requiredIndemnitorsLoading && !requiredIndemnitorsError && requiredIndemnitors !== null) {
      setValidationSchema(
        ISSUE_BOND_BASE_VALIDATION_SCHEMA.concat(
          Yup.object({
            bond_form: isBidBond
              ? ISSUE_BID_BOND_FORM_VALIDATION_SCHEMA
              : ISSUE_CONTRACT_BOND_FORM_VALIDATION_SCHEMA,
          }).concat(
            requiredIndemnitors !== null &&
              (requiredIndemnitors.principal !== undefined ||
                requiredIndemnitors.individuals.length ||
                requiredIndemnitors.companies.length)
              ? Yup.object({
                  indemnity_agreement: Yup.object({})
                    .noUnknown()
                    .concat(
                      Yup.object({
                        principal_signer:
                          ISSUE_BOND_INDEMNITY_AGREEMENT_PRINCIPAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
                        individual_signers: Yup.array()
                          .of(
                            ISSUE_BOND_INDEMNITY_AGREEMENT_INDIVIDUAL_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
                          )
                          .length(requiredIndemnitors.individuals.length),
                        company_signers: Yup.array()
                          .of(
                            ISSUE_BOND_INDEMNITY_AGREEMENT_COMPANY_INDEMNITOR_SIGNER_VALIDATION_SCHEMA,
                          )
                          .length(requiredIndemnitors.companies.length),
                      }).noUnknown(),
                    ),
                }).noUnknown()
              : Yup.object({}).noUnknown(),
          ),
        ).noUnknown(),
      );

      setInitialValues({
        ...ISSUE_BOND_INITIAL_VALUES_BASE,
        bond_form: {
          issuance_method: null,
          data: {
            ...ISSUE_BOND_FORM_DATA_INITIAL_VALUES_BASE,
            ...(isBidBond
              ? INITIAL_BID_FORM_DATA_VALUES_BASE
              : INITIAL_CONTRACT_FORM_DATA_VALUES_BASE),
            obligees: [
              isBidBond
                ? ISSUE_BOND_FORM_DATA_OBLIGEE_INTIAL_VALUES_BASE
                : ISSUE_BOND_FORM_DATA_OBLIGEE_WITH_FAMILIAR_INTIAL_VALUES,
            ],
          },
        },
        ...(requiredIndemnitors !== null &&
        (requiredIndemnitors.principal !== undefined ||
          requiredIndemnitors.individuals.length ||
          requiredIndemnitors.companies.length)
          ? {
              indemnity_agreement: {
                principal_signer: INITIAL_PRINCIPAL_INDEMNITOR_SIGNER_FORM_VALUE,
                individual_signers: requiredIndemnitors.individuals.map(individual =>
                  INITIAL_INDIVIDUAL_INDEMNITOR_FORM_VALUE(individual.contact_id, individual.email),
                ),
                company_signers: requiredIndemnitors.companies.map(company =>
                  INITIAL_COMPANY_INDEMNITOR_FORM_VALUE(company.contact_id),
                ),
              },
            }
          : {}),
      });
    }
  }, [requiredIndemnitorsLoading]);

  React.useEffect(() => {
    const path = isBidBond
      ? `/v1/surety/contract/bid/quotes/${wrapperProps.requestId}`
      : `/v1/surety/contract/quotes/${wrapperProps.requestId}`;
    if (bindRequestResponse !== null || bindBidBondResponse !== null) {
      wrapperProps.refetchRequest(path);
    }
  }, [bindRequestResponse, bindBidBondResponse]);

  return requiredIndemnitorsLoading === false &&
    !requiredIndemnitorsError &&
    validationSchema !== null &&
    initialValues !== null ? (
    <Formik
      initialValues={initialValues}
      validate={async (values: any) => {
        let errors = {};
        if (validationSchema !== null) {
          try {
            await validateYupSchema(
              validationSchema
                .noUnknown()
                .cast(stripEmptyStrings(cloneDeep(values)), { assert: false }),
              validationSchema,
            );
          } catch (validationErrors) {
            errors = yupToFormErrors(validationErrors);
          }
        }
        return errors;
      }}
      onSubmit={data => {
        isBidBond
          ? handleBidSubmit(data as unknown as BindBidRequestDto)
          : handleRequestSubmit(data as unknown as BindRequestRequestDto);
      }}
    >
      {(props: FormikProps<Yup.InferType<typeof validationSchema>> | any) => {
        return (
          <Form>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='stretch'
              spacing={10}
              pb={10}
            >
              <Grid item xs={6} spacing={2} mt={6}>
                <Stack direction='column' spacing={2}>
                  <Box display='inline-block'>
                    <Paper
                      sx={{
                        display: 'inline-block',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#F8F8FB',
                          padding: '30px 35px',
                          fontWeight: 500,
                          fontSize: '14px',
                          display: 'inline-flex',
                          flexDirection: 'column',
                        }}
                      >
                        To issue your bond, we need to collect some additional details to complete
                        the following forms:
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '14px 0 0 10px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            <TextSnippetOutlinedIcon style={{ marginRight: '4px' }} />
                            Bond Form
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '4px',
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            <TextSnippetOutlinedIcon style={{ marginRight: '4px' }} />
                            Indemnity Agreement
                          </div>
                        </div>
                      </Box>
                    </Paper>
                  </Box>
                  <Stack direction='column' spacing={2}>
                    <Typography variant='h5' style={{ marginTop: 40, marginBottom: 10 }}>
                      ISSUANCE METHOD
                    </Typography>
                    <Stack direction='column' spacing={4}>
                      <Stack direction='column' spacing={2}>
                        <FormControl>
                          <InputLabel id='bond_form.issuance_method'>Issuance method</InputLabel>
                          <Select
                            id='bond_form.issuance_method'
                            name='bond_form.issuance_method'
                            label='Issuance method'
                            aria-describedby='bond_form.issuance_method'
                            value={props.values.bond_form.issuance_method}
                            onChange={props.handleChange}
                            error={
                              props.touched.bond_form?.issuance_method &&
                              Boolean(props.errors.bond_form?.issuance_method)
                            }
                            displayEmpty
                          >
                            {Object.values(IssuanceMethod).map(prefix => {
                              return (
                                <MenuItem value={prefix} key={prefix} disabled={prefix === 'agent'}>
                                  {IssuanceMethodLabel[prefix]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {props.touched.bond_form?.issuance_method && (
                            <FormHelperText>
                              {props.errors.bond_form?.issuance_method}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Typography variant='h5' style={{ marginTop: 40, marginBottom: 10 }}>
                    BOND FORM
                  </Typography>
                  <Stack direction='column' spacing={4}>
                    <Stack direction='column' spacing={2}>
                      {!isBidBond && (
                        <DatePicker
                          fullWidth
                          value={props.values.bond_form.data.contract_date}
                          label='Contract Date'
                          onChange={value => {
                            props.setFieldValue(
                              'bond_form.data.contract_date',
                              value !== null ? value.toFormat('MM/dd/yyyy') : '',
                            );
                          }}
                          error={
                            props.touched.bond_form?.data?.contract_date &&
                            Boolean(props.errors.bond_form?.data?.contract_date)
                          }
                          helperText={
                            props.touched.bond_form?.data?.contract_date &&
                            props.errors.bond_form?.data?.contract_date
                          }
                        />
                      )}
                      {!isBidBond && (
                        <TextField
                          id='bond_form.data.contract_description'
                          name='bond_form.data.contract_description'
                          label='Contract Description'
                          aria-describedby='bond_form.contract-description-text'
                          fullWidth
                          value={props.values.bond_form?.data?.contract_description}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.contract_description &&
                            Boolean(props.errors.bond_form?.data?.contract_description)
                          }
                          helperText={
                            props.touched.bond_form?.data?.contract_description &&
                            props.errors.bond_form?.data?.contract_description
                          }
                        />
                      )}
                      {isBidBond && (
                        <TextField
                          id='bond_form.data.project_description'
                          name='bond_form.data.project_description'
                          label='Project Description'
                          aria-describedby='bond_form.project-description-text'
                          fullWidth
                          value={props.values.bond_form?.data?.project_description}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.project_description &&
                            Boolean(props.errors.bond_form?.data?.project_description)
                          }
                          helperText={
                            props.touched.bond_form?.data?.project_description &&
                            props.errors.bond_form?.data?.project_description
                          }
                        />
                      )}
                      <FormControl
                        fullWidth
                        error={
                          props.touched.project_owner_class_prefix &&
                          props.errors.project_owner_class_prefix
                        }
                      >
                        <InputLabel id='project_owner_class_prefix'>Project Owner Type</InputLabel>
                        <Select
                          id='project_owner_class_prefix'
                          name='project_owner_class_prefix'
                          label='Project Owner Type'
                          aria-describedby='project_owner_class_prefix-text'
                          value={props.values.project_owner_class_prefix}
                          onChange={props.handleChange}
                          error={
                            props.touched.project_owner_class_prefix &&
                            Boolean(props.errors.project_owner_class_prefix)
                          }
                          displayEmpty
                        >
                          {Object.values(ContractSuretyBondProjectOwnerClassPrefix).map(prefix => {
                            return (
                              <MenuItem value={prefix} key={prefix}>
                                {ContractSuretyBondProjectOwnerClassPrefixLabel[prefix]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {props.touched.project_owner_class_prefix && (
                          <FormHelperText>{props.errors.project_owner_class_prefix}</FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                    {!isBidBond && (
                      <Stack direction='row'>
                        <Grid item xs={8}>
                          <Typography variant='body1'>
                            Has this principal worked with this obligee before?
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            error={
                              props.touched.bond_form?.data?.obligees?.[0]?.familiar &&
                              Boolean(props.errors.bond_form?.data?.obligees?.[0]?.familiar)
                            }
                          >
                            <RadioGroup
                              row
                              name='bond_form.data.obligees[0].familiar'
                              value={props.values.bond_form?.data?.obligees?.[0]?.familiar?.toString()}
                              onChange={event => {
                                props.setFieldValue(
                                  'bond_form.data.obligees[0].familiar',
                                  event.currentTarget.value === 'true',
                                );
                              }}
                            >
                              <FormControlLabel value='true' control={<Radio />} label='Yes' />
                              <FormControlLabel value='false' control={<Radio />} label='No' />
                            </RadioGroup>
                            {props.touched.bond_form?.data?.obligees?.[0]?.familiar && (
                              <FormHelperText>
                                {props.errors.bond_form?.data?.obligees?.[0]?.familiar}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Stack>
                    )}
                    <Stack direction='column' spacing={2}>
                      <Typography variant='h6' style={{ marginTop: 0 }}>
                        Obligee
                      </Typography>
                      <TextField
                        id='bond_form.data.obligees[0].name'
                        name='bond_form.data.obligees[0].name'
                        label='Name'
                        aria-describedby='bond_form.data.obligees[0].name-text'
                        fullWidth
                        value={props.values.bond_form?.data?.obligees?.[0]?.name}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.obligees?.[0]?.name &&
                          Boolean(props.errors.bond_form?.data?.obligees?.[0]?.name)
                        }
                        helperText={
                          props.touched.bond_form?.data?.obligees?.[0]?.name &&
                          props.errors.bond_form?.data?.obligees?.[0]?.name
                        }
                      />
                      <FormControl
                        fullWidth
                        error={
                          props.touched.bond_form?.data?.obligees?.[0]?.role &&
                          props.errors.bond_form?.data?.obligees?.[0]?.role
                        }
                      >
                        <InputLabel id='bond_form.data.obligees[0].role'>Role</InputLabel>
                        <Select
                          id='bond_form.data.obligees[0].role'
                          name='bond_form.data.obligees[0].role'
                          label='Role'
                          aria-describedby='bond_form.data.obligees[0].role-text'
                          value={props.values.bond_form?.data?.obligees?.[0]?.role}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.obligees?.[0]?.role &&
                            Boolean(props.errors.bond_form?.data?.obligees?.[0]?.role)
                          }
                          displayEmpty
                        >
                          {Object.values(ContractSuretyBondObligeeRole).map(role => {
                            return (
                              <MenuItem value={role} key={role}>
                                {ContractSuretyBondObligeeRoleLabel[role]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {props.touched.bond_form?.data?.obligees?.[0]?.role && (
                          <FormHelperText>
                            {props.errors.bond_form?.data?.obligees?.[0]?.role}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Stack direction='row' spacing={2}>
                        <AddressAutocomplete
                          valueOverride={
                            props.values.bond_form?.data?.obligees?.[0]?.address !== null &&
                            props.values.bond_form?.data?.obligees?.[0]?.address !== undefined &&
                            props.values.bond_form?.data?.obligees?.[0]?.address !== '' &&
                            props.values.bond_form?.data?.obligees?.[0]?.city !== null &&
                            props.values.bond_form?.data?.obligees?.[0]?.city !== undefined &&
                            props.values.bond_form?.data?.obligees?.[0]?.city !== '' &&
                            props.values.bond_form?.data?.obligees?.[0]?.state !== null &&
                            props.values.bond_form?.data?.obligees?.[0]?.state !== undefined &&
                            props.values.bond_form?.data?.obligees?.[0]?.state !== '' &&
                            props.values.bond_form?.data?.obligees?.[0]?.zip !== null &&
                            props.values.bond_form?.data?.obligees?.[0]?.zip !== undefined &&
                            props.values.bond_form?.data?.obligees?.[0]?.zip !== ''
                              ? getFullAddress(
                                  props.values.bond_form?.data?.obligees?.[0]?.address,
                                  props.values.bond_form?.data?.obligees?.[0]?.city,
                                  props.values.bond_form?.data?.obligees?.[0]?.state,
                                  props.values.bond_form?.data?.obligees?.[0]?.zip,
                                )
                              : null
                          }
                          onValidation={result => {
                            props.setFieldValue(
                              'bond_form.data.obligees[0].address',
                              !result?.valid ? '' : result?.address ?? '',
                            );
                            props.setFieldTouched('bond_form.data.obligees[0].address', true);
                            props.setFieldValue(
                              'bond_form.data.obligees[0].city',
                              result?.city ?? '',
                            );
                            props.setFieldValue(
                              'bond_form.data.obligees[0].state',
                              result?.state ?? '',
                            );
                            props.setFieldValue(
                              'bond_form.data.obligees[0].zip',
                              result?.zip ?? '',
                            );
                          }}
                          error={
                            props.values.bond_form?.obligee?.address === '' &&
                            props.touched.bond_form?.obligee?.address
                          }
                          readOnly={false}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction='column' spacing={2}>
                      <Typography variant='h6' style={{ marginTop: 0 }}>
                        Signer for {wrapperProps.principalName}
                      </Typography>
                      <Stack direction='row' spacing={2}>
                        <TextField
                          id='bond_form.data.principal_signer.first_name'
                          name='bond_form.data.principal_signer.first_name'
                          label='First Name'
                          aria-describedby='bond_form.data.principal_signer.first_name-text'
                          fullWidth
                          value={props.values.bond_form?.data?.principal_signer?.first_name}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.principal_signer?.first_name &&
                            Boolean(props.errors.bond_form?.data?.principal_signer?.first_name)
                          }
                          helperText={
                            props.touched.bond_form?.data?.principal_signer?.first_name &&
                            props.errors.bond_form?.data?.principal_signer?.first_name
                          }
                        />
                        <TextField
                          id='bond_form.data.principal_signer.last_name'
                          name='bond_form.data.principal_signer.last_name'
                          label='Last Name'
                          aria-describedby='bond_form.data.principal_signer.last_name-text'
                          fullWidth
                          value={props.values.bond_form.data.principal_signer?.last_name}
                          onChange={props.handleChange}
                          error={
                            props.touched.bond_form?.data?.principal_signer?.last_name &&
                            Boolean(props.errors.bond_form?.data?.principal_signer?.last_name)
                          }
                          helperText={
                            props.touched.bond_form?.data?.principal_signer?.last_name &&
                            props.errors.bond_form?.data?.principal_signer?.last_name
                          }
                        />
                        <FormControl
                          fullWidth
                          error={
                            props.touched.bond_form?.data?.principal_signer?.suffix &&
                            props.errors.bond_form?.data?.principal_signer?.suffix
                          }
                        >
                          <InputLabel id='bond_form.data.principal_signer.suffix'>
                            Suffix
                          </InputLabel>
                          <Select
                            id='bond_form.data.principal_signer.suffix'
                            name='bond_form.data.principal_signer.suffix'
                            label='Suffix'
                            aria-describedby='bond_form.data.principal_signer.suffix-select'
                            value={props.values.bond_form?.data?.principal_signer?.suffix}
                            onChange={props.handleChange}
                            error={
                              props.touched.bond_form?.data?.principal_signer?.suffix &&
                              Boolean(props.errors.bond_form?.data?.principal_signer?.suffix)
                            }
                            displayEmpty
                            placeholder='Select'
                          >
                            {nameSuffixMenuItems}
                          </Select>
                          {props.touched.bond_form?.data?.principal_signer?.suffix && (
                            <FormHelperText>
                              {props.errors.bond_form?.data?.principal_signer?.suffix}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                      <TextField
                        id='bond_form.data.principal_signer.title'
                        name='bond_form.data.principal_signer.title'
                        label='Title'
                        aria-describedby='bond_form.data.principal_signer.title-text'
                        fullWidth
                        value={props.values.bond_form.data.principal_signer?.title}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.principal_signer?.title &&
                          Boolean(props.errors.bond_form?.data?.principal_signer?.title)
                        }
                        helperText={
                          props.touched.bond_form?.data?.principal_signer?.title &&
                          props.errors.bond_form?.data?.principal_signer?.title
                        }
                      />
                      <TextField
                        id='bond_form.data.principal_signer.email'
                        name='bond_form.data.principal_signer.email'
                        label='Email'
                        aria-describedby='bond_form.data.principal_signer?.email-text'
                        fullWidth
                        value={props.values.bond_form.data.principal_signer?.email}
                        onChange={props.handleChange}
                        error={
                          props.touched.bond_form?.data?.principal_signer?.email &&
                          Boolean(props.errors.bond_form?.data?.principal_signer?.email)
                        }
                        helperText={
                          props.touched.bond_form?.data?.principal_signer?.email &&
                          props.errors.bond_form?.data?.principal_signer?.email
                        }
                      />
                    </Stack>
                  </Stack>
                  {(requiredIndemnitors?.principal !== undefined ||
                    (requiredIndemnitors?.individuals !== undefined &&
                      requiredIndemnitors.individuals.length > 0) ||
                    (requiredIndemnitors?.companies !== undefined &&
                      requiredIndemnitors.companies.length > 0)) && (
                    <>
                      <Divider style={{ marginTop: 40 }} />
                      <Typography variant='h5' style={{ marginTop: 40, marginBottom: 10 }}>
                        INDEMNITY AGREEMENT
                      </Typography>
                      <Stack direction='column' spacing={4}>
                        {requiredIndemnitors?.principal !== undefined && (
                          <Stack direction='column' spacing={2}>
                            <Typography variant='h6' style={{ marginTop: 0 }}>
                              Signer for {wrapperProps.principalName}
                            </Typography>
                            <Stack direction='row' spacing={2}>
                              <TextField
                                id='indemnity_agreement.principal_signer.first_name'
                                name='indemnity_agreement.principal_signer.first_name'
                                label='First Name'
                                aria-describedby='indemnity_agreement.principal_signer.first_name-text'
                                fullWidth
                                value={
                                  props.values.indemnity_agreement?.principal_signer?.first_name
                                }
                                onChange={props.handleChange}
                                error={
                                  props.touched.indemnity_agreement?.principal_signer?.first_name &&
                                  Boolean(
                                    props.errors.indemnity_agreement?.principal_signer?.first_name,
                                  )
                                }
                                helperText={
                                  props.touched.indemnity_agreement?.principal_signer?.first_name &&
                                  props.errors.indemnity_agreement?.principal_signer?.first_name
                                }
                              />
                              <TextField
                                id='indemnity_agreement.principal_signer.last_name'
                                name='indemnity_agreement.principal_signer.last_name'
                                label='Last Name'
                                aria-describedby='indemnity_agreement.principal_signer.last_name-text'
                                fullWidth
                                value={
                                  props.values.indemnity_agreement?.principal_signer?.last_name
                                }
                                onChange={props.handleChange}
                                error={
                                  props.touched.indemnity_agreement?.principal_signer?.last_name &&
                                  Boolean(
                                    props.errors.indemnity_agreement?.principal_signer?.last_name,
                                  )
                                }
                                helperText={
                                  props.touched.indemnity_agreement?.principal_signer?.last_name &&
                                  props.errors.indemnity_agreement?.principal_signer?.last_name
                                }
                              />
                              <FormControl
                                fullWidth
                                error={
                                  props.touched.indemnity_agreement?.principal_signer?.suffix &&
                                  props.errors.indemnity_agreement?.principal_signer?.suffix
                                }
                              >
                                <InputLabel id='indemnity_agreement.principal_signer.suffix-label'>
                                  Suffix
                                </InputLabel>
                                <Select
                                  id='indemnity_agreement.principal_signer.suffix'
                                  name='indemnity_agreement.principal_signer.suffix'
                                  label='Suffix'
                                  aria-describedby='indemnity_agreement.principal_signer.suffix-select'
                                  value={props.values.indemnity_agreement?.principal_signer?.suffix}
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.indemnity_agreement?.principal_signer?.suffix &&
                                    Boolean(
                                      props.errors.indemnity_agreement?.principal_signer?.suffix,
                                    )
                                  }
                                  displayEmpty
                                  placeholder='Select'
                                >
                                  {nameSuffixMenuItems}
                                </Select>
                                {props.touched.indemnity_agreement?.principal_signer?.suffix && (
                                  <FormHelperText>
                                    {props.errors.indemnity_agreement?.principal_signer?.suffix}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Stack>
                            <TextField
                              id='indemnity_agreement.principal_signer.title'
                              name='indemnity_agreement.principal_signer.title'
                              label='Title'
                              aria-describedby='indemnity_agreement.principal_signer.title-text'
                              fullWidth
                              value={props.values.indemnity_agreement.principal_signer?.title}
                              onChange={props.handleChange}
                              error={
                                props.touched.indemnity_agreement?.principal_signer?.title &&
                                Boolean(props.errors.indemnity_agreement?.principal_signer?.title)
                              }
                              helperText={
                                props.touched.indemnity_agreement?.principal_signer?.title &&
                                props.errors.indemnity_agreement?.principal_signer?.title
                              }
                            />
                            <TextField
                              id='indemnity_agreement.principal_signer.email'
                              name='indemnity_agreement.principal_signer.email'
                              label='Email'
                              aria-describedby='indemnity_agreement.principal_signer?.email-text'
                              fullWidth
                              value={props.values.indemnity_agreement.principal_signer?.email}
                              onChange={props.handleChange}
                              error={
                                props.touched.indemnity_agreement?.principal_signer?.email &&
                                Boolean(props.errors.indemnity_agreement?.principal_signer?.email)
                              }
                              helperText={
                                props.touched.indemnity_agreement?.principal_signer?.email &&
                                props.errors.indemnity_agreement?.principal_signer?.email
                              }
                            />
                          </Stack>
                        )}
                        {requiredIndemnitors.companies.map((company, index) => {
                          return (
                            <Stack key={index} direction='column' spacing={2}>
                              <Typography variant='h6' style={{ marginTop: 0 }}>
                                {company.name} Signer
                              </Typography>
                              <Stack direction='row' spacing={2}>
                                <TextField
                                  id={`indemnity_agreement.company_signers[${index}].first_name`}
                                  name={`indemnity_agreement.company_signers[${index}].first_name`}
                                  label='First Name'
                                  aria-describedby={`indemnity_agreement.company_signers[${index}].first_name-text`}
                                  fullWidth
                                  value={
                                    props.values.indemnity_agreement?.company_signers?.[index]
                                      ?.first_name
                                  }
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.indemnity_agreement?.company_signers?.[index]
                                      ?.first_name &&
                                    Boolean(
                                      props.errors.indemnity_agreement?.company_signers?.[index]
                                        ?.first_name,
                                    )
                                  }
                                  helperText={
                                    props.touched.indemnity_agreement?.company_signers?.[index]
                                      ?.first_name &&
                                    props.errors.indemnity_agreement?.company_signers?.[index]
                                      ?.first_name
                                  }
                                />
                                <TextField
                                  id={`indemnity_agreement.company_signers[${index}].last_name`}
                                  name={`indemnity_agreement.company_signers[${index}].last_name`}
                                  label='Last Name'
                                  aria-describedby={`indemnity_agreement.company_signers[${index}].last_name-text`}
                                  fullWidth
                                  value={
                                    props.values.indemnity_agreement?.company_signers?.[index]
                                      ?.last_name
                                  }
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.indemnity_agreement?.company_signers?.[index]
                                      ?.last_name &&
                                    Boolean(
                                      props.errors.indemnity_agreement?.company_signers?.[index]
                                        ?.last_name,
                                    )
                                  }
                                  helperText={
                                    props.touched.indemnity_agreement?.company_signers?.[index]
                                      ?.last_name &&
                                    props.errors.indemnity_agreement?.company_signers?.[index]
                                      ?.last_name
                                  }
                                />
                              </Stack>
                              <FormControl
                                fullWidth
                                error={
                                  props.touched.indemnity_agreement?.principal_signer?.suffix &&
                                  props.errors.indemnity_agreement?.principal_signer?.suffix
                                }
                              >
                                <InputLabel
                                  id={`indemnity_agreement.company_signers[${index}].suffix-label`}
                                >
                                  Suffix
                                </InputLabel>
                                <Select
                                  id={`indemnity_agreement.company_signers[${index}].suffix`}
                                  name={`indemnity_agreement.company_signers[${index}].suffix`}
                                  label='Suffix'
                                  aria-describedby={`indemnity_agreement.company_signers[${index}].suffix-select`}
                                  value={
                                    props.values.indemnity_agreement?.company_signers?.[index]
                                      ?.suffix
                                  }
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.indemnity_agreement?.company_signers?.[index]
                                      ?.suffix &&
                                    Boolean(
                                      props.errors.indemnity_agreement?.company_signers?.[index]
                                        ?.suffix,
                                    )
                                  }
                                  displayEmpty
                                  placeholder='Select'
                                >
                                  {nameSuffixMenuItems}
                                </Select>
                                {props.touched.indemnity_agreement?.company_signers?.[index]
                                  ?.suffix && (
                                  <FormHelperText>
                                    {
                                      props.errors.indemnity_agreement?.company_signers?.[index]
                                        ?.suffix
                                    }
                                  </FormHelperText>
                                )}
                              </FormControl>
                              <TextField
                                id={`indemnity_agreement.company_signers[${index}].title`}
                                name={`indemnity_agreement.company_signers[${index}].title`}
                                label='Title'
                                aria-describedby={`indemnity_agreement.company_signers[${index}].title-text`}
                                fullWidth
                                value={
                                  props.values.indemnity_agreement.company_signers?.[index]?.title
                                }
                                onChange={props.handleChange}
                                error={
                                  props.touched.indemnity_agreement?.company_signers?.[index]
                                    ?.title &&
                                  Boolean(
                                    props.errors.indemnity_agreement?.company_signers?.[index]
                                      ?.title,
                                  )
                                }
                                helperText={
                                  props.touched.indemnity_agreement?.company_signers?.[index]
                                    ?.title &&
                                  props.errors.indemnity_agreement?.company_signers?.[index]?.title
                                }
                              />
                              <TextField
                                id={`indemnity_agreement.company_signers[${index}].email`}
                                name={`indemnity_agreement.company_signers[${index}].email`}
                                label='Email'
                                aria-describedby={`indemnity_agreement.company_signers[${index}].title-text`}
                                fullWidth
                                value={
                                  props.values.indemnity_agreement.company_signers?.[index]?.email
                                }
                                onChange={props.handleChange}
                                error={
                                  props.touched.indemnity_agreement?.company_signers?.[index]
                                    ?.email &&
                                  Boolean(
                                    props.errors.indemnity_agreement?.company_signers?.[index]
                                      ?.email,
                                  )
                                }
                                helperText={
                                  props.touched.indemnity_agreement?.company_signers?.[index]
                                    ?.email &&
                                  props.errors.indemnity_agreement?.company_signers?.[index]?.email
                                }
                              />
                            </Stack>
                          );
                        })}
                        {requiredIndemnitors.individuals.map((individual, index) => {
                          return (
                            <Stack key={index} direction='column' spacing={2}>
                              <Typography variant='h6' style={{ marginTop: 0 }}>
                                {individual.first_name}
                                {individual.middle_initial
                                  ? ` ${individual.middle_initial}`
                                  : ''}{' '}
                                {individual.last_name}
                                {individual.suffix ? ` ${individual.suffix}` : ''}
                              </Typography>
                              <TextField
                                id={`indemnity_agreement.individual_signers[${index}].email`}
                                name={`indemnity_agreement.individual_signers[${index}].email`}
                                label='Email'
                                aria-describedby={`indemnity_agreement.individual_signers[${index}].email-text`}
                                fullWidth
                                value={
                                  props.values.indemnity_agreement?.individual_signers?.[index]
                                    ?.email
                                }
                                onChange={props.handleChange}
                                error={
                                  props.touched.indemnity_agreement?.individual_signers?.[index]
                                    ?.email &&
                                  Boolean(
                                    props.errors.indemnity_agreement?.individual_signers?.[index]
                                      ?.email,
                                  )
                                }
                                helperText={
                                  props.touched.indemnity_agreement?.individual_signers?.[index]
                                    ?.email &&
                                  props.errors.indemnity_agreement?.individual_signers?.[index]
                                    ?.email
                                }
                              />
                            </Stack>
                          );
                        })}
                      </Stack>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={2} style={{ position: 'relative' }}>
                <Box pt={6} style={{ position: 'sticky', top: 0, left: 0 }}>
                  <Button
                    type='submit'
                    style={{
                      width: '100%',
                      color: '#FFF',
                      backgroundColor: '#34B668',
                      fontWeight: 600,
                    }}
                  >
                    {isBidBond ? (
                      bindBidBondLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        'Issue Bond'
                      )
                    ) : bindRequestLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      'Issue Bond'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <></>
  );
};
