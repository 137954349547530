import { useMutate, useRequest } from './useRequest';

export type AgentInvitePreview = {
  id: string;
  email: string;
  user_roles: string;
  invitation_pending: boolean;
  invited_at: string;
  first_name: string;
  last_name: string;
};

export type AgentInvitePayload = {
  email: string;
  role: string;
};

export type CompleteSetupPayload = {
  first_name: string;
  last_name: string;
  npn: string;
  resident_license_state: string;
};

export type AgentInvitePreviewResponse = AgentInvitePreview[];

export const useGetAgents = () => {
  return useRequest<AgentInvitePreviewResponse>('/v1/agents/previews', 'GET');
};

export const usePostAgentInvite = () => {
  return useMutate('/v1/agents/invite', 'POST');
};

export const useResendAgentInvite = (agentId: string) => {
  return useMutate(`/v1/agents/${agentId}/invite/resend`, 'POST');
};

export const useCompleteAgentSetup = () => {
  return useMutate(`/v1/agents/complete-setup`, 'POST');
};

export const useDeleteAgentInvite = (agentId: string) => {
  return useMutate(`/v1/agents/${agentId}/invite`, 'DELETE');
};
