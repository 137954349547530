import { InputAdornment, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export const DatePicker = (
  props: Omit<MobileDatePickerProps<string, DateTime>, 'renderInput'> & {
    style?: any;
    fullWidth?: boolean;
    flex?: number;
    placeholder?: string;
    helperText?: any;
    error?: any;
  } & React.RefAttributes<HTMLDivElement>,
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [shrunk, setShrunk] = useState<boolean>(false);

  useEffect(() => {
    if (props.value === '') {
      setShrunk(false);
    } else {
      setShrunk(true);
    }
  }, [props.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MobileDatePicker
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnSelect={true}
        showToolbar={false}
        onChange={e => {
          if (e !== null) {
            setShrunk(true);
          } else {
            setShrunk(false);
          }
          props.onChange(e);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' onClick={() => setOpen(true)}>
              <CalendarMonthIcon />
            </InputAdornment>
          ),
        }}
        renderInput={params => (
          <TextField
            fullWidth={props.fullWidth}
            inputProps={{
              readOnly: true,
              placeholder: props.placeholder,
            }}
            InputLabelProps={{
              shrink: shrunk,
              disableAnimation: true,
            }}
            {...params}
            sx={{
              div: { cursor: 'pointer', flex: props.flex },
              input: { cursor: 'pointer' },
              ...props.style,
            }}
            helperText={props.helperText}
            error={props.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};
