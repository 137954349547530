import { useResendAgentInvite } from '../../services/agents';
import { LoadingButton } from '../../components/LoadingButton';

type ResendInviteButtonProps = {
  agentId: string;
};

export const ResendInviteButton = ({ agentId }: ResendInviteButtonProps) => {
  const { request, isLoading } = useResendAgentInvite(agentId);

  return (
    <LoadingButton
      spinnerColor='success'
      text='Resend'
      variant='outlined'
      onClick={() => request(null)}
      disabled={isLoading}
      isLoading={isLoading}
    />
  );
};
